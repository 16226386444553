import XHR_POST from '../scripts/XHR_POST';
import APIRoutes from './APIRoutes';

const AdminDeployContract = async ({
  index=null,
},authToken=null) =>{
  return(XHR_POST({
    route: APIRoutes.contracts.deploy,
    data:{
      index
    },
    authToken,
  }).catch(e=>{
    console.error(e);
    alert(e.toString());
    throw new Error(`Unable to deploy contract`);
  }).then(({message})=>{
    alert(message);
    return(true);
  }));
}

export default AdminDeployContract;