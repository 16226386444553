import React from 'react';
import {string} from 'prop-types';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Translator from '../../scripts/Translator';
import CanPassLoginImage from '../../images/canpass-login.png';

const translate = Translator({});


const useStyles = makeStyles((theme)=>{
  return({
    NewsContainer:{
      display: "flex",
      alignItems: "center",
      alignContent:"center",
      // maxWidth:"40rem",
      border: "solid #C4C4C4 1px",
      borderRadius:".5rem",
      color: "black",
      padding: "0.5rem",
      textDecoration: "none",
      marginBottom: ".5rem",
    },
    NewsImage:{
      marginRight: "0.5rem",
      maxHeight: "4rem",
      maxWidth: "6rem"
    },
    NewsMore:{
      width: "auto"
    },
    NewsSVG:{
      marginLeft: "0.25rem",
      transform: "scale(2)"
    },
    NewsTitle:{
      marginRight: "0.25rem",
      flexGrow: 1,
    }
  })
});

const NewsPost = (props)=>{
  const {
    title, image_url, link_url
  } = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  return(<a
    className = {classes.NewsContainer}
    href={link_url}
    target="_blank"
    rel="noopener noreferrer"
  >
    {<img alt="news item" src={image_url?image_url:CanPassLoginImage} className={classes.NewsImage} />}
    <Typography variant="body1" className={classes.NewsTitle}>{translate(title)}</Typography>
    <Button 
      variant='outlined'
      color='primary'
      className={classes.NewsMore}
      style={{whiteSpace:"nowrap", marginLeft:"1rem", flexGrow:"0", flexShrink:"0"}}
    >{translate("Read More")}</Button>
  </a>)
}

NewsPost.propTypes = {
  title: string.isRequired,
  image_url: string,
  link_url: string.isRequired
};

export default NewsPost;