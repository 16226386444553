
const Translator = ({languages={}}) => (baseText, toLanguage="Englisgh")=>{
  //<TODO>
  if(typeof(languages[toLanguage])!='undefined')
  {
    if(typeof(languages[toLanguage][baseText])!='undefined')
    {
      return(languages[toLanguage][baseText]);
    }
  }
  return(baseText);
}

export default Translator;
