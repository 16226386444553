import XHR_GET from '../scripts/XHR_GET';
import APIRoutes from './APIRoutes';

const GetUserTransactions = async (authToken, opts={}) =>{
  let {
    page=1,
    prior=[],
    getAll=true,
  }=opts;
  let transactions= await XHR_GET({
    route: APIRoutes.transactions.myTransactions,
    authToken,
    queries: {
      page
    }
  }).then(info=>{
    //Do post-processing here
    if(info.transactions.length == 25 && getAll)
    {
      return(GetUserTransactions(authToken, {
        page: page + 1,
        prior: prior.concat(prior, info.transactions),
        getAll,
      }));
    }
    else
    {
      return(prior.concat(prior, info.transactions));
    }
  });
  return(transactions);
};

export default GetUserTransactions;