import XHR_POST from '../scripts/XHR_POST';
import APIRoutes from './APIRoutes';

const UpdateWallet = async ({
  address=null,
  name="",
  setDefault=false,
}, authToken)=>{ 
  return(XHR_POST({
    route: APIRoutes.wallets.update,
    data: {
      address,
      name,
      default: setDefault
    },
    authToken
  }).then(({message,wallets,defaultWallet})=>{
    return({message,wallets, defaultWallet});
  }));
};

export default UpdateWallet;