import React, {useState, useEffect, useRef} from 'react';
import BigNumber from 'bignumber.js';
import QRCode from 'qrcode.react';
import AutoSizer from 'react-virtualized-auto-sizer';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {default as useOnboardingStyles} from '../onboarding/onboarding_style';
import TimeoutTextButton from '../../components/timeout_text_button/timeout_text_button';

import WalletsInputDropdown from '../../components/wallets_dropdown/wallets_dropdown';
import StyledDropdown from '../../components/styled_dropdown/styled_dropdown';
import StatesDropdown from '../../components/states_dropdown/states_dropdown';
import StyledInput from '../../components/styled_input/styled_input';
import Disclosures from '../../components/disclosures/disclosures';

const useStyles = makeStyles((theme)=>{
  return({
    mainContainer:{
      display:"flex",
      flexDirection:"column",
      justifyContent:"center",
      alignContent:"center",
      alignItems:"center",
      padding:"0.5rem"
    },
    qrCode:{
      height:"15rem",
      width:"15rem",
      maxWidth:"800px",
      maxHeight:"800px",
      marginTop:"1rem",
      marginBottom:"2rem"
    },
    paymentOptionButton:{
      margin:"1rem",
    },
    metaMaskCTA:{
      margin:"1rem",
      '&:hover':{
        cursor:"pointer",
        color:"blue"
      }
    },
    RecipientInputContainer:{
      marginTop:"1rem",
      marginBottom:"1rem",
      display:"inline-flex",
      flexDirection:"row",
      flexWrap:"wrap",
      paddingRight:".25rem",
      flexGrow:"0"
    },
    EditReceiverContainer:{
      display:"flex",
      flexDirection:"row",
      flexWrap:"wrap",
      alignContent:"flex-start",
      alignItems:"flex-start",
      justifyContent:"space-between",
      flexBasis:"100%"
    },
    EditReceiverInput:{
      flexGrow:"1",
      flexShrink:"0"
    },
    FormButtons:{
      marginTop:"1rem"
    }
  });

});

const UpdateReceiverInfoPage = (props)=>{
  const theme = useTheme();
  const onboardingClasses = useOnboardingStyles(theme);
  const classes = useStyles(theme);

  const {
    address='',
    email='',
    first='',
    last='',
    company='',
    phone='',
    street='',
    city='',
    state='',
    country='',
    zip='',
    updateInfo=async(info)=>{},
    contracts =[],
    wallets = [],
    metaMaskAddress = null,
    showTermsAndConditions = ()=>{console.log("Default Show Terms")},
    showPrivacyPolicy = ()=>{console.log("Default Show Privacy")},
  } = props;

  const [inputAddress, setInputAddress] = useState(address);
  const [inputEmail, setInputEmail] = useState(email);
  const [inputFirstName, setInputFirstName] = useState(first);
  const [inputLastName, setInputLastName] = useState(last);
  const [inputCompany, setInputCompany] = useState(company);
  const [inputPhone, setInputPhone] = useState(phone);
  const [inputStreet, setInputStreet] = useState(street);
  const [inputCity, setInputCity] = useState(city);
  const [inputState, setInputState] = useState(state);
  const [inputCountry, setInputCountry] = useState(country);
  const [inputZip, setInputZip] = useState(zip);

  const defaultSubmitButtonText = "Submit Update";
  const [submitButtonText, setSubmitButtonText] = useState(defaultSubmitButtonText);

  const defaultRevertButtonText = "Revert Changes";
  //const [revertButtonText, setRevertButtonText] = useState(defaultRevertButtonText);
  const cancelChanges= ()=>{
    setInputAddress(address);
    setInputEmail(email);
    setInputFirstName(first);
    setInputLastName(last);
    setInputCompany(company);
    setInputPhone(phone);
    setInputStreet(street);
    setInputCity(city);
    setInputState(state);
    setInputCountry(country);
    setInputZip(zip);
  }
  const isRendered = useRef(false);
  useEffect(()=>{
    isRendered.current = true;
    return(()=>{
      isRendered.current = false;
    })
  });
  const isUpdating = useRef(false);
  const submitPageInfo = ()=>{
    if(!isUpdating.current)
    {
      isUpdating.current=true;
      setSubmitButtonText("Submitting...");
      updateInfo({
        address: inputAddress,
        email: inputEmail,
        first: inputFirstName,
        last: inputLastName,
        company: inputCompany,
        phone: inputPhone,
        street: inputStreet,
        city: inputCity,
        state: inputState,
        country: inputCountry,
        zip: inputZip
      }).catch(e=>{
        console.error(e);
        setSubmitButtonText("Error");
        return(null);
      }).then((result)=>{
        if(result)
        {
          setSubmitButtonText("Updated!");
        }
        isUpdating.current = false;
      });
    }
  }

  return(<div className={onboardingClasses.baseContainer}>
    <div className={onboardingClasses.ImageContainer}/>
    <Container className={onboardingClasses.FormContainer} onSubmit={()=>{}}>
        <Typography variant="h6" style={{marginBottom:"1rem"}}>Update Contract Info Request</Typography>
        <Typography variant="body1" style={{marginBottom:"1rem", textAlign:"center"}}>A CanPass user has requested your information for payments. Enter your information below to begin receiving CanPass payments</Typography>
        <div className = {classes.RecipientInputContainer}>
          <div className={classes.EditReceiverContainer}>
            <WalletsInputDropdown
              value={inputAddress}
              type="text"
              label="Recipient Wallet Address"
              updateForm={(newVal)=>{
                setInputAddress(newVal);
              }}
              style={{flexBasis:"100%", width:"calc(100% - 1rem)", margin:0}}
              className = {classes.EditReceiverInput}
              contracts={contracts}
              wallets={wallets}
              metaMaskWallet={metaMaskAddress}
            />
          </div>
          <div className={classes.EditReceiverContainer}>
            <StyledInput
              value={inputFirstName}
              type="text"
              label="First Name"
              updateForm={(newVal)=>{
                setInputFirstName(newVal);
              }}
              className = {classes.EditReceiverInput}
            />
            <StyledInput
              value={inputLastName}
              type="text"
              label="Last Name"
              updateForm={(newVal)=>{
                setInputLastName(newVal);
              }}
              className = {classes.EditReceiverInput}
            />
          </div>
          <div className={classes.EditReceiverContainer}>
            <StyledInput
              value={inputEmail}
              type="text"
              label="Email Address"
              updateForm={(newVal)=>{
                setInputEmail(newVal);
              }}
              className = {classes.EditReceiverInput}
            />
            <StyledInput
              value={inputPhone}
              type="text"
              label="Phone Number"
              updateForm={(newVal)=>{
                setInputPhone(newVal);
              }}
              className = {classes.EditReceiverInput}
            />
          </div>
          <div className={classes.EditReceiverContainer}>
            <StyledInput
              value={inputCompany}
              type="text"
              label="Company"
              updateForm={(newVal)=>{
                setInputCompany(newVal);
              }}
              className = {classes.EditReceiverInput}
            />
            <StyledInput
              value={inputStreet}
              type="text"
              label="Street Address"
              updateForm={(newVal)=>{
                setInputStreet(newVal);
              }}
              className = {classes.EditReceiverInput}
            />
          </div>
          <div className={classes.EditReceiverContainer}>
            <StyledInput
              value={inputCity}
              type="text"
              label="City"
              updateForm={(newVal)=>{
                setInputCity(newVal);
              }}
              className = {classes.EditReceiverInput}
            />
            <StatesDropdown
              value={inputState}
              label="State / Territory"
              updateForm={(newVal)=>{
                setInputState(newVal);
              }}
              className = {classes.EditReceiverInput}
            />
          </div>
          <div className={classes.EditReceiverContainer}>
            <StyledInput
              value={inputZip}
              type="text"
              label="ZIP Code"
              updateForm={(newVal)=>{
                setInputZip(newVal);
              }}
              className = {classes.EditReceiverInput}
            />
            <StyledDropdown
              value={inputCountry}
              label="Country"
              updateForm={(newVal)=>{
                setInputCountry(newVal);
              }}
              className = {classes.EditReceiverInput}
              items={[
                {
                  label:"United States of America",
                  value: "United States of America"
                }
              ]}
            />
          </div>
        </div>
        <TimeoutTextButton
          defaultText={defaultSubmitButtonText}
          buttonText= {submitButtonText}
          setButtonText={setSubmitButtonText}
          timeoutSeconds={15}
          variant='contained'
          color='primary'
          onClick={()=>{
            submitPageInfo();
          }}
          className={classes.FormButtons}
        />
        <Button
          variant='outlined'
          color='secondary'
          onClick={()=>{
            cancelChanges();
          }}
          className={classes.FormButtons}
        >{defaultRevertButtonText}</Button>
        <br/><br/>
        <Disclosures 
        showPrivacyPolicy = {showPrivacyPolicy}
        showTermsAndConditions = {showTermsAndConditions}
      ></Disclosures>
    </Container>
  </div>)
}

export default UpdateReceiverInfoPage;