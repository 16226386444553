import BigNumber from 'bignumber.js';
import React, {useState, useEffect} from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme)=>{
  return({
    outerContainer:{
      width:"100%",
      height:"25rem"
    },
    sliderContainer:{
      display:"flex",
      flexDirection:"row",
      flexWrap:"wrap",
      overflow:"scroll",
      width:"100%",
    },
    cardContainer:{
      padding:"1rem",
      display:"inline-flex",
      flexDirection:"column",
      justifyContent:"space-around",
      alignItems:"center",
      width:"15rem",
      height:"20rem",
      margin:"1rem",
      color:"white",
      fontWeight:"500",
      '&:hover':{
        boxShadow:".3rem .3rem grey",
        cursor:"pointer"
      }
    },
    addCard:{
      backgroundColor:"#4F731A",//"#7BCDD1",
    },
    addButton:{
      backgroundColor:"#2C400F",//"",
      color:"white",
      '&:hover':{
        backgroundColor:"#8CBF3F"//"#5D9B9E",
      }
    },
    draftCard:{
      backgroundColor:"#202473"
    },
    draftButton:{
      backgroundColor:"#3B42BF",
      color:"white",
      '&:hover':{
        backgroundColor:"#8CBF3F",
        
      }
    },
    activeCard:{
      backgroundColor: "#0D1326"
    },
    activeButton:{
      backgroundColor:"#1B1E59",
      color:"white",
      '&:hover':{
        backgroundColor:"#5D9B9E"
      }
    },
    frozenCard:{
      backgroundColor:"#141640",
      color:"white",
    },
    frozenButton:{
      backgroundColor:"#272840",
      color:"white",
      '&:hover':{
        backgroundColor:"#7B7FCA",
        color:"white"
      }
    },
    archivedCard:{
      backgroundColor:"#5F0F73",
      color:"white",
    },
    archivedButton:{
      backgroundColor:"#350840",
      color:"white",
      '&:hover':{
        backgroundColor:"#9E19BF",
        color:"white"
      }
    },
  })
})

const RecentContractsSlider = ({
  contracts=[],
  style,
  className,
  maxToShow = 4,
  onClick = (event, index)=>{console.log(`Default on Add New Handler: ${index}`)}
}) =>
{
  const theme =useTheme();
  const classes = useStyles(theme);

  const ContractCard = ({title, status, classBase="", info, buttonText, onClick})=>{
    let buttonClass=classes[`${classBase}Button`];
    let cardClass=classes[`${classBase}Card`];

    return(<Paper style={{...style}}
      onClick={onClick}
      className={[classes.cardContainer, cardClass, className].join(' ')}
    >
      <Typography variant="h6" style ={{textAlign:"center"}}>{title}</Typography>
      <Typography variant="h6" style = {{textAlign:"center",textTransform:"capitalize"}}>{status}</Typography>
      <Typography variant="h6" style ={{textAlign:"center"}} >{info}</Typography>
      <Button 
        variant="contained"
        className={[buttonClass].join(' ')}
        onClick={onClick}
      >{buttonText}</Button>
    </Paper>)
  }

  return(<div  className={classes.outerContainer}><AutoSizer>{({height, width})=>(<div style={{height, width}} className={classes.sliderContainer}>
    {<ContractCard 
      title="Add New"
      status={<AddIcon/>}
      classBase = {"add"}
      info="Create New Payment Contract"
      buttonText="Get Started"
      onClick = {(event)=>{
        onClick(event,null);
      }}
    />}
    {contracts.map((contract,index)=>{
      let status = 'draft';
      let buttonText= "Edit Contract";
      if(contract.deployed)
      {
        status = "active";
        buttonText = "More Details";
      }
      if(contract.frozen)
      {
        status = "frozen";
        buttonText = "More Details";
      }
      if(contract.archived)
      {
        status="archived";
        buttonText = "More Details";
      }
      let info = "";
      if(contract.totalReceived)
      {
        info = `Total Received: ${new BigNumber(contract.totalReceived).div(1e18).toFixed(4)} CNG`;
      }
      return(<ContractCard
        key = {contract.index}
        title= {contract.name}
        status = {status}
        classBase = {status}
        info = {info}
        buttonText = {buttonText}
        onClick = {(event,)=>{
          onClick(event,contract.index);
        }}
      />)
    }).sort((a,b)=>{
      return(b.dateCreated-a.dateCreated);
    }).slice(0, Math.min(maxToShow, contracts.length))}
  </div>)}</AutoSizer></div>);
};

export default RecentContractsSlider;

