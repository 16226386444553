import React, {useState, useEffect, useRef} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import StarsIcon from '@material-ui/icons/Stars';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save'

import StyledInput from '../styled_input/styled_input';
import StyledCheckbox from '../styled_checkbox/styled_checkbox';

const useStyles = makeStyles((theme)=>{
  return({
    infoContainer:{
      width:"100%",
      display:"flex",
      flexDirection:"column",
      justifyContent:"space-around",
      alignContent:"center",
      alignItems:"center"
    },
    mainInfo:{
      width:"100%",
      display:"flex",
      flexDirection:"row",
      justifyContent:"space-around",
      alignContent:"center",
      alignItems:"center"
    },
    mainInput:{
      flexGrow:"1",
      margin:".5rem"
    },
    controls:{
      width:"100%",
      display:"flex",
      flexDirection:"row",
      justifyContent:"space-around",
      alignContent:"center",
      alignItems:"center"
    }
  });
})

const WalletInfo = ({
  starting_name="default_name",
  starting_address="0x0000000000000000000000000000000000000000",
  verified=false,
  is_default=false,
  editing = false,
  saveUpdates = (updatedInfo)=>{console.log(`Default Save Wallet Updates ${JSON.stringify(updatedInfo)}`)},
  updateEditing = ()=>{console.log(`Default Cancel Edit Wallet`)},
  verifyWallet = ()=>{console.log(`Default Verify Wallet`)},
  deleteWallet = ()=>{console.log(`Default Delete Wallet`)},
}) =>{
  const classes = useStyles();
  const [name, setName] = useState(starting_name);
  const [address, setAddress] = useState(starting_address);
  const [isEditing, setEditing] = useState(editing);
  const [makeDefault, setMakeDefault] = useState(is_default);
  const [modified, setModified] = useState(false);

  const [verificationButtonText, setVerificationButtonText] = useState("Verify Wallet");
  const verificationButtonTimeoutRef = useRef(null);
  const updateVerificationProgress = (progress)=>{
    const verificationProgressTestEnums = {
      '0':"Verifying.",
     '-1':"Wallet Error", 
      '1':"Verifying..",
     '-2':"Chain Error",
      '2':"Verifying...",
     '-3':"App Error",
      '3':"Sending.",
     '-4':"Signature Error",
      '4':"Sending..",
     '-5':"Transaction Error",
      '5':"Sent Request!",
      '6':"TX Accepted!"
    };
    const progressEnum =verificationProgressTestEnums[progress.toString()];
    if(typeof(progressEnum)!='undefined')
    {
      setVerificationButtonText(progressEnum)
    }
    if(verificationButtonTimeoutRef.current)
    {
      clearTimeout(verificationButtonTimeoutRef.current);
    }
    setTimeout(()=>{
      if(verificationButtonTimeoutRef.current)
      {
        clearTimeout(verificationButtonTimeoutRef.current);
      }
      verificationButtonTimeoutRef.current = setTimeout(()=>{
        setVerificationButtonText("Verify Wallet");
      }, 10*1000);
    })
  };

  useEffect(()=>{
    return(()=>{
      if(verificationButtonTimeoutRef.current)
      {
        clearTimeout(verificationButtonTimeoutRef.current);
      }
    })
  },[]);

  function save()
  {
    if(modified)
    {
      saveUpdates({
        name,
        address,
        setDefault:makeDefault
      });
    }
    setEditing(false);
    if(updateEditing){updateEditing(false);}
  }

  function cancelEdit()
  {
    setName(starting_name);
    setAddress(starting_address);
    setMakeDefault(is_default);
    setEditing(false);
    if(updateEditing){updateEditing(false);}
  }

  function checkModified()
  {
    let is_modified=false;
    if(name != starting_name ||
      address != starting_address || 
      makeDefault != is_default)
    {
      is_modified = true;
    }
    setModified(is_modified)
  }
  useEffect(checkModified, [
    name, address, makeDefault
  ]);

  return(<div className={classes.infoContainer}>
    <div className={classes.mainInfo}>
      <StyledInput
        value ={name}
        label="Wallet Name"
        type="text"
        updateForm={(newVal)=>{
          setName(newVal);
        }}
        className={classes.mainInput}
      ></StyledInput>
      <StyledInput
        value ={address}
        label={`Wallet Address${is_default?" (Default)":""}${verified?" (Verified)":""}`}
        type="text"
        updateForm={(newVal)=>{
          setAddress(newVal);
        }}
        className={classes.mainInput}
      ></StyledInput>
      {isEditing?<Button
        variant='contained'
        color='primary'
        onClick={save}
      ><SaveIcon/> Save</Button>
        :
      <Button
        variant='outlined'
        color='primary'
        onClick={()=>{
          setEditing(true);
          if(updateEditing){updateEditing(true);}
        }}
      ><EditIcon/> Edit</Button>}
    </div>
    {isEditing?<div className={classes.controls}>
      <StyledCheckbox
        checked={makeDefault}
        label="Set As Default"
        updateForm={(newVal)=>{
          setMakeDefault(newVal);
        }}
      />
      {typeof(verifyWallet)=='function' && !verified?<Button
        variant='contained'
        color='primary'
        onClick={()=>{verifyWallet(updateVerificationProgress)}}
      ><StarsIcon/>{verificationButtonText}</Button>
      :null}
      {typeof(deleteWallet)=='function'?<Button
        variant='contained'
        color='primary'
        onClick={deleteWallet}
      ><DeleteIcon/> Delete Wallet</Button>:null}
      <Button
        variant='contained'
        color='primary'
        onClick={cancelEdit}
      ><CancelIcon/> Cancel Edit</Button>
    </div>:null}
  </div>)
}


export default WalletInfo;