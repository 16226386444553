import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import Typography from '@material-ui/core/Typography';

import Section from '../section/section';
import Translator from '../../scripts/Translator';
const translate = Translator({});

const useStyles = makeStyles((theme)=>{
  return({
    receiverDetails:{
      display:"flex",
      flexDirection:"column",
      justifyContent:"center",
      alignContent:"flex-start",
      flexWrap:"wrap",
      margin:"0.5rem"
    },
    receiverDetailsContainer:{
      display:"flex",
      flexDirection:"column",
      justifyContent:"center",
      alignContent:"flex-start",
      paddingBottom:"1rem"
    },
    receiverTitle:{
      display:"inline-block",
      wordWrap:"break-word",
      flexBasis:"100%",
      marginTop:".5rem",
      marginBottom:".5rem",
      paddingLeft:"0.5rem",
    },
    receiverSendRequestText:{
      display:"inline-block",
      flexBasis:"100%",
      paddingLeft:"0.5rem",
    },
    receiverDetailsLeft:{
      display:"flex",
      flexDirection:"row",
      flexWrap:"wrap",
      marginTop:"0.5rem",
      boxSizing:"border-box",
      width:"50%",
      paddingLeft:".5rem",
      paddingRight:".5rem",
      flexShrink:"1",
      [theme.breakpoints.down("sm")]:{
        width:"100%",
      }
    },
    receiverDetailsRight:{
      marginTop:"0.5rem",
      boxSizing:"border-box",
      width:"50%",
      paddingLeft:".5rem",
      paddingRight:".5rem",
      flexShrink:"1",
      [theme.breakpoints.down("sm")]:{
        width:"100%",
      }
    },
    percentContainer:{
      display:"inline-block",
      textAlign:"center",
      width: "100%"
    },
    percentValue:{
      display:"inline-block",
      padding:"0.5rem",
      borderRadius:".25rem",
      border:"1px solid black",
      width: "auto"
    }
  })
});

const ReviewContractReceiverSection = (props)=>{
  const {
    title="Receiver",
    cangeaID,
    address,
    email,
    sendRequest,
    percent,
    first,
    last,
    company,
    phone,
    street,
    city,
    state,
    country,
    zip
  } = props;

  
  const theme = useTheme();
  const classes = useStyles(theme);

  const ReceiverDetails = ({title, caption, style={}})=>{
    return(<div className={classes.receiverDetails} style={{...style}}>
      <Typography variant="caption" style={{display:"inline-block", wordWrap:"break-word", width:"100%"}} nowrap={"false"}>{title}</Typography>
      <Typography variant="body1" style={{display:"inline-block", wordWrap:"break-word", width:"100%"}} nowrap={"false"}>{caption}</Typography>
    </div>)
  }

  let className = props.className?props.className:"";

  const LeftSection = ()=>{
    return(<div className = {classes.receiverDetailsLeft}>
      {company&&company.length>0?<ReceiverDetails title="Company" caption={`${company}`}/>:null}
      <ReceiverDetails title="Contact Name" caption={`${last}, ${first}`}/>
      <ReceiverDetails title="Contact Email Address" caption={`${email}`}/>
      <ReceiverDetails title="Contact Phone Number" caption={`${phone}`}/>
      <ReceiverDetails title="Contact Cangea ID" caption={`${cangeaID}`}/>
      <ReceiverDetails title="Street Address" caption={`${street}`}/>
      <ReceiverDetails title="City, State, Zip" caption={`${city}, ${state}, ${zip}`}/>
      <ReceiverDetails title="Country" caption={`${country}`}/>
  </div>);
  }

  const RightSection = ()=>{
    return(<div className = {classes.receiverDetailsRight}>
      <ReceiverDetails title="Wallet Address" caption={`${address}`}  style={{textAlign:"center"}}/>
      <div className={classes.percentContainer}>
        <Typography variant="body2">Percent to Receive</Typography>
        <div className={classes.percentValue}><Typography variant="h5">{percent}%</Typography></div>
      </div>
    </div>);
  };

  return(<Section 
    title = {`${title} Information`}
    icon = {<FingerprintIcon/>}
    className = {className}
  >
  <div className = {classes.receiverDetailsContainer}>
    <Typography variant="h5" className={classes.receiverTitle}>{company&&company.length>0?company:`${first} ${last}`}</Typography>
    <Typography variant="caption" className={classes.receiverSendRequestText}>{`Contact info may${sendRequest?"":" not"} be edited by Recipient before deployment`}</Typography>
    <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap"}}>{LeftSection()}
    {RightSection()}</div>
  </div>
  </Section>)
};

export default ReviewContractReceiverSection;