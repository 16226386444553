import React from 'react';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

const PublicNavLinks = [{
  text:"Log In",
  icon:<AccountBoxIcon/>,
  page: "/login"
},{
  text:"Register",
  icon:<AccountBoxIcon/>,
  page: "/register"
},{
  text:"Recover Account",
  icon:<AccountBoxIcon/>,
  page: "/forgot-password"
}];

export default PublicNavLinks;
