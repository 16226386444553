import web3 from 'web3';

const ConnectWebProvider = async (opts={})=>{
  let Web3;
  if (window.ethereum) {
    // Web3 is injected by MetaMask if ethereum is available
    // prettier-ignore
    // eslint-disable-next-line no-unused-vars,no-undef
    Web3 = new web3(window.ethereum);
    return(window.ethereum.enable().catch((error)=>{
      console.error(error);
      throw new Error('Unable to connect to Web3: ' + error.toString());
    }).then(wallets => {
      console.log('New Web3 Provider Connected!');
      console.log(`Wallets: ${wallets}`);
      return({Web3, metamaskWallet: wallets.length>0?wallets[0]:null});
    }));
  } else if (typeof window.web3 != 'undefined') {
    console.log('New Web3 Object Found');
    if (typeof window.web3.currentProvider != 'undefined') {
      Web3 = new web3(window.web3.currentProvider);
      return(Web3);
    } else {
      throw new Error('Missing Current Web3 Provider');
    }
  } else {
    console.error('Unable to detect to Web3!');
  }
}

export default ConnectWebProvider;