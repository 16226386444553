import React, {useState, useEffect} from 'react';

import {makeStyles, useTheme} from '@material-ui/core/styles';
import MemoryIcon from '@material-ui/icons/Memory';
import Button from '@material-ui/core/Button';

import Section from '../section/section';
import StyledInput from '../styled_input/styled_input';


const useStyles = makeStyles((theme)=>{
  return({
    calcContainer:{
      display:"flex",
      flexDirection:"row",
      flexWrap:"wrap",
      justifyContent:"center",
      alignItems:"center"
    },
    calcInput:{
      flexGrow:"1"
    }
  })
});

function PaymentCalculator({ethPrice = 268.77, cngPrice = 0.0039, title="Payment Calculator", actionText="Purchase Tokens",
  onAction=(numEth, progressUpdateFn)=>{
    console.log(`Default On Action: Purchase CNG for ${numEth}`);
  },
  className=""
})
{
  const theme = useTheme();
  const classes = useStyles(theme);

  const [numTokens, setNumTokens] = useState(0);
  const [numEth, setNumEth] = useState(0);
  const [usdCost, setUsdCost] = useState(0);

  const calcChanges = (updated)=> (newVal) =>{
    console.log(`Recalculating... ${updated}`);
    console.log()
    switch(updated)
    {
      case "cng":{
        let eth = newVal * cngPrice / ethPrice;
        console.log(eth);
        setNumEth(eth);
        setUsdCost(newVal * cngPrice);
        setNumTokens(newVal);
        break;
      }
      case "eth":{
        setNumTokens(ethPrice * newVal / cngPrice);
        setUsdCost(newVal * ethPrice);
        setNumEth(newVal);
        break;
      }
      case "usd":{
        setNumTokens(newVal / cngPrice);
        setNumEth(newVal / ethPrice);
        setUsdCost(newVal);
        break;
      }
    }
  }

  console.log(`Eth: ${numEth}, cng: ${numTokens}, @${usdCost}`);
  return(<Section
      title={title}
      icon={<MemoryIcon/>}
      className={className}
    >
      <div className={classes.calcContainer}>
        <StyledInput
          value={numTokens}
          label={"Number of Tokens"}
          type="number"
          step="1"
          updateForm={calcChanges("cng")}
          className = {classes.calcInput}
        />
        <StyledInput
          value={numEth}
          label={"Required ETH Amount"}
          type="number"
          step="any"
          updateForm={calcChanges("eth")}
          className = {classes.calcInput}
        />
        <StyledInput
          value={usdCost}
          label={"USD Cost"}
          type="number"
          step={10}
          updateForm={calcChanges("usd")}
          className = {classes.calcInput}
        />
        {onAction&&<Button
          variant="contained"
          color="primary"
          onClick = {()=>{
            onAction(numEth);
          }}
        >{actionText}</Button>}
      </div>
    </Section>)
}

export default PaymentCalculator;