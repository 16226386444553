import Register from './Register';
import Login2FA from './Login2FA';
import Login from './Login';
import GetUserInfo from './GetUserInfo';
import GetUserRoles from './GetUserRoles';
import GetUserContracts from './GetUserContracts';
import GetUserTransactions from './GetUserTransactions';
import GetUserWallets from './GetUserWallets';
import GetWordPressPosts from './GetWordPressPosts';
import SendInvoiceRequest from './SendInvoiceRequest';
import CreateContract from './CreateContract';
import EditContract from './EditContract';
import DeleteContract from './DeleteContract';
import GenerateContract from './GenerateContract';
import AdminDeployContract from './AdminDeployContract';
import GetTransactionsByAddress from './GetTransactionsByAddress';
import GetTutorialLinks from './GetVideoTutorials';
import SetupTwoFactorAuth from './TwoFactorAuthSetupStart';
import TwoFactorAuthSetupComplete from './TwoFactorAuthSetupComplete';
import RemoveTwoFactorAuth from './TwoFactorAuthRemoval';
import GetTwoFactorAuthStatus from './GetTwoFactorAuthStatus';
import GetContractAddresses from './GetContractAddresses';
import UpdateWallet from './UpdateWallet';
import RemoveWallet from './RemoveWallet';
import UpdateBusinessProfile from './UpdateBusinessProfile';
import UpdatePersonalProfile from './UpdatePersonalProfile';
import UpdatePassword from './UpdatePassword';
import LookupUser from './LookupUser';
import UpdateRecipientInfo from './UpdateRecipientInfo';

const CangeaAPI = {
  register: Register,
  auth:{
    login: Login,
    twoFactorLogin: Login2FA,
  },
  mfa:{
    setup: SetupTwoFactorAuth,
    complete: TwoFactorAuthSetupComplete,
    remove: RemoveTwoFactorAuth,
    status: GetTwoFactorAuthStatus
  },
  getEnvironmentAddresses: GetContractAddresses,
  getUserInfo: GetUserInfo,
  getUserRoles: GetUserRoles,
  profile:{
    updateBusiness: UpdateBusinessProfile,
    updatePersonal: UpdatePersonalProfile,
  },
  lookupUser: LookupUser,
  updatePassword: UpdatePassword,
  getUserContracts: GetUserContracts,
  getUserTransactions:GetUserTransactions,
  getUserWallets: GetUserWallets,
  wallets:{
    myWallets: GetUserWallets,
    update: UpdateWallet,
    remove: RemoveWallet,
  },
  getNewsPosts: GetWordPressPosts,
  sendInvoiceRequest: SendInvoiceRequest,
  contracts:{
    create: CreateContract,
    edit: EditContract,
    delete: DeleteContract,
    generate: GenerateContract,
    deploy: AdminDeployContract,
    updateRecipientInfo: UpdateRecipientInfo
  },
  getTransactionsByAddress: GetTransactionsByAddress,
  getTutorialLinks: GetTutorialLinks,
};

export default CangeaAPI;