import XHR_GET from '../scripts/XHR_GET';
import APIRoutes from './APIRoutes';
import GetUserRoles from './GetUserRoles';
import GetTwoFactorAuthStatus from './GetTwoFactorAuthStatus';

const GetUserInfo = async (authToken, {
  getBusiness= true,
  getPersonal= true,
  getRoles = true,
  getTwoFactorStatus=true,
}) =>{
  let personal = null;
  let business = null;
  let roles =null;
  let twoFactorStatus = null;
  if(getPersonal)
  {
    personal = await XHR_GET({
      route: APIRoutes.profile.personal,
      authToken,
    }).then(info=>{
      //Do post-processing here
      return(info.info);
    });
  }
  if(getBusiness)
  {
    business = await XHR_GET({
      route: APIRoutes.profile.business,
      authToken,
    }).then(info=>{
      //Do post-processing here
      return(info.info);
    });
  }
  if(getRoles)
  {
    roles = await GetUserRoles(authToken, {});
  }
  if(getTwoFactorStatus)
  {
    twoFactorStatus = await GetTwoFactorAuthStatus(authToken);
  }
  return({personal, business, roles, twoFactor:twoFactorStatus});
};

export default GetUserInfo;