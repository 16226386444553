import BigNumber from 'bignumber.js';
import ABIs from '../abis';
import CreateContractMethod from '../createContractMethod';

const PurchaseTokens = async (
  Web3=null,
  contractAddress='0x0',
  numEth=0,
  opts={},
  privateKey=null,
  progressFunction=(stage)=>{
    console.log(`PurchaseContractDeployment call has reached stage ${stage}`);
  },
  handleCallReturnValue= async (val)=>{
    //Returns whether to proceed with sending the actual transaction
    return(true);
  }
)=>{
  return(
    CreateContractMethod(
      ABIs.Crowdsale,
      [], 
      'buy',
    )(
      Web3,
      contractAddress,
      [],
      opts={
        ...opts,
        value:new BigNumber(numEth).times(1e18).integerValue().toString()
      },
      privateKey,
      progressFunction,
      handleCallReturnValue
    )
  );
}

const CrowdsaleContractMethods = {
  purchaseTokens: PurchaseTokens
};
export default CrowdsaleContractMethods;