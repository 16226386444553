const APIRoutes ={
  logout: "/logout",
  register: "/register",
  links:"/links",
  login: {
    password: "/auth/login",
    multiFactor: "/auth/twofactor/login"
  },
  mfa:{
    setup: '/twofactor/setup',
    completeSetup: '/twofactor/confirm',
    remove: '/twofactor/remove',
    status: '/twofactor/status'
  },
  profile:{
    personal: "/profile/personal",
    business: "/profile/business",
    roles: "/account/roles"
  },
  userLookup:"/lookup/paymentInfo",
  password:{
    update:"/password/update"
  },
  contracts:{
    myContracts:"/contracts",
    info:"/contracts/info",
    create: "/contracts/create",
    edit: "/contracts/edit",
    delete: "/contracts/remove",
    generate:"/contracts/generate",
    deploy: "/contracts/deploy",
    addresses: "/contracts/addresses",
    recipientUpdate: "/contracts/updateReceiver"
  },
  transactions:{
    myTransactions:"/transactions/mytransactions",
    received:"/transactions/received",
    sent:"/transactions/sent",
    search: "/transactions/search"
  },
  wallets:{
    myWallets:"/wallets/mywallets",
    update: "/wallets/update",
    remove: "/wallets/delete"
  },
  invoice:{
    request:"/invoices/sendInvoice"
  }
  
}
export default APIRoutes;