import React from 'react';

import {makeStyles, useTheme} from '@material-ui/core/styles';

import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import Button from '@material-ui/core/Button';

import PageTitle from '../../components/page_title/page_title';
import ReviewContractMetaSection from '../../components/review_contract_meta/review_contract_meta';
import ReviewContractReceiverSection from '../../components/review_contract_receiver/review_contract_receiver';

const useStyles = makeStyles((theme)=>{
  return({
    main:{
      display:"flex",
      flexDirection:"column",
      flexWrap:"wrap",
      justifyContent:"flex-start",
      alignContent:"center",
      alignItems:"center",
      height: "100%",
      width: "100%",
      padding:"1rem"
    },
    reviewMetaSection:{
      width: "100%"
    },
    reviewReceiverSection:{
      width:"100%"
    },
    receiversArea:{
      display:"flex",
      flexDirection:"row",
      flexWrap:"wrap",
      justifyContent:"flex-start",
      alignItems:"stretch",
      width:"100%"
    },
    controlsContainer:{
      display:"flex",
      width:"100%",
      flexDirection:"row",
      justifyContent:"center",
      '& > *':{
        margin:"0.5rem",
        width:"auto"
      }
    }
  })
});

const ReviewContractPage = ({
  meta={
    name:"default_name",
    primaryEmail:"default@cangea.io",
    description:"A Default Decscription with some lorem ipsum dolor media",
    management:"0x0000000000000000000000000000000000000000",
    activeOnly:false,
    erc223:false,
    receivable:false
  },
  receivers=[{
    address:"0x0000000000000000000000000000000000000000",
    cangeaID: "(Unassigned)",
    first:"John",
    last:"Doe",
    company:"John's Dough",
    street:"123 Default St",
    city: "Testington",
    state:"MO",
    zip:"63100",
    country:"United States of America",
    email:"example@cangea.io",
    sendRequest:false,
    percent:100,
    phone:"(636) 208-6868",
  },{
    address:"0x0000000000000000000000000000000000000001",
    cangeaID: "(Unassigned)",
    first:"John",
    last:"Doe",
    company:"John's Dough",
    street:"123 Default St",
    city: "Testington",
    state:"MO",
    zip:"63100",
    country:"United States of America",
    email:"example@cangea.io",
    sendRequest:true,
    percent:100,
    phone:"(636) 208-6868",
  }],
  help_link_url="/help",
  purchaseContract = ()=>{console.log(`Default Purchase Contract`)},
  editContract = ()=>{console.log(`Default Edit Contract`)},
  isAdmin = false,
  deployContract = ()=>{console.log(`Default Deploy Contract`)}
})=>{
  const theme = useTheme();
  const classes = useStyles(theme);
  return(<div className={classes.main}>
    <PageTitle
      title="Review Payment Contract"
      icon={<LibraryAddCheckIcon/>}
      link={help_link_url}
    />
    <ReviewContractMetaSection
      {...meta}
      className={classes.reviewMetaSection}
    ></ReviewContractMetaSection>
    <div className={classes.receiversArea}>
      {receivers.map((receiver, index)=>{
        return(<ReviewContractReceiverSection
          {...receiver} 
          key={`${receiver.address}+${index}`}
          className={classes.reviewReceiverSection}
        />)
      })}
    </div>
    <div className={classes.controlsContainer}>
      <Button
        variant="outlined"
        color="primary"
        onClick={()=>{
          editContract();
        }}
      >Edit Contract</Button><br/>
      <Button
        variant="contained"
        color="primary"
        onClick={()=>{
          purchaseContract();
        }}
      >Purchase Contract</Button><br/>
      {isAdmin?<Button
        variant="contained"
        color="secondary"
        onClick={()=>{
          deployContract();
        }}
      >Manually Deploy Contract</Button>:null}
    </div>
  </div>)
}

export default ReviewContractPage;