import React, { useState, useEffect } from 'react';
import {makeStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
      minWidth: "10rem"
    },
    dense: {
      marginTop: 19,
    },
    menu: {
      width: 200,
    },
}));

function StyledInput(props)
{
    const classes = useStyles();
    let {
        value="",
        variant='outlined',
        items=[{
            label:"Default Label",
            value: "default_value"
        }],
        disabled=false
    } =props;

    const [isOpen, setOpen] = useState(false); 
    const [labelWidth, setLabelWidth] = useState(0);
    const updateForm = typeof(props.updateForm)=='function'?props.updateForm:function({updated_value}){
        console.log(`Updating Styled Input Value: ${updated_value}`);
    };

    function onOpen(){
        setOpen(true);
    }
    function onClose(){
        setOpen(false);
    }

    const handleChange = event => {
        let value = event.target.value;
        console.log(value);
        updateForm(value);
        setOpen(false);
    };
    const labelRefCallback = element =>{
        if(element)
        {
            setLabelWidth(element.offsetWidth);
        }
    }
    const Label = <InputLabel style={{left:"0rem"}} ref={labelRefCallback}>{props.label}</InputLabel>;
    
    let outlinedProp = {
        labelWidth
    };
    if(variant!='outlined')
    {
        delete outlinedProp.labelWidth;
    }
    let valueFound = false;
    const menuItems = Array.isArray(items)?items.map((item, index)=>{
        if(item.value == value)
        {
            valueFound=true;
        }
        return(<MenuItem value={item.value} key={index}>{item.label}</MenuItem>)
    }):[];
    if(!valueFound)
    {
        menuItems.push(<MenuItem value={value} key={menuItems.length}>{value}</MenuItem>)
    }
    return(<FormControl 
        className = {[classes.formControl, props.className?props.className:""].join(' ')}
        variant="outlined"
        style = {{...props.style}}
    >
        {Label}
        <Select
          variant="outlined"
          open={isOpen}
          onClose={onClose}
          onOpen={onOpen}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          {...outlinedProp}
        >{
            menuItems
        }</Select>
    </FormControl>);
}

export default StyledInput;
