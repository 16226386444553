import XHR_POST from '../scripts/XHR_POST';
import APIRoutes from './APIRoutes';

const UpdateRecipientInfo = async (info={}, requestToken=null) =>{
  if(typeof(info)!='object')
  {
    throw {
      error: new Error(`Must supply a recipient info object to UpdateRecipientInfo`),
      response: "",
      status: 400
    }
  }
  if(!requestToken)
  {
    throw {
      error: new Error(`Must supply a Request Authorization Token to UpdateRecipientInfo`),
      response: "",
      status: 400,
    };
  }
  else
  {
    return(XHR_POST({
      route: APIRoutes.contracts.recipientUpdate,
      data:{
        info,token:requestToken
      },
    }).catch(e=>{
      console.error(e);
      throw {
        error: new Error(`Unable to update recipient info`),
        response: e.response?e.response:"",
        status: e.status?e.status:500
      };
    }).then(({message, info})=>{
      return({message, info});
    }));
  }
}

export default UpdateRecipientInfo;