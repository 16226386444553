import CreateTokenContract from './tokenContract';
import CreateWalletContract from './walletContract';
import CreatePurchasesContract from './purchasesContract';

//Instantiates the Web3 Object, containing general Web3 and Cangea-Specific APIs
//  Callback receives (error, Web3_API_Object)
//  opts may be:
//  debug: output debugging logs to console
// web3Container.js
function createWeb3API(opts={}, ContractAddresses, Web3) {
  let UserWallet;
  console.log('web3Container.js createWeb3: calling connectProvider');
  return({
    Web3: Web3,
    UserWallet: UserWallet,
    ContractAddresses: ContractAddresses,
    Purchases: CreatePurchasesContract(
      ContractAddresses.cangea,
      ContractAddresses.purchases,
      Web3,
      opts
    ),
    Wallets: CreateWalletContract(
      ContractAddresses.verifications,
      Web3,
      opts
    ),
    Token: CreateTokenContract(ContractAddresses.cangea, Web3, opts),
    getCurrentWallet: createGetCurrentWallet(Web3)
  });
}

//TODO: Why does this function not do anything?
function createGetCurrentWallet(_Web3) {
  //TODO: This function is never called.
  function getCurrentWallet() {
    //Dynamic array, each access queries MM
    return _Web3.eth.accounts[0];
  }
  return(getCurrentWallet);
}
export default createWeb3API;
