import React, {useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';

import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import OfflineBoltOutlinedIcon from '@material-ui/icons/OfflineBoltOutlined';

import PageTitle from '../../components/page_title/page_title';
import Section from '../../components/section/section';
import ContractManagementSection from '../../components/contract_management_section/contract_management_section';
import ContractAddressSection from '../../components/contract_address_section/contract_address_section';
import ContractReceiversSection from '../../components/contract_receivers_section/contract_receivers_section';
import ContractDetailsMeta from '../../components/contract_details_meta/contract_details_meta';
import ContractEventsTable from '../../components/contract_events_table/contract_events_table';

const useStyles = makeStyles((theme)=>{
  return({
    infoContainer:{
      display:"flex",
      flexDirection:"column",
      justifyContent:"center",
      alignContent:"center",
      alignItems:"center",
   },
   main:{
    display:"flex",
    flexDirection:"row",
    flexWrap:"wrap",
    justifyContent:"flex-start",
    alignContent:"flex-start",
    alignItems:"center",
    height: "100%",
    width: "100%",
    padding:"1rem"
  },
  infoSection:{width:"100%"},
  top:{
    width:"100%",
    display:"flex",
    flexDirection:"row",
    flexWrap:"nowrap",
    alignItems:"stretch",
    justifyContent:"center"
  },
  topLeft:{
    flexBasis:"30%",
    display:"flex",
    flexDirection:"column",
    justifyContent:"flex-start",
    alignItems:"stretch",
    '& > *':{
      height:"100%"
    }
  },
  topRight:{
    flexBasis:"70%",
    display:"flex",
    flexDirection:"column",
    justifyContent:"flex-start",
    alignItems:"stretch"
  },
  tableSection:{
    padding:"0",
    width:"100%",
    '& > div':{
      padding:"0"
    }
  }
});
  
});


const defaultTx = {
  transactionHash:"test_transaction_hash_which_is_really_long",
  blockHash: "test_block_hash_which_is_also_really_long",
  logIndex:1,
  sender:"0x13d3d44e768ee41b16d73c7db623fbc41b4de748",
  recipient:"0x13d3d44e768ee41b16d73c7db623fbc41b4de748",
  senderName:"dispense_mo_test",
  senderContract:false,
  senderOwned: false,
  recipientName:"logged_in_user",
  recipientContract: false,
  recipientOwned:true,
  date: Date.now(),
  timestamp: new Date(Date.now()).toISOString(),
  amount:"50000000000000000000000",
  token: "Cangea",
  symbol: "CNG",
  tokenAddress:"0xe08e982F04c9919E7900DbA37Cc757F7751F7919",
  value: 50.00,
  price: "0.0000000000000000001"
};

const ContractDetailsPage = ({
  address="test_address",
  name="default_contract_name",
  canManage=true,
  archiveContract= async({})=>{},
  freezeContract= async({})=>{},
  thawContract= async({})=>{},
  Web3=null,
  description="",
  status="",
  deployed=true,
  frozen=true,
  archived=false,
  activeOnly=false,
  erc223=false,
  receivable=false,
  totalReceived= "0",
  numTransactions= 0,
  transfers=[],
  receivers=[],
  sendInvoiceRequest=()=>{console.log(`Request Funds Default`)}
})=>{
  const theme = useTheme();
  const classes = useStyles(theme);

  return(
  <div className={classes.main}>
    <PageTitle icon={<InfoIcon/>} title={`Contract Details: ${name}`} style={{flexBasis:"100%", flexShrink:"0"}}/>
    <div className = {classes.top}>
      <div className={classes.topLeft}>
      <ContractAddressSection
        address={address}
        sendInvoiceRequest={sendInvoiceRequest}
      />
      </div>
      <div className={classes.topRight}>
        {ContractDetailsMeta({name,
          description,
          status,
          deployed,
          frozen,
          archived,
          activeOnly,
          erc223,
          receivable,
          totalReceived,
          numTransactions})
        }
        <ContractReceiversSection
          receivers={receivers}
        />
      </div>
    </div>
    {canManage?<ContractManagementSection
      freezeContract={freezeContract}
      thawContract={thawContract}
      archiveContract={archiveContract}
      contractFrozen = {frozen}
      contractArchived = {archived}
      Web3={Web3}
    />:null}
    <Section 
      className={classes.tableSection}
    >
      <ContractEventsTable
        title={<div style={{display:"flex",flexDirection:"row",alignItems:"center"}}><OfflineBoltOutlinedIcon style={{marginRight:"0.5rem"}}/>Contract Events</div>}
        transfers={transfers} receivers={receivers} getMore={null}
      ></ContractEventsTable>
    </Section>
  </div>)
}

export default ContractDetailsPage;