import BigNumber from 'bignumber.js';
import ABIs from '../abis';
import CreateContractMethod from '../createContractMethod';

const FreezeContract = async (
  Web3=null,
  contractAddress='0x0',
  opts={},
  privateKey=null,
  progressFunction=(stage)=>{
    console.log(`FreezeContract call has reached stage ${stage}`);
  },
  handleCallReturnValue= async (val)=>{
    //Returns whether to proceed with sending the actual transaction
    return(true);
  }
)=>{
  return(
    CreateContractMethod(
      ABIs.SplitPayment,
      [], 
      'Freeze',
    )(
      Web3,
      contractAddress,
      [],
      opts={
        ...opts,
      },
      privateKey,
      progressFunction,
      handleCallReturnValue
    )
  );
}

const ThawContract = async (
  Web3=null,
  contractAddress='0x0',
  opts={},
  privateKey=null,
  progressFunction=(stage)=>{
    console.log(`ThawContract call has reached stage ${stage}`);
  },
  handleCallReturnValue= async (val)=>{
    //Returns whether to proceed with sending the actual transaction
    return(true);
  }
)=>{
  return(
    CreateContractMethod(
      ABIs.SplitPayment,
      [], 
      'Thaw',
    )(
      Web3,
      contractAddress,
      [],
      opts={
        ...opts,
      },
      privateKey,
      progressFunction,
      handleCallReturnValue
    )
  );
}

const ArchiveContract = async (
  Web3=null,
  contractAddress='0x0',
  opts={},
  privateKey=null,
  progressFunction=(stage)=>{
    console.log(`ArchiveContract call has reached stage ${stage}`);
  },
  handleCallReturnValue= async (val)=>{
    //Returns whether to proceed with sending the actual transaction
    return(true);
  }
)=>{
  return(
    CreateContractMethod(
      ABIs.SplitPayment,
      [], 
      'Archive',
    )(
      Web3,
      contractAddress,
      [],
      opts={
        ...opts,
      },
      privateKey,
      progressFunction,
      handleCallReturnValue
    )
  );
}

const SplitPaymentContractMethods = {
  freeze: FreezeContract,
  thaw: ThawContract,
  archive: ArchiveContract
};

export default SplitPaymentContractMethods;