import React, {useState, useEffect, useRef} from 'react';
import BigNumber from 'bignumber.js';
import QRCode from 'qrcode.react';
import AutoSizer from 'react-virtualized-auto-sizer';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {default as useOnboardingStyles} from '../onboarding/onboarding_style';

import CopyAddressButton from '../../components/copy_address_button/copy_address_button';
import TimeoutTextButton from '../../components/timeout_text_button/timeout_text_button';

const useStyles = makeStyles((theme)=>{
  return({
    mainContainer:{
      display:"flex",
      flexDirection:"column",
      justifyContent:"center",
      alignContent:"center",
      alignItems:"center",
      padding:"0.5rem"
    },
    qrCode:{
      height:"15rem",
      width:"15rem",
      maxWidth:"800px",
      maxHeight:"800px",
      marginTop:"1rem",
      marginBottom:"2rem"
    },
    paymentOptionButton:{
      margin:"1rem",
    },
    metaMaskCTA:{
      margin:"1rem",
      '&:hover':{
        cursor:"pointer",
        color:"blue"
      }
    }
  });

});

const InvoicePaymentPage = (props)=>{
  const theme = useTheme();
  const onboardingClasses = useOnboardingStyles(theme);
  const classes = useStyles(theme);
  const {address="0x0000000000000000000000000000000000000000", amount=0, acceptedTokens=[], acceptsEth=false, Web3=null, ethPrice=1000.00, cngPrice=1.00, metamaskAddress=null, transferCNG=()=>{}} = props;
  const qrID="invoiceAddressQRID";

  const ethValue = new BigNumber(amount/100).div(ethPrice).times(1e18);
  const cngValue = new BigNumber(amount/100).div(cngPrice);
  const payWithEth = async ({
    updateFunction=(stage)=>{
      console.log(`Eth Payment at stage ${stage}`);
    },
    confirmationWait = 1
  })=>{
    if(!Web3 || !metamaskAddress)
    {
      updateFunction(-1);
      alert(`Must connect with MetaMask`);
    }
    else
    {
      updateFunction(1);
      const availableBalanceEth = await Web3.eth.getBalance(metamaskAddress, 'pending').catch(e=>{
        updateFunction(-2);
        console.error(e);
        let error = new Error(`Unable to determine existing Eth Balance:\n${JSON.stringify(e)}`);
        throw error;
      }).then(result=>{
        updateFunction(2);
        return(new BigNumber(result));
      });
      if(availableBalanceEth.lt(ethValue))
      {
        updateFunction(-3);
        throw new Error(`Insufficent Funds`);
      }
      else
      {
        if(confirmationWait > 12)
        {
          confirmationWait = 12;
        }
        updateFunction(3);
        let txReceipt=null;
        return new Promise((resolve, reject)=>{
          Web3.eth.sendTransaction({
            from: metamaskAddress,
            to: address,
            value: ethValue
          }).once('transactionHash', txHash=>{
            updateFunction(4);
            if(confirmationWait < 0)
            {
              resolve(txHash);
            }
          }).once('receipt', (receipt)=>{
            updateFunction(5);
            txReceipt = receipt;
            if(confirmationWait < 1)
            {
              resolve(receipt);
            }
          }).on('error', (e, receipt=null)=>{
            if(receipt)
            {
              console.log(`Transaction reverted with error`);
            }
            else
            {
              console.log(`Transaction failed`);
            }
            console.error(e);
            let error = new Error(`Transaction ${receipt?"reverted":"failed"}`);
            reject(error);
          }).on('confirmation', (number)=>{
            updateFunction(5 + number);
            if(confirmationWait==number)
            {
              resolve(txReceipt);
            }
          });
        });
      }
    }
  };

  const defaultPayWithEthButtonText = "Pay With ETH";
  const transactionInProgress = useRef(false);
  const [payWithEthButtonText, setPayWithEthButtonText] = useState(defaultPayWithEthButtonText);

  const handleEthButtonPress = async ()=>{
    if(!transactionInProgress.current)
    {
      transactionInProgress.current = true;
      const handleError = (error)=>{
        transactionInProgress.current = false;
        console.error(error);
        alert(`Error:\n${JSON.stringify(error)}`);
        return(null);
      }
      const updateFunction = (stage)=>{
        if(stage < 0)
        {
          setPayWithEthButtonText("ERROR");
        }
        else 
        {
          switch(stage)
          {
            case 1:
            {
              setPayWithEthButtonText("Checking Balance...");
              break;
            }
            case 3:
            {
              setPayWithEthButtonText("Signing Transaction...");
              break;
            }
            case 4:
            {
              setPayWithEthButtonText("Sending Transaction...");
              break;
            }
            case 5:
            {
              setPayWithEthButtonText("Transaction Mined...");
              break;
            }
            case 6:
            {
              setPayWithEthButtonText("Transaction Confirmed!");
              break;
            }
          }
        }
      }
      return(payWithEth({
        updateFunction,
        confirmationWait:1
      }).catch(handleError).then(receipt=>{
        if(receipt)
        {
          transactionInProgress.current = false;
          setPayWithEthButtonText("Sent Transaction!");
          alert(`Transaction Successful!\nHash: ${receipt.transactionHash}`);
        }
      }));
    }
    else
    {
      return(null);
    }
  }

  const defaultPayWithCngButtonText = "Pay With CNG";
  const [payWithCngButtonText, setPayWithCngButtonText] = useState(defaultPayWithCngButtonText);
  const handleCngButtonPress = async ()=>{
    if(!transactionInProgress.current)
    {
      transactionInProgress.current = true;
      const handleError = (error)=>{
        transactionInProgress.current = false;
        console.error(error);
        alert(`Error:\n${JSON.stringify(error)}`);
        return(null);
      }
      const progressFn = (stage)=>{
        if(stage < 0)
        {
          setPayWithCngButtonText("ERROR");
        }
        else 
        {
          switch(stage)
          {
            case 1:
            {
              setPayWithCngButtonText("Checking Balance...");
              break;
            }
            case 3:
            {
              setPayWithCngButtonText("Signing Transaction...");
              break;
            }
            case 4:
            {
              setPayWithCngButtonText("Sending Transaction...");
              break;
            }
            case 5:
            {
              setPayWithCngButtonText("Transaction Mined...");
              break;
            }
            case 6:
            {
              setPayWithCngButtonText("Transaction Confirmed!");
              break;
            }
          }
        }
      }
      return(transferCNG({
        numTokens: cngValue.times(1e18),
        to: address,
        progressFn
      }).catch(handleError).then(receipt=>{
        if(receipt)
        {
          transactionInProgress.current = false;
          setPayWithCngButtonText("Sent Transaction!");
          alert(`Transaction Successful!\nHash: ${receipt.transactionHash}`);
        }
      }));
    }
    else
    {
      return(null);
    }
  }

  return(<div className={onboardingClasses.baseContainer}>
    <div className={onboardingClasses.ImageContainer}/>
    <Container className={onboardingClasses.FormContainer} onSubmit={()=>{}}>
      <Typography variant="h5" style={{fontWeight:"600"}}>Send Payment of ${parseFloat(amount/100).toFixed(3)} to this Address</Typography>
      <Typography variant="caption" style={{fontWeight:"600"}}>({address})</Typography>
      <div className={classes.qrCode}>
        <AutoSizer >
            {({height, width})=>{
                const size = Math.min(width,height)-4;
                return(<div id={qrID} style={{height, width, display:"inline-block", textAlign:"center"}}><QRCode
                    value={address}
                    size = {size}
                    style={{boxSizing:"content-box", border: "2px solid black"}}
                ></QRCode></div>);
            }}
        </AutoSizer>
      </div>
      <CopyAddressButton
        buttonText={<Typography variant="body2">Copy Address</Typography>}
        copyContent={address}
        style={{margin:"1rem"}}
      />
      <div>{acceptsEth&&Web3&&metamaskAddress ?
        <TimeoutTextButton 
          defaultText={defaultPayWithEthButtonText}
          buttonText= {payWithEthButtonText}
          setButtonText={setPayWithEthButtonText}
          timeoutSeconds={30}
          onClick={handleEthButtonPress}
          className={classes.paymentOptionButton}
        />
        :null
      }
      {Web3&&metamaskAddress?
        <TimeoutTextButton 
          defaultText={defaultPayWithCngButtonText}
          buttonText= {payWithCngButtonText}
          setButtonText={setPayWithCngButtonText}
          timeoutSeconds={30}
          onClick={handleCngButtonPress}
          className={classes.paymentOptionButton}
        />
        :null
      }
      {Web3&&metamaskAddress?null:<div className={classes.metaMaskCTA} onClick={()=>{
        window.open("https://metamask.io/download.html",null,"statusbar,menubar,location,status,resizable,scrollbars,noopener,noreferrer");
      }}><Typography variant="h6" element='a'>Connect using MetaMask!</Typography></div>}</div>
    </Container>
  </div>)
}

export default InvoicePaymentPage;