import React, {useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import QRCode from 'qrcode.react';
import AutoSizer from 'react-virtualized-auto-sizer';

import SettingsRemoteIcon from '@material-ui/icons/SettingsRemote';
import InfoIcon from '@material-ui/icons/Info';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ListIcon from '@material-ui/icons/List';

import PageTitle from '../../components/page_title/page_title';
import Section from '../../components/section/section';
import CrowdsalePurchasesTable from '../../components/crowdsale_purchases_table/crowdsale_purchases_table';
import PaymentCalculator from '../../components/payment_calculator/payment_calculator';

const useStyles = makeStyles((theme)=>{
  return({
   infoContainer:{
      display:"flex",
      flexDirection:"column",
      justifyContent:"center",
      alignContent:"center",
      alignItems:"center",
   },
   main:{
    display:"flex",
    flexDirection:"row",
    flexWrap:"wrap",
    justifyContent:"flex-start",
    alignContent:"flex-start",
    alignItems:"center",
    height: "100%",
    width: "100%",
    padding:"1rem",
    paddingBottom:"5rem"
  },
  infoSection:{width:"100%"},
  qrCode:{
    height:"25rem",
    width:"25rem",
    maxWidth:"800px",
    maxHeight:"800px",
    marginTop:"1rem",
    marginBottom:"1rem"
  },
  crowdsaleDetail:{
    display:"flex",
    flexDirection:"column",
    marginLeft:"1rem",
    marginRight:"1rem",
    '& h6':{
      fontWeight:"600",
      width:"100%",
      textAlign:"center"
    },
    '& p':{
      fontWeight:"600",
      color:"grey",
      width:"100%",
      textAlign:"center"
    },
  }
  });
});


const PurchaseTokensPage = (props)=>{

  const {ethPrice = 1000, cngPrice = 0.0039, currentTier=0, crowdsaleAddress="0x859FE3225C37D00C83835369FeBc3313bE8d983C", purchaseTokensFunction=null, crowdsaleTransfers=[]} = props;
  const theme = useTheme();
  const classes = useStyles(theme);

  const CrowdsaleDetail = ({title, subtitle})=>{
    return(<div className = {classes.crowdsaleDetail}>
      <Typography variant="body1" component="h6">{title}</Typography>
      <Typography variant="caption" component="p">{subtitle}</Typography>
    </div>)
  }
  const qrID="crowdsaleQRID";
  return(
  <div className={classes.main}>
    <PageTitle icon={<CreditCardIcon/>} title={"Purchase Tokens"} style={{flexBasis:"100%", flexShrink:"0"}}/>
    <Section
      title="Crowdsale Details"
      icon={<SettingsRemoteIcon/>}
      className={classes.infoSection}
    >
      <div className = {classes.infoContainer}>
        <div style={{marginTop:"1rem",marginBottom:"1rem",display:"flex", flexFlow:"row wrap", justifyContent:"center", alignItems:"flex-start"}}>
          <CrowdsaleDetail title="Detected Price of ETH" subtitle={`$${ethPrice.toFixed(3)} / ETH`}/>
          <CrowdsaleDetail title="Current Price of CNG" subtitle={`$${cngPrice.toFixed(4)} / CNG`}/>
          <CrowdsaleDetail title="Current Crowdsale Tier" subtitle={`${currentTier.toFixed(0)}`}/>
        </div>
        <Typography variant="h5" style={{fontWeight:"600"}}>Send Payments to this Address</Typography>
        <Typography variant="caption" style={{fontWeight:"600"}}>({crowdsaleAddress})</Typography>
        <div className={classes.qrCode}>
          <AutoSizer >
              {({height, width})=>{
                  const size = Math.min(width,height)-4;
                  return(<div id={qrID} style={{height, width, display:"inline-block", textAlign:"center"}}><QRCode
                      value={crowdsaleAddress}
                      size = {size}
                      style={{boxSizing:"content-box", border: "2px solid black"}}
                  ></QRCode></div>);
              }}
          </AutoSizer>
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={()=>{
            console.log("Copy Address Default");
          }}
          style={{margin:"2rem"}}
        >Copy Address</Button>
      </div>
    </Section>
    <PaymentCalculator className={classes.infoSection}
      onAction={(numEth, numTokens)=>{
        purchaseTokensFunction(numEth, (progress)=>{
          console.log(`Purchase Tokens Progress ${progress}`)
        });
      }}
      ethPrice={ethPrice}
      cngPrice={cngPrice}
    />
    <Section
      title="Recent Purchases"
      icon={<ListIcon/>}
      className={classes.infoSection}
    ><CrowdsalePurchasesTable transfers={crowdsaleTransfers} getMore={null}/></Section>
  </div>)
}

export default PurchaseTokensPage;