import ABIs from '../abis';
import CreateContractMethod from '../createContractMethod';

const RequestWalletVerification = async (
  Web3=null,
  contractAddress='0x0',
  verificationCode=null,
  opts={},
  privateKey=null,
  progressFunction=(stage)=>{
    console.log(`RequestWalletVerification call has reached stage ${stage}`);
  },
  handleCallReturnValue= async (val)=>{
    //Returns whether to proceed with sending the actual transaction
    return(true);
  }) =>{
  return(
    CreateContractMethod(
      ABIs.Wallets,
      [
        'string'
      ], 
      'RequestVerification',
    )(
      Web3,
      contractAddress,
      [
        verificationCode
      ],
      opts,
      privateKey,
      progressFunction,
      handleCallReturnValue
    )
  );
}

const CheckWalletVerification = async (
  Web3=null,
  contractAddress='0x0',
  walletToCheck='0x0',
  opts={},
  progressFunction=(stage)=>{
    console.log(`CheckWalletVerification call has reached stage ${stage}`);
  }
)=>{
  return(new Promise((resolve, reject)=>{
    const handleCallReturnValue= async(val)=>{
      //Returns whether to proceed with sending the actual transaction
      // In this case simply resolves with called value and returns false
      resolve(val);
      return(false);
    }
    CreateContractMethod(
      ABIs.Wallets,
      [
        'address'
      ], 
      'VerifiedWallets',
    )(
      Web3,
      contractAddress,
      [
        walletToCheck
      ],
      opts,
      null,
      progressFunction,
      handleCallReturnValue
    ).catch(e=>{
      reject(e);
    })
  }));
}

const CheckRequestStatus = async (
  Web3=null,
  contractAddress='0x0',
  walletToCheck='0x0',
  opts={},
  progressFunction=(stage)=>{
    console.log(`CheckRequestStatus call has reached stage ${stage}`);
  }
)=>{
  return(new Promise((resolve, reject)=>{
    const handleCallReturnValue= async(val)=>{
      //Returns whether to proceed with sending the actual transaction
      // In this case simply resolves with called value and returns false
      resolve(val);
      return(false);
    }
    CreateContractMethod(
      ABIs.Wallets,
      [
        'address'
      ], 
      'VerificationRequests',
    )(
      Web3,
      contractAddress,
      [
        walletToCheck
      ],
      opts,
      null,
      progressFunction,
      handleCallReturnValue
    )
  }));
}

const WalletVerificationMethods = {
  getStatus: CheckRequestStatus,
  getIsVerified: CheckWalletVerification,
  verifyWallet: RequestWalletVerification
};

export default WalletVerificationMethods;