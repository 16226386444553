import XHR_GET from '../scripts/XHR_GET';
import APIRoutes from './APIRoutes';

const GetUserWallets = async (authToken, opts) =>{
  let wallets= await XHR_GET({
    route: APIRoutes.wallets.myWallets,
    authToken,
  }).then(info=>{
    //Do post-processing here
    return(info.wallets);
  });
  return(wallets);
};

export default GetUserWallets;