import XHR_GET from '../scripts/XHR_GET';
import APIRoutes from './APIRoutes';

const GetTutorialLinks= async (authToken, opts) =>
{
  const links = await XHR_GET({
    route: APIRoutes.links,
    authToken,
  }).then(info=>{
    //Do post-processing here
    console.log(info);
    return(info.links);
  })
  return(links);
}
  

export default GetTutorialLinks;