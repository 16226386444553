import 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme=>{return({
  BaseApp:{
    display:"flex",
    maxHeight: "100vh"
  },
  content: {
    boxSizing:"border-box",
    display:"flex",
    flexDirection: "column",
    margin:0,
    flexGrow: 1,
    flexShrink: 1,
    height: `calc(100%)`,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: "15rem",
  },
  toolbar: theme.mixins.toolbar,
})});

export default useStyles;