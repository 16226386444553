import React, {useState, useEffect, useRef} from 'react';
import Button from '@material-ui/core/Button';

const TimeoutTextButton = ({
  defaultText = "Default Text",
  buttonText = "Button Text",
  setButtonText = (newText)=>{
    console.log(`Default Timeout Text Button SetText`);
  },
  buttonIcon = null,
  iconFirst = false,
  timeoutSeconds = 15,
  variant = "contained",
  color="primary",
  onClick=()=>{
    console.log(`Default Timeout Text Button onClick`);
  },
  className="",
  style={}
}) =>{
  const timeoutRef = useRef(null);
  useEffect(()=>{
    console.log(`Setting timeout`);
    timeoutRef.current = setTimeout(()=>{
      setButtonText(defaultText);
      timeoutRef.current = null;
    }, timeoutSeconds*1000);
    return(()=>{
      console.log(`Clearing timeout`)
      if(timeoutRef.current)
      {
        clearTimeout(timeoutRef.current);
      }
    })
  },[buttonText]);

  const content = <React.Fragment key={`CopyButtonInnerText`}>{iconFirst?buttonIcon:buttonText}  {iconFirst?buttonText:buttonIcon}</React.Fragment>;
  return(<Button
    variant={variant}
    color={color}
    onClick={onClick}
    style={style}
    className={className}
  >{content}</Button>)
}

export default TimeoutTextButton;