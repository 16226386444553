const HandleXHRResponse = (x, callback) => () =>
{
  //Check if request is complete on change
  if(x.readyState==4)
  {
    //Check if server responded with success
    if(x.status!=200)
    {
      const createError = (error_string)=>{
        let error = new Error(error_string);
        error.status = x.status;
        error.response = x.response;
        return(error);
      }
      let error;
      switch(x.status)
      {
        case 400:{
          error = createError("Invalid Input");
          break;
        }
        case 401:{
          error = createError("User is not Logged In");
          break;
        }
        case 403:{
          error = createError("User is Forbidden from this resource");
          break;
        }
        case 404:{
          error = createError("Resource cannot be found");
          break;
        }
        case 404:{
          error = createError("Resource has conflict");
          break;
        }
        case 503:{
          error = createError("Internal Server Error");
          break;
        }
        default:{
          error = createError("Non-Typical Error");
          break;
        }
      }
      console.error(error);
      callback(error);
    }
    else
    {
      let responseType = x.getResponseHeader('content-type');
      var response;
      if(responseType.includes("application/json"))
      {
        try {
          response = JSON.parse(x.response);
        }
        catch(e)
        {
          response="!INVALID RESPONSE!";
        }
      }
      else
      {
        response = x.response;
      }
      callback(null, response);
      return;
    }
  }
}

export default HandleXHRResponse;