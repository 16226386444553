import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import BigNumber from 'bignumber.js';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  { id: 'description', numeric: false, disablePadding: false, label: 'Description' },
  { id: 'date', numeric: true, disablePadding: false, label: 'Timestamp' },
  { id: 'amount', numeric: true, disablePadding: false, label: 'Amount E(-18)' },
  { id: 'token', numeric: false, disablePadding: false, label: 'Token (Symbol)' },
  { id: 'value', numeric: true, disablePadding: false, label: 'Value (USD)' },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort} = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: "1rem",//theme.spacing(1),
    paddingRight: "1rem",//theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  tableHead:{
    backgroundColor: "#021226",
    color:"white",
    '& svg':{
      color:"white"
    }
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { title } = props;

  return (
    <Toolbar
      className={clsx(classes.root, classes.tableHead)}
    >
      <Typography className={classes.title} variant="h6" id="tableTitle">
        {title}
      </Typography>
      <Tooltip title="Filter list">
        <IconButton aria-label="filter list">
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    //minWidth: 750,
  },
  
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  viewMoreRow:{
    height: 33,
    textDecoration:"underline",
    '& :hover':{
      color:"blue",
      cursor:"pointer",
      textDecoration:"blue underline",
    }
  }
}));

const defaultTx = {
  transactionHash:"test_transaction_hash_which_is_really_long",
  blockHash: "test_block_hash_which_is_also_really_long",
  logIndex:1,
  sender:"0x13d3d44e768ee41b16d73c7db623fbc41b4de748",
  recipient:"0x13d3d44e768ee41b16d73c7db623fbc41b4de748",
  senderName:"dispense_mo_test",
  senderContract:false,
  senderOwned: false,
  recipientName:"logged_in_user",
  recipientContract: false,
  recipientOwned:true,
  date: Date.now(),
  timestamp: new Date(Date.now()).toISOString(),
  amount:"50000000000000000000000",
  token: "Cangea",
  symbol: "CNG",
  tokenAddress:"0xe08e982F04c9919E7900DbA37Cc757F7751F7919",
  value: 50.00,
  price: "0.0000000000000000001"
};

export default function TransfersTable(props) {
  const {
    transfers=[],
    getMore=()=>{console.log(`Default Get More`);},
    title="",
  } = props;
  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('date');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, txID) => {
    console.log(`Clicked TX ${txID}`)
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const reduceAddress = (addr)=>{
    return(`${addr.slice(0,5)}...${addr.slice(-5)}`);
  }

  const tableRows = Array.isArray(transfers)?stableSort(transfers.map(tx=>{
    if(tx.date==0)
    {
      tx.date = new Date(tx.timestamp).valueOf();
    }
    return(tx);
  }), getComparator(order, orderBy))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((tx, index) => {
      let txDescription = `Transfer from ${tx.senderName&&tx.senderName!='N/A'?` ${tx.senderName} `:""}(${reduceAddress(tx.from)}) to ${tx.recipientName&&tx.recipientName!='N/A'?` ${tx.recipientName} `:""}(${reduceAddress(tx.to)})`;
      let transferID = `${tx.hash}/${tx.logIndex}`;
      return (
        <TableRow
          //hover
          onClick={event => handleClick(event, transferID)}
          tabIndex={-1}
          key={transferID}
        >
          <TableCell component="th" scope="row" style={{wordBreak:"break-word", maxWidth:"25rem"}}>
            {txDescription}
          </TableCell>
          <TableCell align="right" style={{wordBreak:"break-word"}}>{tx.timestamp}</TableCell>
          <TableCell align="right">{tx.senderOwned?(tx.recipientOwned?"":"-"):(tx.recipentOwned?"+":"")}{new BigNumber(tx.amount).div(1e18).toPrecision(4)}</TableCell>
          <TableCell align="left">{tx.token} ({tx.symbol})</TableCell>
          <TableCell align="right">${new BigNumber(tx.value).toFixed(3)}</TableCell>
        </TableRow>
      );
    }):[];

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, transfers.length - page * rowsPerPage) - (typeof(getMore)=='function'?1:0);

  return (
    <div className={classes.root}>
      {/* <Paper className={classes.paper}> */}
        <EnhancedTableToolbar title={title}/>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size='small'
            aria-label="transfers table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={transfers.length}
            />
            <TableBody>
              {tableRows}
              {typeof(getMore)==='function'?(
              <TableRow 
                className={classes.viewMoreRow}
              >
                <TableCell 
                  colSpan={5}
                  onClick={getMore}
                  align="center"
                >View More</TableCell>
              </TableRow>):null}
              {emptyRows > 0 ? (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={5} />
                </TableRow>
              ):null}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={transfers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      {/* </Paper> */}
    </div>
  );
}