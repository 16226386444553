import React, {useState} from 'react';
import QRCode from 'qrcode.react';
import AutoSizer from 'react-virtualized-auto-sizer';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import FingerprintIcon from '@material-ui/icons/Fingerprint';


import Section from '../section/section';
import CopyAddressButton from '../copy_address_button/copy_address_button';
import RequestFundsModal from '../request_funds_modal/request_funds_modal';

const useStyles = makeStyles((theme)=>{
  return({
    mainContainer:{
      display:"flex",
      flexDirection:"column",
      justifyContent:"center",
      alignContent:"center",
      alignItems:"center",
      padding:"0.5rem"
    },
    qrCode:{
      height:"15rem",
      width:"15rem",
      maxWidth:"800px",
      maxHeight:"800px",
      marginTop:"1rem",
      marginBottom:"1rem"
    },
    modal:{

    },
    buttons:{
      margin:"1rem"
    }
  })
});

const ContractAddressSection = (props={})=>{
  const {
    address,
    title="Smart Contract Address",
    icon=<FingerprintIcon/>,
    wallets=[],
    contracts=[],
    metaMaskAddress=null,
    sendInvoiceRequest=()=>{console.log(`Default Contract Address Section Submit Invoice Request`)}
  } = props;
  const [requestFundsModalOpen, setRequestFundsModalOpen] = useState(false);
  const openRequestFundsModal = ()=>{
    setRequestFundsModalOpen(true);
  }
  const theme = useTheme();
  const classes = useStyles(theme);
  console.log(classes);
  const qrID="contractAddressQRID";
  return(<Section
    title={title}
    icon={icon}
  ><div className={classes.mainContainer}>
    <Typography variant="h5" style={{fontWeight:"600"}}>Send Payments to this Address</Typography>
    <Typography variant="caption" style={{fontWeight:"600"}}>({address})</Typography>
    <div className={classes.qrCode}>
      <AutoSizer >
          {({height, width})=>{
              const size = Math.min(width,height)-4;
              return(<div id={qrID} style={{height, width, display:"inline-block", textAlign:"center"}}><QRCode
                  value={address}
                  size = {size}
                  style={{boxSizing:"content-box", border: "2px solid black"}}
              ></QRCode></div>);
          }}
      </AutoSizer>
    </div>
    <CopyAddressButton
      className={classes.buttons}
      style={{margin:"1rem"}}
      copyContent = {address}
      buttonText={<Typography variant="body2">Copy Address</Typography>}
    />
    <Button
      variant="contained"
      color="primary"
      onClick={()=>{
        openRequestFundsModal();
      }}
      className={classes.buttons}
    >Request Funds</Button>
  </div><RequestFundsModal
    sendTo={address}
    modalOpen={requestFundsModalOpen}
    setModalOpen ={setRequestFundsModalOpen}
    wallets={wallets}
    contracts={contracts}
    metaMaskAddress={metaMaskAddress}
    submit={sendInvoiceRequest}
  /></Section>)
}

export default ContractAddressSection;