import React, {useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';

import Section from '../section/section';
import StyledInput from '../styled_input/styled_input';
import WalletsDropdown from '../wallets_dropdown/wallets_dropdown';

const useStyles = makeStyles((theme)=>{
  return({
    RequestFundsContainer:{
      display:"flex",
      flexDirection:"column",
      alignContent:"center",
      alignItems:"center",
      justifyContent:"flex-start",
      width: "100%",
      minWidth:"25rem"
    },
    RequestFundsInput:{
      width: "calc(100% - 4rem)"
    }
  })
});

const RequestFundsSection = (props)=>{
  
  const {
    submit=(from, amount, to, note)=>{},
    wallets=[],
    contracts=[],
    metaMaskAddress=null
  } = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  const [requestFrom, setRequestFrom] = useState("");
  const [amount, setAmount] = useState("");
  const [sendTo, setSendTo] = useState("");
  const [note, setNote] = useState("");
  const submitRequest = ()=>{
    submit(requestFrom, amount, sendTo, note);
  }
  return(<Section title="Request Funds" icon={<SendIcon/>}>
    <div className={classes.RequestFundsContainer}>
      <StyledInput
        value={requestFrom}
        type="text"
        label="Request From Email"
        updateForm={setRequestFrom}
        className={classes.RequestFundsInput}
      />
      <StyledInput
        value={amount}
        type="number"
        label="Amount to Request"
        minimum={0}
        updateForm={setAmount}
        className={classes.RequestFundsInput}
      />
      <WalletsDropdown
        value={sendTo}
        type="text"
        label="Send To Address"
        updateForm={setSendTo}
        className={classes.RequestFundsInput}
        wallets={wallets}
        contracts={contracts}
        metaMaskAddress={metaMaskAddress}
      />
      <StyledInput
        value={note}
        type="textarea"
        label="Note"
        updateForm={setNote}
        className={classes.RequestFundsInput}
      />
      <Button
        variant="outlined"
        color="primary"
        onClick={submitRequest}
        style={{maxWidth:"6rem"}}
      >Send</Button>
    </div>
  </Section>);
} 

export default RequestFundsSection;
