import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {makeStyles} from '@material-ui/styles';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import Disclosures from '../../components/disclosures/disclosures';

const useStyles = makeStyles(theme =>{
  return({
  navbarDrawer:{
    flexShrink: "0"
  },
  linksContainer:{
    width: '15rem',
    display:"flex",
    flexDirection:"column",
    height:"100%",
    alignItems:"flex-start",
    justifyContent:"flex-start",
  },
  toolbar: {
    minHeight: 56,
    '@media (min-width:0px) and (orientation: landscape)':{
      minHeight: 48,
    },
    '@media (min-width:600px)':{
      minHeight: 64,
    }
  },
  navIcon:{
    paddingRight: "0.5rem",
    minWidth: "1rem"
  }
})});

function NavBar(props)
{
  const {setNavOpen} = props;
  const classes = useStyles();

  const navLinks = Array.isArray(props.links)?props.links:["Default"];

  const handleNavToggle = () =>{
    setNavOpen(!props.open);
  };

  const changePage = typeof(props.changePage)=='function'?props.changePage:function(page){
    console.log(`Default Change Page: ${page}`);
  }

  const handlePageClick = (index) => (event)=> {
    changePage(index);
  }

  const mainLinks = navLinks.filter(l=>{
    return(!l.endOfList);
  }).map((link,index)=>{
    const {
      text="Default Link Text",
      page="/help",
      icon=<ExitToAppIcon></ExitToAppIcon>
    } = link;
    return(<NavItem
      onClick={handlePageClick(page)}
      key={index}
      text={text}
      icon={icon}
    ></NavItem>);
  });
  const endingLinks = navLinks.filter(l=>{
    return(!!l.endOfList);
  }).map((link,index)=>{
    const {
      text="Default Link Text",
      page="/help",
      endOfList=true,
      icon=<ExitToAppIcon></ExitToAppIcon>
    } = link;
    return(<NavItem
      onClick={handlePageClick(page)}
      key={index}
      text={text}
      icon={icon}
    ></NavItem>);
  });
  return(<Drawer
    open={props.open}
    onClose = {handleNavToggle}
    variant="persistent"
    anchor="left"
    className = {classes.navbarDrawer}
  >
  <div className={classes.linksContainer}>
    <div className ={classes.toolbar}></div>
    <List style={{width:"100%"}}>
      {mainLinks}
    </List>
    <div style={{flexGrow:"1"}}></div>
    <List style={{width:"100%"}}>
      {endingLinks}
      <Disclosures style={{width:"auto"}}/>
    </List>
  </div>
  </Drawer>)
}

function NavItem(props)
{
    const classes = useStyles();
    return(
      <ListItem button onClick={props.onClick}>
        <ListItemIcon className={classes.navIcon}>{(typeof(props.icon)!='undefined'?props.icon:<ExitToAppIcon></ExitToAppIcon>)}</ListItemIcon>
        <ListItemText primary={props.text}></ListItemText>
      </ListItem>
    );
}

export default NavBar;
