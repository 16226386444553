import XHR_POST from '../scripts/XHR_POST';
import APIRoutes from './APIRoutes';

const EditContract = async ({
  name,
  primaryEmail,
  management,
  description="",
  erc223=true,
  receivable=true,
  activeOnly=false,
  receivers=[],
  index=null,
},authToken=null) =>{
  return(XHR_POST({
    route: APIRoutes.contracts.edit,
    data:{
      index,
      name,
      management,
      primaryEmail,
      description,
      activeOnly,
      erc223,
      receivable,
      receivers
    },
    authToken,
  }).then(({message,contract})=>{
    alert(message);
    return(contract);
  }));
}

export default EditContract;