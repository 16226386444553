import XHR_POST from '../scripts/XHR_POST';
import APIRoutes from './APIRoutes';

const CreateContract = async ({
  name,
  primaryEmail,
  management,
  description="",
  erc223=true,
  receivable=true,
  activeOnly=false,
  receivers=[]
},authToken=null) =>{
  return(XHR_POST({
    route: APIRoutes.contracts.create,
    data:{
      name,
      management,
      primaryEmail,
      description,
      activeOnly,
      erc223,
      receivable,
      receivers
    },
    authToken,
  }).then((result)=>{
    if(result)
    {
      const {message,contract} = result;
      return(contract);
    }
  }));
}

export default CreateContract;