import CanPassLoginImage from '../../images/canpass-login.png';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme=>{
  console.log(theme.mixins.toolbar);
  return({
    baseContainer:{
      display: "flex",
      flexGrow: "1",
      height: `calc(100%)`,
    },
    ImageContainer:{
      height: "100%",
      width: "100%",
      flexBasis:"50%",
      backgroundImage: `url(${CanPassLoginImage})`,
      backgroundSize:"100% auto",
      backgroundRepeat: "no-repeat",
      backgroundPosition:"center",
      backgroundColor: "#111633"
    },
    FormContainer:{
      height: "100%",
      width: "100%",
      flexBasis:"50%",
      backgroundColor:"mintcream",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
      alignItems:"center",
    },
    FormTitle:{
      fontWeight: "500",
      textTransform:"uppercase",
      fontSize:"2.5rem",
      marginBottom:"0rem",
      marginTop:0,
    },
    FormSubtitle:{
      textAlign:"center",
      fontWeight: "300",
      maxWidth: "20rem"
    },
    FormInput:{
      width: "20rem"
    },
    FormForgotPassword:{
      width: "20rem",
      display: "flex",
      justifyContent:"space-between",
      alignItems:"center",
      textAlign:"right",
    },
    FormForgotPasswordText:{
      display:"inline-block",
      '&:hover':{
        cursor:"pointer",
        color:"blue"
      }
    },
    FormButton:{
      minWidth: "6rem",
      margin: "0.5rem"
    },
    FormDisclosures:{
      width:"20rem",
      display: "flex",
      justifyContent:"center",
      alignItems:"center",
      textAlign:"center",
      '& :hover':{
        cursor:"pointer",
        color:"blue"
      }
    },
    FormDisclosureText:{
      fontSize: "0.5rem",
      margin:"0.5rem",
      textDecoration:"underline"
    }
  });
});

export default useStyles;