import XHR_POST from '../scripts/XHR_POST';
import APIRoutes from './APIRoutes';

const LookupUser = async ({
  cangeaID=null,
  email=null
},authToken=null) =>{
  let query = {};
  if(cangeaID)
  {
    query.cangeaId = cangeaID;
  }
  if(email)
  {
    query.email = email;
  }
  console.log(`Looking up user with query ${JSON.stringify(query)}`);
  return(XHR_POST({
    route: APIRoutes.userLookup,
    data:query,
    authToken,
  }).then(({message, cangeaID, address, info})=>{
    return([{...info, cangeaID, address}]);
  }));
};

export default LookupUser;