import React from 'react';
import {useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useStyles from '../../pages/onboarding/onboarding_style';
import Translator from '../../scripts/Translator';
const translate = Translator({});

const Disclosures =(props)=>{
  const theme = useTheme();
  const classes = useStyles(theme);
  const {
    showTermsAndConditions = ()=>{console.log("Default Base Show Terms")},
    showPrivacyPolicy = ()=>{console.log("Default Base Show Privacy")},
    style={},
  } = props;
  return(<div className={classes.FormDisclosures} style={{...style}}>
    <Typography
      variant="body1"
      component='a'
      style={{fontSize:".75rem"}}
      onClick={()=>{showTermsAndConditions()}}
      className = {classes.FormDisclosureText}
    >{translate("Terms of Use")}</Typography>
    <Typography
      variant="body1"
      component='a'
      style={{fontSize:".75rem"}}
      onClick={()=>{showPrivacyPolicy()}}
      className = {classes.FormDisclosureText}
    >{translate("Privacy Policy")}</Typography>
  </div>)
}

export default Disclosures;