import React, {useEffect, useState} from 'react';

//Material UI
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import {makeStyles, useTheme} from '@material-ui/core/styles';

import StyledInput from '../styled_input/styled_input';

const useStyles = makeStyles((theme)=>{
  return({
    inputsContainer:{
      display: "flex",
      flexDirection:"column",
      justifyContent:"flex-start",
      alignContent:"center",
      alignItems:"center"
    },
    formSubmitContainer:{
      flexShrink:"0",
      textAlign:"center",
      '& > *':{
        margin:"1rem",
      }
    },
    modal:{
      display: 'flex',
      flexDirection: "column",
      justifyContent:"stretch",
      height: "25rem",
      width: "25rem",
      top:"calc(30%)",
      left:"calc(50% - 12.5rem)",
      padding:"1.5rem"
    },
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: "white",
      border: '2px solid #000',
      borderRadius:".5rem",
      outline:"none",
      boxShadow: "none",
      padding: "2rem, 4rem, 3rem",
    },
  });
})

const PasswordChangeModal = (props)=>{
  const theme = useTheme();
  const classes = useStyles(theme);

  const {
    updatePassword = (oldPassword, newPassword)=>{
      console.log(`Default Component Update Password`);
    },
    setModalOpen = (isOpen)=>{console.log(`Default Component Set Password Reset Modal ${isOpen?"Open":"Closed"}`);},
    open = false
  } = props;

  const [currentPasswordInput, setCurrentPasswordInput] = useState("");
  const [newPasswordInput1, setNewPasswordInput1] = useState("");
  const [newPasswordInput2, setNewPasswordInput2] = useState("");

  const submitReset = ()=>{
    if(!currentPasswordInput)
    {
      alert("Invalid Current Password Input");
    }
    else if(!newPasswordInput1)
    {
      alert("Invalid Desired Password Input");
    }
    else if(newPasswordInput1 != newPasswordInput2)
    {
      alert("Desired Password Inputs do not match");
    }
    else
    {
      updatePassword(currentPasswordInput, newPasswordInput1);
    }
  }

  const closeModal=()=>{
    setCurrentPasswordInput("");
    setNewPasswordInput1("");
    setNewPasswordInput2("");
    setModalOpen(false);
  }

  return(<Modal
    open = {open}
    onClose={()=>{
      closeModal();
    }}
  ><div className={[classes.paper, classes.modal].join(' ')}>
    <Typography variant="h5" style={{textAlign:"center"}}>Reset Password</Typography>
    <Typography variant="subtitle1" style={{textAlign:"center"}}>Complete form below to change password</Typography>
    <div className={classes.inputsContainer}>
      <StyledInput
        label="Current Password"
        value = {currentPasswordInput}
        updateForm ={setCurrentPasswordInput}
        type="password"
      />
      <StyledInput
        label="Desired Password"
        value = {newPasswordInput1}
        updateForm ={setNewPasswordInput1}
        type="password"
      />
      <StyledInput
        label="Repeat Desired Password"
        value = {newPasswordInput2}
        updateForm ={setNewPasswordInput2}
        type="password"
      />
    </div>
    <div 
      className={classes.formSubmitContainer}
    >
      <Button 
        variant="contained"
        color="primary"
        onClick={()=>{submitReset();}}
      >Submit Changes</Button>
    </div>
  </div></Modal>)
}

export default PasswordChangeModal;