import XHR_GET from '../scripts/XHR_GET';
import APIRoutes from './APIRoutes';

const GetUserRoles = async (authToken, opts) =>
{
  const roles = await XHR_GET({
    route: APIRoutes.profile.roles,
    authToken,
  }).then(info=>{
    //Do post-processing here
    return(info.roles);
  })
  return(roles);
}
  

export default GetUserRoles;