import XHR_POST from '../scripts/XHR_POST';
import APIRoutes from './APIRoutes';

const UpdatePassword = async (paswordUpdate={}, authToken=null) =>{
  if(typeof(paswordUpdate)!='object')
  {
    throw {
      error: new Error(`Must supply a paswordUpdate object to UpdatePassword`),
      response: "",
      status: 400
    }
  }
  if(!authToken)
  {
    throw {
      error: new Error(`Must supply an Auth Token to UpdatePassword`),
      response: "",
      status: 400,
    };
  }
  else
  {
    return(XHR_POST({
      route: APIRoutes.password.update,
      data:paswordUpdate,
      authToken,
    }).catch(e=>{
      throw {
        error: new Error(`Unable to update password`),
        response: e.response?e.response:"",
        status: e.status?e.status:500
      };
    }).then((result)=>{
      return(result);
    }));
  }
}

export default UpdatePassword;