import React from 'react';
import clsx from 'clsx';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Branding from '../branding/branding';
import LogoImage from '../../images/logo.png';
import Address from '../address/address';


// import Badge from '@material-ui/core/Badge';
// import MenuItem from '@material-ui/core/MenuItem';
// import Menu from '@material-ui/core/Menu';

const useStyles = makeStyles(theme => ({
    ...Branding,
    front: {
        zIndex: 1300
    },
    grow: {
      flexGrow: "0",
    },
    growHeader: {
      flexGrow: "1",
    },
    menuButton: {
      marginRight: theme.spacing(1),
    },
    menuButtonIcon: {
      fontSize:"2rem"
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
      width: "auto",
      
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 200,
      },
    },
    sectionDesktop: {
      display: 'flex',
      alignItems:"center",
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    }
}));

const useLogoStyles = makeStyles({
  LogoContainer:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"flex-start",
    alignItems:"center"
  },
  LogoImage:{
    display: "inline-block",
    height: "40px",
    width: "25px"
  },
  LogoTextContainer:{
    display:"inline-flex",
    flexDirection: "row",
    justifyContent:"center",
    alignItems:"center",
    height: "48px",
    marginLeft: ".25rem"
  },
  LogoTextSubContainer:{
    display:"inline-flex",
    flexDirection: "column",
    justifyContent:"center",
    alignContent: "center",
    alignItems:"flex-end",
    height: "48px"
  },
  LogoTextMain :{
    height: "36px",
    lineHeight:"36px",
    verticalAlign:"bottom",
    marginBottom: "-6px"
  },
  LogoTextSubtitle :{
    height: "12px",
    lineHeight:"12px",
    verticalAlign:"top"
  },
  LogoTextTrademark :{
    display:"inline-block",
    height: "48px",
    fontSize: "0.5rem"
  },
})

const Logo = (props)=>{
  const classes = useLogoStyles();
  return<div className={classes.LogoContainer}>
    <img src={LogoImage} alt="logo" className={classes.LogoImage}/>
    <div className ={classes.LogoTextContainer}>
    <div className ={classes.LogoTextSubContainer}>
        <Typography variant="h5" className={classes.LogoTextMain}>CanPass</Typography>
        <Typography variant="caption" className={classes.LogoTextSubtitle}>by Cangea</Typography>
      </div>
      <div>
        <Typography variant="caption" className={classes.LogoTextTrademark}>TM</Typography>
      </div>
    </div>
  </div>
}

export default function PageHeader(props) {
    const {username="", openNav=()=>{console.log("OpenNav Default");}, openProfile=()=>{console.log(`Open Profile Default`)}} = props;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  
    function handleProfileMenuOpen(event) {
      setAnchorEl(event.currentTarget);
      openProfile();
    }

    return (
    <div style={{display:"inline-block"}} className={[classes.grow, classes.front].join(' ')}>
      <AppBar className={clsx(classes.brandedDark)} position="fixed" style={{zIndex:2000}}>
        <Toolbar>
          {openNav?<IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={openNav}
          >
            <MenuIcon className={classes.menuButtonIcon}/>
          </IconButton>:null}
          <Logo/>
          <div className={classes.growHeader} />
          <div className={classes.sectionDesktop}>
            {!username?null:(<Typography variant="h5">{username}</Typography>)}
            {!username?null:<IconButton
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
