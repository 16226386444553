import React from 'react';
import StatesAndTerritories from '../../common/us_states_territories';
import StyledDropdown from '../styled_dropdown/styled_dropdown';

const formattedStatesAndTerritories = StatesAndTerritories.map(st=>{
  return({
    label: st.name,
    value: st.name
  });
})
const StatesDropdown = (props)=>{
  return(<StyledDropdown
    items={formattedStatesAndTerritories}
    {...props}
  ></StyledDropdown>)
}

export default StatesDropdown;