import React, {useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import QRCode from 'qrcode.react';
import AutoSizer from 'react-virtualized-auto-sizer';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import StyledInput from '../styled_input/styled_input';

const useStyles = makeStyles((theme)=>{
  return({
    qrCode:{
      height:"15rem",
      width:"15rem",
      maxWidth:"800px",
      maxHeight:"800px",
      marginTop:"1rem",
      marginBottom:"1rem"
    },
  });
})

const TwoFactorSetupModal = (props)=>{

  const [responseInput, setResponseInput] = useState("");
  const theme = useTheme();
  const classes = useStyles(theme);
  
  const {setIsOpen=(val)=>{
    console.log(`Default Two Factor Challenge Modal setIsOpen: ${val}`);
  }} = props;
  const {
    isOpen=false,
    submitResponse=(val)=>{
      console.log(`Default Two Factor Challenge Modal submitResponse: ${val}`);
    },
    setupCode="",
    handleClose = ()=>{
      setIsOpen(false);
    }
  } = props;

  const handleSubmit = ()=>{
    submitResponse(responseInput);
  }
  const qrID="twoFactorSetupQRID";
  return(<Dialog open={isOpen} onClose={handleClose} >
    <DialogTitle>Two Factor Challenge</DialogTitle>
    <DialogContent>
      <DialogContentText>Scan the code below with your Two Factor Authentication App and enter the response code to proceed.</DialogContentText>
      <div style={{display:"flex",flexDirection:"column", justifyContent: "flex-start", alignContent: "center", alignItems:"center", flexWrap:false}}>
        <div className={classes.qrCode}>
          <AutoSizer >
            {({height, width})=>{
                const size = Math.min(width,height)-4;
                return(<div id={qrID} style={{height, width, display:"inline-block", textAlign:"center"}}><QRCode
                    value={setupCode}
                    size = {size}
                    style={{boxSizing:"content-box", border: "2px solid black"}}
                ></QRCode></div>);
            }}
          </AutoSizer>
        </div>
        <StyledInput
          value={responseInput}
          updateForm={setResponseInput}
          fullScreen={true}
          style={{flexBasis:"100%", flexShrink:0, flexGrow:1}}
        ></StyledInput>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >Submit</Button>
      </div>
    </DialogContent>
  </Dialog>)
}

export default TwoFactorSetupModal;