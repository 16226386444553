import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import BigNumber from 'bignumber.js';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Description' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'totalReceived', numeric: true, disablePadding: false, label: 'Total Received E(-18)' },
  { id: 'deployedAdddress', numeric: false, disablePadding: false, label: 'Contract Address' },
  { id: 'numReceivers', numeric: true, disablePadding: false, label: '# Rx' },
  { id: 'numTransactions', numeric: true, disablePadding: false, label: '# Tx' },
  { id: 'dateCreated', numeric: true, disablePadding: false, label: 'Date Created' },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort} = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: "1rem",//theme.spacing(1),
    paddingRight: "1rem",//theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  tableHead:{
    backgroundColor: "#021226",
    color:"white",
    '& svg':{
      color:"white"
    }
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { title } = props;

  return (
    <Toolbar
      className={clsx(classes.root, classes.tableHead)}
    >
      <Typography className={classes.title} variant="h6" id="tableTitle">
        {title}
      </Typography>
      {/* <Tooltip title="Filter list">
        <IconButton aria-label="filter list">
          <FilterListIcon />
        </IconButton>
      </Tooltip> */}
    </Toolbar>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    //minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  viewMoreRow:{
    height: 33,
    textDecoration:"underline",
    '& :hover':{
      color:"blue",
      cursor:"pointer",
      textDecoration:"blue underline",
    }
  }
}));

const handleClickDefault = (event, contractIndex) => {
  console.log(`Clicked Contract ${contractIndex}`)
};

export default function ContractsOverviewTable(props) {
  const {
    contracts=[],
    getMore=()=>{console.log(`Default Get More`);},
    title="",
    handleClick = handleClickDefault
  } = props;
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('date');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const reduceAddress = (addr)=>{
    return(`${addr.slice(0,5)}...${addr.slice(-5)}`);
  }

  const tableRows = stableSort(contracts.map(contractBase=>{
    let contract = Object.assign({},contractBase);
    let status = 'draft';
    if(contract.generated)
    {
      status = "generated";
    }
    if(contract.deployed)
    {
      status = "deployed";
    }
    if(contract.frozen)
    {
      status = "frozen";
    }
    if(contract.archived)
    {
      status="archived";
    }
    contract.status = status;
    contract.totalReceivedHumanReadable = new BigNumber(contract.totalReceived).div(1e18).toFixed(4);
    contract.numReceivers = contract.receivers.length;
    return(contract);
  }), getComparator(order, orderBy))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((contract, index) => {
      
      return (
        <TableRow
          //hover
          onClick={event => handleClick(event, contract.index)}
          tabIndex={-1}
          key={contract.index}
        >
          <TableCell component="th" scope="row" style={{wordBreak:"break-word", maxWidth:"20rem"}}>
            {contract.name}
          </TableCell>
          <TableCell align="left" style={{textTransform:"capitalize"}}>{contract.status}</TableCell>
          <TableCell align="right">{(typeof(contract.totalReceivedHumanReadable)=='string'&& contract.totalReceivedHumanReadable!="NaN")?`${contract.totalReceivedHumanReadable} CNG`:"N/A"}</TableCell>
          <TableCell align="left">{contract.deployed?reduceAddress(contract.deployedAddress):"Draft"}</TableCell>
          <TableCell align="right">{contract.numReceivers}</TableCell>
          <TableCell align="right">{contract.numTransactions}</TableCell>
          <TableCell align="right">{new Date(contract.dateCreated).toLocaleDateString()}</TableCell>
        </TableRow>
      );
    });

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, contracts.length - page * rowsPerPage) - (typeof(getMore)=='function'?1:0);

  return (
    <div className={classes.root}>
      {/* <Paper className={classes.paper}> */}
        <EnhancedTableToolbar title={title}/>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size='small'
            aria-label="contracts table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={contracts.length}
            />
            <TableBody>
              {tableRows}
              {typeof(getMore)==='function'?(
              <TableRow 
                className={classes.viewMoreRow}
              >
                <TableCell 
                  colSpan={7}
                  onClick={getMore}
                  align="center"
                >View More</TableCell>
              </TableRow>):null}
              {emptyRows > 0 ? (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              ):null}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={contracts.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      {/* </Paper> */}
    </div>
  );
}