import React, {useState, useEffect, useRef} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';

import Section from '../section/section';
import LookupDropdown from '../lookup_dropdown/lookup_dropdown';
import StyledInput from '../styled_input/styled_input';
import StyledCheckbox from '../styled_checkbox/styled_checkbox';
import WalletsInputDropdown from '../wallets_dropdown/wallets_dropdown';
import StyledDropdown from '../styled_dropdown/styled_dropdown';
import StatesDropdown from '../states_dropdown/states_dropdown';
import Translator from '../../scripts/Translator';
const translate = Translator({});

const useStyles = makeStyles((theme)=>{
  return({
    EditReceiverContainer:{
      display:"flex",
      flexDirection:"row",
      flexWrap:"wrap",
      alignContent:"flex-start",
      alignItems:"flex-start",
      justifyContent:"space-between",
      flexBasis:"100%"
    },
    EditReceiverInput:{
      flexGrow:"1",
      flexShrink:"0"
    },
    EditAddressContainer:{
      flexGrow:"1",
      flexShrink:"0",
      display:"inline-flex",
      flexDirection: "column",
      justifyContent:"flex-start",
      alignContent:"stretch",
      alignItems: "center",
      boxSizing:"border-box",
      marginTop:"0.5rem",
      marginBottom:"0.5rem"
    },
    RecipientInputContainer:{
      display:"flex",
      flexDirection:"row",
      flexBasis:"100%",
    },
    RecipientInputContainerLeft:{
      display:"inline-flex",
      flexDirection:"row",
      flexWrap:"wrap",
      paddingRight:".25rem",
      flexBasis:"75%",
      flexGrow:"1"
    },
    RecipientInputContainerRight:{
      minWidth:"5rem",
      display:"inline-block",
      flexBasis:"15rem",
      flexGrow:"1",
      '& > * > div':{
        margin:0
      }
    },
  })
});

const EditContractReceiverSection = (props)=>{
  const {
    startingCangeaID="",
    startingAddress="",
    startingEmail="",
    startingSendRequest = false,
    startingPercent = 0,
    startingFirstName = "",
    startingLastName = "",
    startingCompany = "",
    startingPhone = "",
    startingStreet = "",
    startingCity = "",
    startingState = "",
    startingCountry = "United States of America",
    startingZip = "",
    updatePage=(updateInfo)=>{console.log(`Updating Page`);console.log(updateInfo);},
    deleteReceiver = ()=>{console.log(`Default Delete Receiver`);},
    wallets=[],
    contracts=[],
    metaMaskAddress=null,
    lookupUser = async(query)=>{
      return([]);
    }
  } = props;

  
  const theme = useTheme();
  const classes = useStyles(theme);
  const isRendered = useRef(false);
  useEffect(()=>{
    isRendered.current=true;
    return(()=>{
      isRendered.current=false;
    });
  })
  const isLookingUp = useRef(false);
  const triggerUserLookup = async(newCangeaID)=>{
    if(!isLookingUp.current)
    {
      isLookingUp.current = true;
      const lookupResults = await lookupUser({cangeaID:newCangeaID}).catch(e=>{
        console.error(e);
        return([]);
      });
      console.log(`User Lookup Results:\n${JSON.stringify(lookupResults)}`)
      isLookingUp.current = false;
      if(isRendered.current)
      {
        setUserLookupResults(lookupResults);
      }
    }
  };

  const [cangeaID, setCangeaID] = useState(startingCangeaID);
  const [userLookupResults, setUserLookupResults] = useState([]);
  const [address, setAddress] = useState(startingAddress);
  const [email, setEmail] = useState(startingEmail);
  const [sendRequest, setSendRequest] = useState(startingSendRequest);
  const [percent, setPercent] = useState(startingPercent);
  const [first, setFirst] = useState(startingFirstName);
  const [last, setLast] = useState(startingLastName);
  const [company, setCompany] = useState(startingCompany);
  const [phone, setPhone] = useState(startingPhone);
  const [street, setStreet] = useState(startingStreet);
  const [city, setCity] = useState(startingCity);
  const [state, setState] = useState(startingState);
  const [country, setCountry] = useState(startingCountry);
  const [zip, setZip] = useState(startingZip);
 
  
  const sendPageUpdate =()=>{
    updatePage({
      cangeaID,
      address,
      email,
      sendRequest,
      percent,
      first,
      last,
      company,
      phone,
      street,
      city,
      state,
      country,
      zip
    });
  }
  useEffect(sendPageUpdate, [
    cangeaID,
    address,
    email,
    sendRequest,
    percent,
    first,
    last,
    company,
    phone,
    street,
    city,
    state,
    country,
    zip
  ]);

  let className = props.className?props.className:"";

  const LeftSection = ()=>{
    return(<div className = {classes.RecipientInputContainerLeft}>
    <div className={classes.EditReceiverContainer}>
      <LookupDropdown
        value={cangeaID}
        type="text"
        label="Cangea ID"
        updateForm={(newVal)=>{
          setCangeaID(newVal);
        }}
        className = {classes.EditReceiverInput}
        dropdowns = {userLookupResults.filter((val, index)=>{
          return(true);
        }).map((val)=>{
          return({
            text: `${val.contact.last}, ${val.contact.first}`,
            value: val.cangeaID
          })
        })}
        triggerLookup ={triggerUserLookup}
        onDropdownSelect = {(selectedValue)=>{
          let entry=null;
          for(let i=0,limit=userLookupResults.length; i<limit; i++)
          {
            if(selectedValue===userLookupResults[i].cangeaID)
            {
              entry = userLookupResults[i];
              break;
            }
          }
          if(entry)
          {
            entry.address==="None"?setAddress(""):setAddress(entry.address);
            setCompany(entry.company);
            setStreet(entry.street);
            setCity(entry.city);
            setState(entry.state);
            setCountry(entry.country);
            setZip(entry.zip);
            setPhone(entry.phone);
            setFirst(entry.contact.first);
            setLast(entry.contact.last);
            setEmail(entry.contact.email);
            setCangeaID(selectedValue);
            setSendRequest(false);
          }
          else
          {
            console.error(`Did not find selected value in dropdowns (${selectedValue})`);
          }
        }}
      />
      <div className = {classes.EditAddressContainer}>
        <WalletsInputDropdown
          value={address}
          type="text"
          label="Recipient Wallet Address"
          updateForm={(newVal)=>{
            setAddress(newVal);
          }}
          style={{flexBasis:"100%", width:"calc(100% - 1rem)", margin:0}}
          className = {classes.EditReceiverInput}
          contracts={contracts}
          wallets={wallets}
          metaMaskWallet={metaMaskAddress}
        />
        <StyledCheckbox 
          checked = {sendRequest}
          label="Send Wallet Request"
          updateForm={(newVal)=>{
            setSendRequest(newVal);
          }}
          style={{alignSelf:"flex-start", marginLeft:"0.5rem"}}
        />
      </div>
    </div>
    <div className={classes.EditReceiverContainer}>
      <StyledInput
        value={first}
        type="text"
        label="First Name"
        updateForm={(newVal)=>{
          setFirst(newVal);
        }}
        className = {classes.EditReceiverInput}
      />
      <StyledInput
        value={last}
        type="text"
        label="Last Name"
        updateForm={(newVal)=>{
          setLast(newVal);
        }}
        className = {classes.EditReceiverInput}
      />
    </div>
    <div className={classes.EditReceiverContainer}>
      <StyledInput
        value={email}
        type="text"
        label="Email Address"
        updateForm={(newVal)=>{
          setEmail(newVal);
        }}
        className = {classes.EditReceiverInput}
      />
      <StyledInput
        value={phone}
        type="text"
        label="Phone Number"
        updateForm={(newVal)=>{
          setPhone(newVal);
        }}
        className = {classes.EditReceiverInput}
      />
    </div>
    <div className={classes.EditReceiverContainer}>
      <StyledInput
        value={company}
        type="text"
        label="Company"
        updateForm={(newVal)=>{
          setCompany(newVal);
        }}
        className = {classes.EditReceiverInput}
      />
      <StyledInput
        value={street}
        type="text"
        label="Street Address"
        updateForm={(newVal)=>{
          setStreet(newVal);
        }}
        className = {classes.EditReceiverInput}
      />
    </div>
    <div className={classes.EditReceiverContainer}>
      <StyledInput
        value={city}
        type="text"
        label="City"
        updateForm={(newVal)=>{
          setCity(newVal);
        }}
        className = {classes.EditReceiverInput}
      />
      <StatesDropdown
        value={state}
        label="State / Territory"
        updateForm={(newVal)=>{
          setState(newVal);
        }}
        className = {classes.EditReceiverInput}
      />
    </div>
    <div className={classes.EditReceiverContainer}>
      <StyledInput
        value={zip}
        type="text"
        label="ZIP Code"
        updateForm={(newVal)=>{
          setZip(newVal);
        }}
        className = {classes.EditReceiverInput}
      />
      <StyledDropdown
        value={country}
        label="Country"
        updateForm={(newVal)=>{
          setCountry(newVal);
        }}
        className = {classes.EditReceiverInput}
        items={[
          {
            label:"United States of America",
            value: "United States of America"
          }
        ]}
      />
    </div>
  </div>);
  }

  const RightSection = ()=>{
    return(<div className = {classes.RecipientInputContainerRight}>
      <div className={classes.EditReceiverContainer}>
        <StyledInput
          type="number"
          label="Percent Payment"
          value={percent}
          updateForm={(newVal)=>{
            setPercent(newVal);
          }}
          className = {classes.EditReceiverInput}
          endAdornment= {<a className={classes.percentSign}>%</a>}
        ></StyledInput>
      </div>
      <div style={{textAlign:"center"}}>
        <Button
          variant='contained'
          color='primary'
          onClick={deleteReceiver}
        ><DeleteIcon/>Delete Recipient</Button>
      </div>
    </div>);
  };

  return(<Section 
    title = "Receiver Information"
    icon = {<LibraryBooksIcon/>}
    className = {className}
  >
  <div className = {classes.RecipientInputContainer}>
    {LeftSection()}
    {RightSection()}
  </div>
  </Section>)
};

export default EditContractReceiverSection;