import React from 'react';

import {string} from 'prop-types';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Translator from '../../scripts/Translator';


const translate = Translator({});

const useStyles = makeStyles((theme)=>{
  return({
    videoLink:{
      display:"inline-flex",
      alignItems:"center",
      color:"black",
      '&:hover':{
        cursor:"pointer",
        color: "blue"
      },
      textDecoration:"none",
      marginTop: ".5rem",
      '& svg':{
        marginRight:".25rem"
      }
    }
  })
});

function VideoLink({link, label="Watch Instructional Video"}) {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <a
      className={classes.videoLink}
      href={link}
      rel="noopener noreferrer"
      target="_blank"
      title={label}
    >
      <HelpOutlineIcon />
      <div>{translate(label)}</div>
    </a>
  );
}

VideoLink.propTypes = {
  link: string.isRequired
};

export default VideoLink;
