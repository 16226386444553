import XHR_POST from '../scripts/XHR_POST';
import APIRoutes from './APIRoutes';

const GetTwoFactorAuthStatus = async (authToken=null) =>{
  if(!authToken)
  {
    throw {
      error: new Error(`Must supply an Auth Token to RemoveTwoFactorAuth`),
      response: "",
      status: 400,
    };
  }
  else
  {
    return(XHR_POST({
      route: APIRoutes.mfa.status,
      authToken,
    }).then((result)=>{
      const {message, status, dateUpdated} = result;
      return({message, status, dateUpdated});
    }));
  }
}

export default GetTwoFactorAuthStatus;