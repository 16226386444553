import React, {useEffect, useState, useRef} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import StyledInput from '../styled_input/styled_input';

const useStyles = makeStyles((theme)=>{
  return({

  });
});

const LookupDropdown = (props)=>{
  const {
    lookupDelaySeconds = 0.5,
    minChars=4,
    triggerLookup = async()=>{return true},
    updateForm =(newVal)=>{},
    onDropdownSelect = (selectedValue)=>{},
    dropdowns=[], //[{label, value}]
    value="",
  } = props;
  console.log(dropdowns);
  const theme = useTheme();
  const classes = useStyles(theme);

  const lastLookupTime = useRef(0);
  const isLookingUp = useRef(false);
  const isRendered = useRef(false);
  const delayTimer = useRef(null);
  const currentVal = useRef(value);
  useEffect(()=>{
    isRendered.current=true;
    return(()=>{
      isRendered.current = false;
    })
  })
  const shouldLookUp = ()=>{
    return(
      value.length > minChars && 
      ! isLookingUp.current && 
      Date.now() - lookupDelaySeconds*1000 > lastLookupTime.current && 
      isRendered.current
    );
  };

  const performLookup = ()=>{
    isLookingUp.current=true;
    console.log(`Performing Lookup ${currentVal.current}`);
    triggerLookup(currentVal.current).catch(e=>{
      console.error(e);
      return(false);
    }).then(()=>{
      isLookingUp.current=false;
      lastLookupTime.current = Date.now();
    });
  }

  const performDelayedLookup = ()=>()=>{
    delayTimer.current = null;
    if(shouldLookUp())
    {
      console.log(`Performing Delayed Lookup: ${currentVal.current}`);
      performLookup(currentVal.current);
    }
    else
    {
      if(!delayTimer.current)
      {
        delayTimer.current = setTimeout(
          performDelayedLookup(),
          lookupDelaySeconds*1000
        );
      }
    }
  }

  const handleDropdownUpdate = (newVal) =>
  {
    currentVal.current = newVal;
    if(shouldLookUp())
    {
      performLookup();
    }
    else 
    {
      if(delayTimer.current)
      {
        clearTimeout(delayTimer.current);
      }
      console.log(`Delaying Lookup: ${newVal}`);
      delayTimer.current = setTimeout(
        performDelayedLookup(),
        lookupDelaySeconds*1000
      );
    }
    updateForm(newVal);
  }

  return(<StyledInput
    dropdowns = {dropdowns}
    {...props}
    updateForm = {handleDropdownUpdate}
    onDropdownSelect={onDropdownSelect}
    
  />)
};

export default LookupDropdown;