import React, {useState, useRef} from 'react';
import Typography from '@material-ui/core/Typography';

import FileCopyIcon from '@material-ui/icons/FileCopy';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {makeStyles} from '@material-ui/core/styles';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
// import QRModal from '../qr_modal/qr_modal';

const useStyles = makeStyles((theme)=>{
    return({
        AddressCopyTextArea: {
            position: 'absolute',
            right: "-.75rem",
            bottom:"-.5rem",
            zIndex:"-10000",
            flexBasis: '0',
            height:"0",
            width: "0",
            resize:"none",
            boxSizing:"border-box",
            opacity: "0"
        },
        AddressContainer:{
            display:'block', whiteSpace: 'wrap', flexDirection: 'row', width:"100%", textAlign:"center"
        },
        AddressDisplayText:{
            display:"inline-block",
            marginRight:".5rem",
            wordBreak:"break-all"
        },
        AddressIcon:{
            boxSizing:"border-box",
            fontSize: "1.25rem",
            verticalAlign:"top",
            padding:"0",
            margin: "auto 0.125rem"
        },
        AddressCopyIcon:{
            '&:hover':{
                color: 'darkcyan',
                cursor: "pointer"
            }
        },
        AddressLinkIcon:{
            '&:hover':{
                color: 'royalblue',
                cursor: "pointer"
            }
        },
        AddressQRIcon:{
            '&:hover':{
                color: 'royalblue',
                cursor: "pointer"
            }
        }
    });
});

function Address(props)
{
    const {address, clickLink=null, variant='caption'} = props;
    const [qrOpen, setQROpen] = useState(false);
    const hiddenText = useRef(null);
    const copyText = ()=>{
        hiddenText.current.select();
        document.execCommand('copy');
        console.log(`Copied address to clipboard`);
    }
    const classes = useStyles();
    const className = [classes.AddressContainer, props.className].join(' ');
    return(<div className={className} style={props.centered?{textAlign:"center"}:{textAlign:"left"}}>
        <Typography variant={'body1'} className={classes.AddressDisplayText} >{(props.children&&props.children.length>0)?props.children:address}
            <FileCopyIcon onClick={copyText} className={[classes.AddressIcon, classes.AddressCopyIcon].join(' ')}></FileCopyIcon>
        
        <textarea readOnly ref={hiddenText} className={classes.AddressCopyTextArea}
            value = {address}
        ></textarea>
        <ImageSearchIcon
            onClick={()=>{
                setQROpen(true);
            }}
            className={[classes.AddressIcon, classes.AddressQRIcon].join(' ')}
        ></ImageSearchIcon>
        {
            clickLink?<ExitToAppIcon onClick={clickLink} className={[classes.AddressIcon, classes.AddressLinkIcon].join(' ')}></ExitToAppIcon>:null
        }
        </ Typography>
        {/* {qrOpen?<QRModal
            title={"Scannable Address QR"}
            subtitle={<div style={{textAlign:"center"}}><Typography variant={'body1'} className={classes.AddressDisplayText} >{(props.children&&props.children.length>0)?props.children:address}
                <FileCopyIcon onClick={copyText} className={[classes.AddressIcon, classes.AddressCopyIcon].join(' ')}></FileCopyIcon>
            </ Typography>
            </div>}
            value={`{"address":"${address}"}`}
            open={qrOpen}
            setModalOpen={setQROpen}
        ></QRModal>:null} */}
    </div>)
}

export default Address;
