import XHR_GET from '../scripts/XHR_GET';


const PostsUrl = 'https://www.cangea.com/wp-json/wp/v2/posts';

const GetWordPressPosts = async (max=4, query={},opts={}) =>{
  const {
    tags= [19],
    order= 'desc',
    orderby= 'date'
  }= query;
  
  let posts = await XHR_GET({
    route: PostsUrl,
    queries:{
      tags,
      order,
      orderby,
      ...query
    },
    setCSRF:false
  }).catch(e=>{
    console.error(e);
    console.error(e.response);
    throw new Error("Cannot Get WordPress Links");
  }).then(async (allPosts)=>{
    //Do post-processing here
    const posts = allPosts.slice(0,max);
    for(let i=0,limit =posts.length; i<limit; i++)
    {
      console.log( posts[i]);
      let mediaInfo = posts[i]._links['wp:attachment'][0].href;
      const attachmentInfo = await XHR_GET({
        route: mediaInfo,
        setCSRF:false
      }).catch(e=>{
        console.error(e);
        console.error(e.response);
        return({});
        //throw new Error(`Cannot Get WordPress Link Info: ${attachmentUrl}`);
      });
      console.log(attachmentInfo);
      //attachmentInfo.length-1
      posts[i].image_url = attachmentInfo.length>0?attachmentInfo[0].source_url:null;
    }
    return(posts.map(info=>{
      const {
        id,
        link,
        featured_media, // use to display images
        title,
        image_url
      } = info;
      console.log(info);
      return({
        id,
        link_url:link,
        mediaId: featured_media,
        title: title.rendered,
        image_url
      });
    }));
  });
  return(posts);
}

export default GetWordPressPosts;