import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import Typography from '@material-ui/core/Typography';

import Section from '../section/section';
import Translator from '../../scripts/Translator';
const translate = Translator({});

const useStyles = makeStyles((theme)=>{
  return({
    metaDetails:{
      display:"flex",
      flexDirection:"column",
      justifyContent:"flex-start",
      alignContent:"flex-start"
    },
    reviewMetaContainer:{
      display:"flex",
      flexDirection:"column",
      justifyContent:"flex-start",
      alignContent:"flex-start"
    },
    contractTitle:{
      display:"inline-block",
      wordWrap:"break-word",
      flexBasis:"100%",
      marginTop:".5rem",
      marginBottom:".5rem",
      paddingLeft:"0.5rem",
    },

  });
});

const ReviewContractMetaSection = ({
  name="", primaryEmail="", management="", description="",
  receivable=null,
  erc223=null,
  activeOnly=null,
  acceptedTokens = [{
      name: "Ethereum",
      symbol: "ETH",
      address: "0x0000000000000000000000000000000000000000",
      accept: true
    },
    {
      name: "ERC223",
      symbol: "CNG, etc.",
      address: "0x0000000000000000000000000000000000000001",
      accept: true
    },
    // {
    //   name: "Cangea",
    //   symbol: "CNG",
    //   address: "0xe08e982F04c9919E7900DbA37Cc757F7751F7919",
    //   accept: true
    // }
  ], 
  accessControlLists=[{
    name: "Verified Wallets",
    address: "0xeaE61B845e2a08544F22AE65f94f52DAF7Ee9CDA",
    enforce: true
  }],
  className=""
})=>{
  const theme = useTheme();
  const classes = useStyles(theme);

  const MetaDetails = ({title, caption})=>{
    return(<div className={classes.metaDetails}>
      <Typography variant="caption" >{title}</Typography>
      <Typography variant="body1" >{caption}</Typography>
    </div>)
  }

  const PaymentSenderRestrictions = ()=>{
    let isVerifiedOnly = false;
    if(typeof(activeOnly)=='boolean')
    {
      isVerifiedOnly=activeOnly;
    }
    else
    {
      for(let i=0,limit=accessControlLists.length; i<limit; i++)
      {
        if(accessControlLists[i].name== "Verified Wallets" && accessControlLists[i].enforce)
        {
          isVerifiedOnly = true;
        }
      }
    }
    
    let textContent = isVerifiedOnly?`Verified Accounts Only`:`Any Account`;
    return(<MetaDetails title="Payment Sender Restrictions" caption={textContent}/>);
  };

  const PaymentTokenRestrictions = ()=>{
    let isERC223 = false;
    let isPayable = false;
    if(typeof(receivable)=='boolean')
    {
      isPayable=receivable;
    }
    else 
    {
      for(let i=0,limit=acceptedTokens.length; i<limit; i++)
      {
        if(acceptedTokens[i].name=="Ethereum" && acceptedTokens[i].accept)
        {
          isPayable = true;
          break;
        }
      }
    }

    if(typeof(erc223)=='boolean')
    {
      isERC223 = erc223;
    }
    else 
    {
      for(let i=0,limit=acceptedTokens.length; i<limit; i++)
      {
        if(acceptedTokens[i].name=="ERC223"  && acceptedTokens[i].accept)
        {
          isERC223=true;
          break;
        }
      }
    }
    
    let textContent = isERC223?`Can Receive Any ERC223 Token${isPayable?`and ETH`:``}`:isPayable?`Can Only Receive CNG and ETH`:`Can Only Receive CNG`;
    return(<MetaDetails title="Payment Token Restrictions" caption={textContent}/>);
  }
  return(<Section 
    title = "Contract Information"
    icon = {<LibraryBooksIcon/>}
    className = {className}
  >
    <div className={classes.EditMetaContainer}>
      <Typography variant="h5" className={classes.contractTitle}>{name}</Typography>
      <MetaDetails title="Contract Name" caption={name}/>
      <MetaDetails title="Contract Description" caption={description}/>
      <MetaDetails title="Management Wallet Address" caption={management}/>
      <MetaDetails title="Notification Email Addres" caption={primaryEmail}/>
      {PaymentSenderRestrictions()}
      {PaymentTokenRestrictions()}
    </div>
  </Section>)
};

export default ReviewContractMetaSection;