import React, {useState, useRef, useEffect} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Typography from '@material-ui/core/Typography';


import Section from '../section/section';
import TimeoutTextButton from '../timeout_text_button/timeout_text_button';

const useStyles = makeStyles((theme)=>{
  return({
    buttonsContainer:{
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    }
  });
});

const ContractManagementSection = ({
  freezeContract = async({progressFunction})=>{console.log(`Default Manage Contract: Freeze`);},
  thawContract = async({progressFunction})=>{console.log(`Default Manage Contract: Thaw`);},
  archiveContract = async({progressFunction})=>{console.log(`Default Manage Contract: Archive`);},
  contractFrozen = false,
  contractArchived = false,
  style={},
  className=""
}) =>{
  const theme = useTheme();
  const classes = useStyles(theme);

  const freezeButtonDefaultText = "Freeze Contract";
  const [freezeButtonText, setFreezeButtonText] = useState(freezeButtonDefaultText);
  const thawButtonDefaultText = "Thaw Contract";
  const [thawButtonText, setThawButtonText] = useState(thawButtonDefaultText);
  const archiveButtonDefaultText = "Archive Contract";
  const [archiveButtonText, setArchiveButtonText] = useState(archiveButtonDefaultText);

  const createProgressFunction = (setButtonText)=>(stage)=>{
    if(stage < 0)
    {
      setButtonText("Error!");
    }
    else 
    {
      switch(stage)
      {
        case 1:
        {
          setButtonText("Checking...") ;
          break;
        }
        case 3:
        {
          setButtonText("Signing...") ;
          break;
        }
        case 4:
        {
          setButtonText("Sending...") ;
          break;
        }
        case 5:
        {
          setButtonText("Sent TX!") ;
          break;
        }
      }
    }
    return;
  };

  const handleFreezeButtonPress = ()=>{
    freezeContract({
      progressFunction: createProgressFunction(setFreezeButtonText)
    }).catch(e=>{
      alert(e.toString());
      return(null);
    }).then(result=>{
      if(result)
      {
        alert(`Sent Freeze Contract Transaction!\nHash: ${result.transactionHash}`);
        setFreezeButtonText("Transaction Mined!");
      }
    });
  };
  const handleThawButtonPress = ()=>{
    thawContract({
      progressFunction: createProgressFunction(setThawButtonText)
    }).catch(e=>{
      alert(e.toString());
      return(null);
    }).then(result=>{
      if(result)
      {
        alert(`Sent Thaw Contract Transaction!\nHash: ${result.transactionHash}`);
        setThawButtonText("Transaction Mined!");
      }
    });
  };
  const handleArchiveButtonPress = ()=>{
    archiveContract({
      progressFunction: createProgressFunction(setArchiveButtonText)
    }).catch(e=>{
      alert(e.toString());
      return(null);
    }).then(result=>{
      if(result)
      {
        alert(`Sent Archive Contract Transaction!\nHash: ${result.transactionHash}`);
        setArchiveButtonText("Transaction Mined!");
      }
    });
  };

  return(<Section
    title={"Manage Contract"}
    icon={<AccountBalanceIcon/>}
    style={style}
    className={[className].join(' ')}
  >
    <div className={classes.buttonsContainer}>
      {contractArchived?<Typography variant="body2">Archived</Typography>:null}
      {contractArchived?null:!contractFrozen?
        <TimeoutTextButton
          defaultText={freezeButtonDefaultText}
          buttonText= {freezeButtonText}
          setButtonText={setFreezeButtonText}
          timeoutSeconds={30}
          onClick={handleFreezeButtonPress}
        />:
        <TimeoutTextButton
          defaultText={thawButtonDefaultText}
          buttonText= {thawButtonText}
          setButtonText={setThawButtonText}
          timeoutSeconds={30}
          onClick={handleThawButtonPress}
        />
      }
      {contractArchived?null:
        <TimeoutTextButton
          defaultText={archiveButtonDefaultText}
          buttonText= {archiveButtonText}
          setButtonText={setArchiveButtonText}
          timeoutSeconds={30}
          onClick={handleArchiveButtonPress}
        />
      }
    </div>
  </Section>)
  
}

export default ContractManagementSection;