import React, {useState, useEffect} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import FaceIcon from '@material-ui/icons/Face';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import SecurityIcon from '@material-ui/icons/Security';

import Section from '../../components/section/section';
import PageTitle from '../../components/page_title/page_title';

import StyledInput from '../../components/styled_input/styled_input';
import PasswordChangeModal from '../../components/password_change_modal/password_change_modal';
import TwoFactorSetupModal from '../../components/two_factor_setup_modal/two_factor_setup_modal';
import ProfilePersonalSection from '../../components/profile_personal_section/profile_personal_section';
import ProfileBusinessSection from '../../components/profile_business_section/profile_business_section';

const useStyles = makeStyles((theme)=>{
  return({
    main:{
      display:"flex",
      flexDirection:"column",
      flexWrap:"wrap",
      justifyContent:"flex-start",
      alignContent:"flex-start",
      alignItems:"stretch",
      height: "100%",
      width: "100%",
      padding:"1rem",
      paddingBottom:"5rem"
    },
    formContainer:{
      width:"100%",
      display:"flex",
      flexDirection:"row",
      flexWrap:"wrap",
      justifyContent:"center",
      alignItems:"center"
    },
    formSubmitContainer:{
      flexBasis:"100%",
      textAlign:"center",
      marginBottom:"1rem"
    },
    formInput:{
      flexBasis:"48%",
      flexGrow:"0"
    }
  });
});

const ProfilePage = ({
  username="default_user",
  email="default@cangea.io",
  personal={"first":"John","last":"Doe","email":"doej@cangea.io","discoverable":true,"displayName":"dispensemo"},
  business={"company":"DispenseMO Cannabis LLC","street":"4240 Exeton Street","city":"St. Louis","state":"Missouri","zip":"63139","country":"United States of America","tax":"MO837294","business":"MO837294"},
  setupTwoFactor=()=>{
    console.log(`Default Page Two Factor Setup`);
  },
  completeTwoFactorSetup=(response)=>{
    console.log(`Default Page Complete Two Factor Setup: ${response}`);
  },
  removeTwoFactorStart=(response)=>{
    console.log(`Default Page Two Factor Removal Start: ${response}`);
  },
  confirmRemoveTwoFactor=(response)=>{
    console.log(`Default Page Confirm Two Factor Removal: ${response}`);
  },
  twoFactorEnabled = false,
  twoFactorSetupCode = null,
  updateBusinessProfile = (updatedProfile, progressUpdateFn)=>{console.log(`Default Profile Page Update Business Profile: ${JSON.stringify(updatedProfile)}`);},
  updatePersonalProfile = (updatedProfile, progressUpdateFn)=>{console.log(`Default Profile Page Update Personal Profile: ${JSON.stringify(updatedProfile)}`);},
  updatePassword = async ({password, newPassword}, progressUpdateFn)=>{console.log(`Default Profile Page Update  Password`);}
}) =>{
  const theme = useTheme();
  const classes = useStyles(theme);

  const [twoFactorSetupOpen, setTwoFactorSetupOpen] = useState(twoFactorSetupCode?true:false);
  const [passwordChangeModalOpen, setPasswordChangeModalOpen] = useState(false);

  const submitPasswordChanges = (oldPassword, newPassword)=>{
    updatePassword({
      password: oldPassword,
      newPassword
    }, ()=>{
      console.log(`Password Update Progress fn`);
    }).then(()=>{
      setPasswordChangeModalOpen(false);
    })
  }

  return(<div className={classes.main}>
    <PageTitle icon={<AccountBoxIcon/>} title={"My Profile"} style={{flexBasis:"100%", flexShrink:"0"}}/>
    <Section
      title="Account Information"
      icon={<PermIdentityIcon/>}
    ><div className={classes.formContainer}>
      <StyledInput
        label="Username"
        disabled={true}
        className={classes.formInput}
        value={username}
      />
      <StyledInput
        label="Contact Email"
        disabled={true}
        className={classes.formInput}
        value ={email}
      />
    </div></Section>
    <Section
      title="Security"
      icon={<SecurityIcon/>}
    ><div className={classes.formContainer}>
      <div className={classes.formSubmitContainer}>
        {twoFactorEnabled?
          <Button
          variant='contained'
          color="secondary"
          onClick={()=>{
            removeTwoFactorStart();
          }}
          >Disable Two Factor Authorization</Button> 
        :
          <Button
            variant='contained'
            color="primary"
            onClick={()=>{
              setTwoFactorSetupOpen(true);
              setupTwoFactor();
            }}
          >Enable Two Factor Authorization</Button> 
        }
      </div>
      <div className={classes.formSubmitContainer}>
        <Button
          variant='outlined'
          color="primary"
          onClick={()=>{
            setPasswordChangeModalOpen(true);
          }}
        >Change Password</Button>
      </div>
    </div></Section>
    <ProfilePersonalSection
      className = {classes.formContainer}
      personal = {personal}
      updatePersonalProfile={updatePersonalProfile}
    />
    <ProfileBusinessSection
      className = {classes.formContainer}
      business={business}
      updateBusinessProfile={updateBusinessProfile}
    />
    <TwoFactorSetupModal
      isOpen={twoFactorSetupOpen && twoFactorSetupCode ? true:false}
      setupCode={twoFactorSetupCode?twoFactorSetupCode:""}
      handleClose={()=>{
        setTwoFactorSetupOpen(false);
      }}
      submitResponse={completeTwoFactorSetup}
    ></TwoFactorSetupModal>
    <PasswordChangeModal
      open={passwordChangeModalOpen}
      setModalOpen ={setPasswordChangeModalOpen}
      updatePassword={submitPasswordChanges}
    />
  </div>);
}

export default ProfilePage;