import XHR_GET from '../scripts/XHR_GET';
import APIRoutes from './APIRoutes';

const GetTransactionsByAddress = async (address, authToken, {
  getSent= true,
  getReceived= true,
}) =>{
  let sent = [];
  let received = [];
  if(getSent)
  {
    sent = await XHR_GET({
      route: APIRoutes.transactions.sent,
      authToken,
      queries:{
        address
      }
    }).then(info=>{
      //Do post-processing here
      return(info.transactions);
    });
  }
  if(getReceived)
  {
    received = await XHR_GET({
      route: APIRoutes.transactions.received,
      authToken,
      queries:{
        address
      }
    }).then(info=>{
      //Do post-processing here
      return(info.transactions);
    });
  }
  return({sent, received});
};

export default GetTransactionsByAddress;