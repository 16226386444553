import XHR_POST from '../scripts/XHR_POST';
import APIRoutes from './APIRoutes';

const SetupTwoFactorAuth = async (authToken=null) =>{
  if(!authToken)
  {
    throw {
      error: new Error(`Must supply an Auth Token to SetupTwoFactorAuth`),
      response: "",
      status: 400,
    };
  }
  else
  {
    return(XHR_POST({
      route: APIRoutes.mfa.setup,
      data:{},
      authToken,
    }).catch(e=>{
      throw {
        error: new Error(`Unable to start two factor setup`),
        response: e.response?e.response:"",
        status: e.status?e.status:500
      };
    }).then(({message, data, image})=>{
      return({message, data, image});
    }));
  }
}

export default SetupTwoFactorAuth;