const branding = {
    brandedDark:{
      backgroundColor: "#021226",
      color: 'white'
    },
    brandedMain:{
      backgroundColor: "#113059",
      color: 'white'
    },
    brandedSubtle:{
        backgroundColor: "#364959",
        color: "white"
    },
    brandedAccentBright:{
        backgroundColor: "#3C92A6",
        color: "#4D4D4D"
    },
    brandedAccentDull:{
        backgroundColor: "#9CA697",
        color: "#4D4D4D"
    },
    brandedDarkGrey:{
        backgroundColor: "#1b1b1b",
        color: "white"
    },
    brandedMedGrey:{
        backgroundColor: "#2c2c2c",
        color: "rgba(225,225,225)"
    },
    brandedLightGrey:{
        backgroundColor: "#8c8c8c",
        color: "rgba(225,225,225)"
    },
    ContractControlContainer:{
        width: "100%",
    },
    ContractControlSubContainer:{
        display:'flex', 
        flexDirection:"row",
        alignItems: "center",
        justifyContent:"space-around",
        padding: "0.5rem",
        flexWrap: "wrap"
    },
    ContractControlInput:{
        flexShrink: "1",
        flexGrow: "1"
    },
    ContractControlTitle:{
        display:"block",
        width:"100%",
        textAlign:"left",
        paddingTop: "0.75rem",
        paddingLeft: "0.75rem"
    },
    ContractControlSubmit:{
        flexBasis:"10%",
        flexGrow:"0",
        flexShrink:"0",
        width: '10rem',
        minWidth: '10rem'
    },
    ContractControlsContainer:{ //Around the controls
        border: "2px solid black",
        borderRadius: "0rem",
        marginTop:"1rem"
    },
    InputOf1:{
        flexBasis:"80%",
    },
    InputOf2:{
        flexBasis:"40%",
    },
    InputOf3:{
        flexBasis:"26.6%",
    },
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: "white",
      border: '2px solid #000',
      boxShadow: "none",
      padding: "2rem, 4rem, 3rem",
    },
    signatureModal:{
      display: 'flex',
      flexDirection: "column",
      justifyContent:"stretch",
      height: "75vh",
      width: "90vw",
      top:"calc(50% - 37.5vh)",
      left:"calc(50% - 45vw)",
      padding:"1.5rem"
    },
    signatureModalTextOutput:{
      paddingLeft:"2rem", 
      paddingRight: "2rem",
      display:"block",
      wordBreak:"break-all", 
      wordWrap:"normal"
    }
};
export default branding;