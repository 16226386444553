import XHR_POST from '../scripts/XHR_POST';
import APIRoutes from './APIRoutes';

const GenerateContract = async ({
  index=null,
},authToken=null) =>{
  return(XHR_POST({
    route: APIRoutes.contracts.generate,
    data:{
      index
    },
    authToken,
  }).then(({message, contract})=>{
    alert(message);
    return(contract);
  }));
}

export default GenerateContract;