import APIRoutes from './APIRoutes';
import XHR_POST from '../scripts/XHR_POST';

const Login2FA = async (twoFactorResponse, twoFactorToken)=>{
  if(typeof(twoFactorResponse)!='string')
  {
    throw new Error("Invalid Two Factor Response");
  }
  else if(typeof(twoFactorToken)!='string')
  {
    throw new Error("Invalid Two Factor Token");
  }
  else
  {
    return(XHR_POST({
      route: APIRoutes.login.multiFactor,
      data:{
        twoFactorResponse,
        token:twoFactorToken
      }
    }).catch(e=>{
      console.error(e);
      console.error(e.response);
      let error;
      if(e.status==403 || e.status ==401)
      {
        error = new Error("Invalid Two Factor Response");
      }
      else
      {
        error = new Error("Unable to Login");
      }
      error.status = e.status;
      error.response = e.response;
      throw error;
    }).then(result=>{
      //Do post-processing here
      return(result);
    }));
  }
}

export default Login2FA;