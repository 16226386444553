import React, {useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import StyledInput from '../styled_input/styled_input';

const useStyles = makeStyles((theme)=>{
  return({
  });
})

const TwoFactorChallengeModal = (props)=>{
  const [responseInput, setResponseInput] = useState("");
  const theme = useTheme();
  const classes = useStyles(theme);
  
  const {
    isOpen=false,
    setIsOpen=(val)=>{
      console.log(`Default Two Factor Challenge Modal setIsOpen: ${val}`);
    },
    submitResponse=(val)=>{
      console.log(`Default Two Factor Challenge Modal submitResponse: ${val}`);
    },
    handleClose = ()=>{
      setIsOpen(false);
    }
  } = props;
  
  const handleSubmit = ()=>{
    submitResponse(responseInput);
  }
  
  return(<Dialog open={isOpen} onClose={handleClose} >
    <DialogTitle>Two Factor Challenge</DialogTitle>
    <DialogContent>
      <DialogContentText>Enter the response from your Two Factor Authentication App to proceed.</DialogContentText>
      <div style={{display:"flex",flexDirection:"column", justifyContent: "flex-start", alignContent: "center", alignItems:"center", flexWrap:false}}>
        <StyledInput
          value={responseInput}
          updateForm={setResponseInput}
          fullScreen={true}
          style={{flexBasis:"100%", flexShrink:0, flexGrow:1}}
          onEnterPressed = {handleSubmit}
        ></StyledInput>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >Submit</Button>
      </div>
    </DialogContent>
  </Dialog>)
}

export default TwoFactorChallengeModal;