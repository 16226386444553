import React, {useState} from 'react';
import {useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import StyledInput from '../../components/styled_input/styled_input';
import LanguageSelect from '../../components/language_select/language-select';
import useStyles from './onboarding_style';
import Disclosures from '../../components/disclosures/disclosures';
import Translator from '../../scripts/Translator';
const translate = Translator({});

const RegisterPage =(props)=>{
  const theme = useTheme();
  const classes = useStyles(theme);
  const {
    showLogin = ()=>{console.log("Default Show Login")},
    showTermsAndConditions = ()=>{console.log("Default Show Terms")},
    showPrivacyPolicy = ()=>{console.log("Default Show Privacy")},
    setLanguage = ()=>{console.log("Default Set Language")},
    language="English",
    submitRegistration = (username,password,email)=>{
      console.log("Default Submit Registration");
      console.log(`${username}, ${password}, ${email}`);
    },
    basicMessage=null,
    errorMessage=null,
  } = props;
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  return(<div className={classes.baseContainer}>
    <div className={classes.ImageContainer}/>
    <Container className={classes.FormContainer} onSubmit={()=>{}}>
      <Typography variant="h3" className={classes.FormTitle}>
        REGISTER
      </Typography>
      <LanguageSelect
        language = {language}
        onChange = {setLanguage}
      />
      <Typography variant="body1" className={classes.FormSubtitle}>
        {translate(`Please complete and submit the form below to create an account`, language)}
      </Typography>
      <StyledInput
        type='text'
        label='Username'
        className ={classes.FormInput}
        updateForm = {setUsername}
        value={username}
      ></StyledInput>
      <StyledInput
        type='text'
        label='Email Address'
        className ={classes.FormInput}
        updateForm = {setEmail}
        value={email}
      ></StyledInput>
      <StyledInput
        type="password"
        label='Password'
        className ={classes.FormInput}
        updateForm = {setPassword}
        value={password}
      ></StyledInput>
      <StyledInput
        type="password"
        label='Repeat Password'
        className ={classes.FormInput}
        updateForm = {setPasswordRepeat}
        value={passwordRepeat}
      ></StyledInput>
      <div><Button
        variant='outlined'
        color='primary'
        className={classes.FormButton}
        onClick={()=>{
          if(password != passwordRepeat)
          {
            alert('Passwords do not match!');
          }
          else
          {
            submitRegistration(username, password, email);
          }
          
        }}
      >{translate("Register")}</Button>
      <Button
        variant='outlined'
        color='primary'
        className={classes.FormButton}
        onClick={()=>{
          showLogin();
        }}
      >{translate("Return To Login")}</Button>
      </div>
      <div>
        {basicMessage?<Typography variant="body2">{basicMessage}</Typography>:null}
      </div>
      <div>
        {errorMessage?<Typography variant="body2" style={{color:"red"}}>{errorMessage}</Typography>:null}
      </div>
      <Disclosures 
        showPrivacyPolicy = {showPrivacyPolicy}
        showTermsAndConditions = {showTermsAndConditions}
      ></Disclosures>
    </Container>
  </div>)
}

export default RegisterPage;
