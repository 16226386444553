import HandleXHRResponse from './XHR_handleResponse';

//Sends XMLHTTP Request to route indicated with data supplied in JSON format. 
//Callback receives error object or response object
function POST_Request({
  route="/",
  data={},
  headers={},
  authToken=null
}, callback)
{
  var x=new XMLHttpRequest();
  x.onreadystatechange = HandleXHRResponse(x, callback);
  x.open('POST', route, true);
  x.setRequestHeader('Content-Type', 'application/json');
  if(authToken)
  {
    x.setRequestHeader('X-Cangea-Authorization', authToken);
  }
  Object.keys(headers).forEach(h=>{
    x.setRequestHeader(h, headers[h]);
  });
  x.setRequestHeader("x-csrf-Cangea","Anti_CSRF_Token");
  x.send(JSON.stringify(data));
}

const XHR_POST = async ({
  route="/",
  data={},
  headers={},
  authToken=null
}) =>{
  return new Promise((resolve, reject)=>{
    POST_Request({
      route,
      data,
      headers,
      authToken
    }, function(error, result)
    {
      if(error)
      {
        reject(error);
      }
      else
      {
        resolve(result);
      }
    })
  });
}

export default XHR_POST;