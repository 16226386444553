import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddCommentIcon from '@material-ui/icons/AddComment';
import NewsPost from './news_post';
import Translator from '../../scripts/Translator';
import Section from '../section/section';

const translate = Translator({});

const useStyles = makeStyles((theme)=>{
  return({
    NewsSubtitle:{
      marginRight:"1rem",
      marginLeft:"1rem"
    }
  })
})

const NewsPostsSection = (props)=>{
  const {
    posts = []
  } = props;
  const classes = useStyles();
  return(<Section icon={<AddCommentIcon />} title={translate('Updates & News')}>
  <div style={{paddingLeft:"2rem", paddingRight:"2rem", textAlign:'auto'}}><Typography variant="body2" className = {classes.NewsSubtitle}>{translate('Check out some of the latest news and updates.')}</Typography>
  {posts.map((post, index) => (
    <NewsPost key={index} title={post.title.replace(/&#(\d+);/g, function(match, dec) {
      // Eliminates escaped unicode characters
      // from https://stackoverflow.com/a/29824550
      return String.fromCharCode(dec);
    })} link_url={post.link_url} image_url={post.image_url?post.image_url:null}/>
  ))}</div>
</Section>)
}

export default NewsPostsSection;