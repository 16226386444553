import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
const useStyles = makeStyles((theme)=>{
  return({
    main:{
      flexGrow:"1",
      display:"flex",
      flexDirection:"column",
      flexWrap:"wrap",
      justifyContent:"flex-start",
      alignContent:"center",
      alignItems:"center",
      height: "100%",
      width: "100%",
      padding:"3rem",
    },
  });
})

const FourOhFourPage = (props)=>{
  const theme = useTheme();
  const classes = useStyles(theme);
  return(<div className={classes.main}>
    <Typography variant="h3" style={{textAlign:"center"}}>Uh, Oh! We Don't Have This Page.</Typography>
    <SentimentVeryDissatisfiedIcon fontSize='large' style={{margin:"2rem"}}/>
  </div>)
}

export default FourOhFourPage;