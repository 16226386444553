import XHR_GET from '../scripts/XHR_GET';
import APIRoutes from './APIRoutes';

const GetUserContracts = async (authToken, opts) =>{
  let contracts= await XHR_GET({
    route: APIRoutes.contracts.myContracts,
    authToken,
  }).then(info=>{
    //Do post-processing here
    return(info.contracts);
  });
  return(contracts);
};

export default GetUserContracts;