import React, {useState, useEffect} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import BusinessIcon from '@material-ui/icons/Business';
import StyledInput from '../styled_input/styled_input';
import StyledDropdown from '../styled_dropdown/styled_dropdown';
import StatesDropdown from '../states_dropdown/states_dropdown';
import Section from '../section/section';

const useStyles = makeStyles((theme)=>{
  return({
    formSubmitContainer:{
      flexBasis:"100%",
      textAlign:"center",
      marginBottom:"1rem",
      '& > *':{
        margin:"1rem"
      }
    },
    formInput:{
      flexBasis:"48%",
      flexGrow:"0"
    }
  });
});

const ProfileBusinessSection = (props={})=>
{
  const theme = useTheme();
  const classes = useStyles(theme);
  const {
    className = "",
    business = {},
    updateBusinessProfile = (updatedProfile, buttonUpdateFn)=>{console.log(`Default Section Update Business Profile: ${JSON.stringify(updatedProfile)}`);}
  } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [company, setCompany] = useState(business.company||"");
  const [street, setStreet] = useState(business.street||"");
  const [city, setCity] = useState(business.city||"");
  const [state, setState] = useState(business.state||"");
  const [country, setCountry] = useState(business.country||"US");
  const [zip, setZip] = useState(business.zip||"");
  const [phone, setPhoneNumber] = useState(business.phone||"");
  const [tax, setTax] = useState(business.tax||"");
  const [businessId, setBusinessId] = useState(business.business||"");

  const checkIfEdited = ()=>{
    let edited = false;
    if( company != (business.company||"") || 
        street != (business.street||"") || 
        city != (business.city||"") ||
        state != (business.state||"") ||
        country != (business.country||"") ||
        zip != (business.zip||"") ||
        phone != (business.phone||"") ||
        tax != (business.tax||"") ||
        businessId != (business.business||"")
      )
    {
      edited = true;
    };
    setIsEdited(edited);
  }
  useEffect(checkIfEdited, [company, street, city, state, country, zip, phone, tax, businessId]);

  const cancelChanges = ()=>{
    setCompany(business.company||"");
    setStreet(business.street||"");
    setCity(business.city||"");
    setState(business.state||"");
    setCountry(business.country||"US");
    setZip(business.zip||"");
    setPhoneNumber(business.phone||"");
    setTax(business.tax||"");
    setBusinessId(business.business||"");
    setIsEdited(false);
  }

  const submitChanges = ()=>{
    updateBusinessProfile({
      company: company||undefined,
      street: street||undefined,
      city: city||undefined,
      state: state||undefined,
      country: country || undefined,
      zip: zip||undefined,
      phone: phone||undefined,
      tax: tax||undefined,
      business:businessId||undefined
    },()=>{
      console.log(`Business Profile Update Progression Default Notice`);
    }).then(result=>{
      if(result)
      {
        setIsEditing(false);
      }
    })
  }



  
  return(<Section
    title="Business Information"
    icon={<BusinessIcon/>}
  ><div className={className}>
      <StyledInput
        value={phone}
        disabled={!isEditing}
        className={classes.formInput}
        type="text"
        label="Phone Number"
        updateForm={(newVal)=>{
          setPhoneNumber(newVal);
        }}
      />
      <StyledInput
        value={company}
        disabled={!isEditing}
        className={classes.formInput}
        type="text"
        label="Company"
        updateForm={(newVal)=>{
          setCompany(newVal);
        }}
      />
      <StyledInput
        value={street}
        disabled={!isEditing}
        className={classes.formInput}
        type="text"
        label="Street Address"
        updateForm={(newVal)=>{
          setStreet(newVal);
        }}
      />
      <StyledInput
        value={city}
        disabled={!isEditing}
        className={classes.formInput}
        type="text"
        label="City"
        updateForm={(newVal)=>{
          setCity(newVal);
        }}
      />
      <StatesDropdown
        value={state}
        disabled={!isEditing}
        className={classes.formInput}
        label="State / Territory"
        updateForm={(newVal)=>{
          setState(newVal);
        }}
      />
      <StyledInput
        value={zip}
        disabled={!isEditing}
        className={classes.formInput}
        type="text"
        label="ZIP Code"
        updateForm={(newVal)=>{
          setZip(newVal);
        }}
      />
      <StyledDropdown
        value={country}
        disabled={!isEditing}
        className={classes.formInput}
        label="Country"
        updateForm={(newVal)=>{
          setCountry(newVal);
        }}
        items={[
          {
            label:"United States of America",
            value: "United States of America"
          }
        ]}
      />
    </div>
    <div className={classes.formSubmitContainer}>
    {isEditing?null:<Button 
        variant="outlined"
        color={isEditing?"secondary":"primary"}
        onClick={()=>{console.log(`Clicked Component Business Profile Edit`)
          setIsEditing(true);
        }
      }
      >Edit Profile</Button>}
      {isEditing?<Button
        variant="outlined"
        color="secondary"
        onClick={()=>{console.log(`Clicked Component Business Profile Cancel`)
          cancelChanges();
          setIsEditing(false);
        }}
      >Cancel Changes</Button>:null}
      {isEditing&&isEdited?<Button 
        variant="contained"
        color="primary"
        onClick={()=>{console.log("Clicked Component Business Profile Submit"); submitChanges();}}
      >Submit Changes</Button>:null}
  </div></Section>)
}

export default ProfileBusinessSection;