import web3 from 'web3';

async function ImportPrivateKey(privateKey, Web3)
{
    if(typeof(privateKey)!='string')
    {
        throw new Error(`Invalid Private Key to import, must be a string`);
    }
    if(typeof(Web3)!='object' || ! Web3 instanceof web3)
    {
        throw new Error(`Invalid Web3 Object for importing private key`);
    }

    let privateKeyToUse = privateKey;
    if(!privateKey.startsWith('0x'))
    {
        privateKeyToUse = '0x'+privateKey;
    }

    let account;
    try{
        account = Web3.eth.accounts.privateKeyToAccount(privateKeyToUse);
    }
    catch(e)
    {
        console.error(e);
        throw new Error(`Unable to import private key into wallet`);
    }
    return(account);
}

export default ImportPrivateKey;
