import XHR_POST from '../scripts/XHR_POST';
import APIRoutes from './APIRoutes';

const SendInvoiceRequest = ({
  authToken,
  recipient,
  email,
  amount,
  note=""
})=>{
  return(XHR_POST({
    route: APIRoutes.invoice.request,
    data:{
      recipient,email,amount,note
    },
    authToken
  }).then(response=>
  {
    console.log(`Invoice Request response: ${response}`);
    return(response);
  }));
}

export default SendInvoiceRequest;