import React, {useState} from 'react';
import {useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import StyledInput from '../../components/styled_input/styled_input';
import LanguageSelect from '../../components/language_select/language-select';
import useStyles from './onboarding_style';
import Disclosures from '../../components/disclosures/disclosures';
import Translator from '../../scripts/Translator';
const translate = Translator({});

const ForgotPasswordPage =(props)=>{
  const theme = useTheme();
  const classes = useStyles(theme);
  const {
    showLogin = ()=>{console.log("Default Show Login")},
    showTermsAndConditions = ()=>{console.log("Default Show Terms")},
    showPrivacyPolicy = ()=>{console.log("Default Show Privacy")},
    setLanguage = ()=>{console.log("Default Set Language")},
    language="English",
    submitForgotPassword = (username_or_email)=>{
      console.log("Default Submit Forgot Password");
      console.log(`${username_or_email}`);
    },
    basicMessage=null,
    errorMessage=null,
  } = props;
  const [usernameOrEmail, setUsernameOrEmail] = useState("");
  return(<div className={classes.baseContainer}>
    <div className={classes.ImageContainer}/>
    <Container className={classes.FormContainer} onSubmit={()=>{}}>
      <Typography variant="h3" className={classes.FormTitle}>
        Forgot Password
      </Typography>
      <LanguageSelect
        language = {language}
        onChange = {setLanguage}
      />
      <Typography variant="body1" className={classes.FormSubtitle}>
        {translate(`Please complete and sumbit the form below to begin password recovery`, language)}
      </Typography>
      <StyledInput
        type='text'
        label='Username or Email Address'
        className ={classes.FormInput}
        updateForm = {setUsernameOrEmail}
        value={usernameOrEmail}
      ></StyledInput>
      <div><Button
        variant='outlined'
        color='primary'
        className={classes.FormButton}
        onClick={()=>{
          submitForgotPassword(usernameOrEmail);
        }}
      >{translate("Recover")}</Button>
      <Button
        variant='outlined'
        color='primary'
        className={classes.FormButton}
        onClick={()=>{
          showLogin();
        }}
      >{translate("Return To Login")}</Button>
      </div>
      <div>
        {basicMessage?<Typography variant="body2">{basicMessage}</Typography>:null}
      </div>
      <div>
        {errorMessage?<Typography variant="body2" style={{color:"red"}}>{errorMessage}</Typography>:null}
      </div>
      <Disclosures 
        showPrivacyPolicy = {showPrivacyPolicy}
        showTermsAndConditions = {showTermsAndConditions}
      ></Disclosures>
    </Container>
  </div>)
}

export default ForgotPasswordPage;
