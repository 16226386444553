import React, {useState} from 'react';
import {useTheme} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import StyledInput from '../../components/styled_input/styled_input';
import StyledCheckbox from '../../components/styled_checkbox/styled_checkbox';
import LanguageSelect from '../../components/language_select/language-select';
import TwoFactorChallengeModal from '../../components/two_factor_challenge_modal/two_factor_challenge_modal';
import useStyles from './onboarding_style';
import Disclosures from '../../components/disclosures/disclosures';
import Translator from '../../scripts/Translator';
const translate = Translator({});


const LoginPage =(props)=>{
  const theme = useTheme();
  const classes = useStyles(theme);
  const {
    showRegistration = ()=>{console.log("Default Show Registration")},
    showForgotPassword = ()=>{console.log("Default Show Forgot Password")},
    showTermsAndConditions = ()=>{console.log("Default Show Terms")},
    showPrivacyPolicy = ()=>{console.log("Default Show Privacy")},
    setLanguage = ()=>{console.log("Default Set Language")},
    language="English",
    submitLogin = (username,password,rememberMe)=>{
      console.log("Default Submit Login");
      console.log(`${username}, ${password}, ${rememberMe}`);
    },
    basicMessage=null,
    errorMessage=null,
  } = props;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  return(<div className={classes.baseContainer}>
    <div className={classes.ImageContainer}/>
    <Container className={classes.FormContainer} onSubmit={()=>{}}>
      <Typography variant="h3" className={classes.FormTitle}>
        CANPASS
      </Typography>
      <LanguageSelect
        language = {language}
        onChange = {setLanguage}
      />
      <Typography variant="body1" className={classes.FormSubtitle}>
        {translate(`Welcome back! Please login to your account.`, language)}
      </Typography>
      <StyledInput
        variant='outlined'
        type='text'
        label='Username'
        className ={classes.FormInput}
        updateForm = {(val)=>{
          console.log(val);
          setUsername(val);
        }}
        value={username}
        onEnterPressed = {()=>{
        }}
        dropdowns={[]}
      ></StyledInput>
      <StyledInput
        variant='outlined'
        type='password'
        label='Password'
        className ={classes.FormInput}
        updateForm = {(val)=>{
          setPassword(val);
        }}
        onEnterPressed = {()=>{
          submitLogin(username, password, rememberMe);
        }}
        value={password}
      ></StyledInput>
      <div className={classes.FormForgotPassword}>
        <StyledCheckbox checked={rememberMe} updateForm ={setRememberMe} label="Remember Me"/>
        <Typography
          variant="body1"
          component='a'
          style={{fontSize:".75rem"}}
          className={classes.FormForgotPasswordText}
          onClick={()=>{showForgotPassword()}}
        >{translate("Forgot Password?")}</Typography>
      </div>
      <div><Button
        variant='outlined'
        color='primary'
        className={classes.FormButton}
        onClick={()=>{
          submitLogin(username, password, rememberMe);
        }}
      >{translate("Login")}</Button>
      <Button
        variant='outlined'
        color='primary'
        className={classes.FormButton}
        onClick={()=>{
          showRegistration();
        }}
      >{translate("Register")}</Button></div>
      <div>
        {basicMessage?<Typography variant="body2">{basicMessage}</Typography>:null}
      </div>
      <div>
        {errorMessage?<Typography variant="body2" style={{color:"red"}}>{errorMessage}</Typography>:null}
      </div>
      <Disclosures 
        showPrivacyPolicy = {showPrivacyPolicy}
        showTermsAndConditions = {showTermsAndConditions}
      ></Disclosures>
    </Container>
  </div>)
}

export default LoginPage;
