import React, {useState} from 'react';

import {makeStyles, useTheme} from '@material-ui/core/styles';

import NoteIcon from '@material-ui/icons/Note';
import Button from '@material-ui/core/Button';

import PageTitle from '../../components/page_title/page_title';
import EditContractMetaSection from '../../components/edit_contract_meta/edit_contract_meta';
import EditContractReceiverSection from '../../components/edit_contract_receiver/edit_contract_receiver';
import { useEffect } from 'react';

const useStyles = makeStyles((theme)=>{
  return({
    main:{
      display:"flex",
      flexDirection:"row",
      flexWrap:"wrap",
      justifyContent:"flex-start",
      alignContent:"flex-start",
      alignItems:"center",
      height: "100%",
      width: "100%",
      padding:"1rem"
    },
    editMetaSection:{
      width: "100%"
    }
  })
});

const EditContractPage = (props)=>{
  const {
    help_link_url=null,
    saveChanges = (contractData)=>{console.log(`Default Save Changes: ${JSON.stringify(contractData)}`)},
    compileContract = ()=>{console.log(`Default Compile Contract`)},
    copyContract = ()=>{console.log(`Default Copy Contract`)},
    deleteContract = ()=>{console.log(`Default Delete Contract`)},
    errors = {},
    startingMeta = {
      name: "",
      index: "",
      management: "",
      primaryEmail: "",
      description:"",
      activeOnly:false,
      erc223:false,
      receivable:false
    },
    startingReceivers=[],
    contracts=[],
    wallets=[],
    metaMaskAddress=null,
    lookupUser=async(query)=>{return([])}
  } = props;
  const [originalRender, setOriginalRender] = useState(Date.now());
  const theme = useTheme();
  const classes = useStyles(theme);
  const [meta, setMeta] = useState({...startingMeta});
  const [receivers, setReceivers] = useState(startingReceivers);
  const [isChanged, setIsChanged] = useState(false);
  
  const [pageRenderTime] = useState(Date.now());
  const checkIfChanged = (newInfo, currentInfo)=>{
    let changed = false;
    if(Date.now() - 2*1000 > pageRenderTime)
    {
      if(Array.isArray(newInfo.receivers))
      {
        newInfo.receivers.forEach((r,i)=>{
          if(!changed)
          {
            Object.keys(r).forEach(key=>{
              if(r[key] != currentInfo.receivers[i][key])
              {
                // console.log(`Receiver ${i} ${key} is not the same`);
                // console.log(`Receiver ${i} : ${r[key]}`);
                // console.log(`New Receiver ${i} : ${currentInfo.receivers[i][key]}`);
                changed = true;
              }
            });
          }
        });
      }
      if(!changed)
      {
        Object.keys(newInfo).forEach(key=>{
          if(key!='receivers' && currentInfo[key]!=newInfo[key])
          {
            //console.log(`Meta ${key} is not the same`);
            //console.log(`Meta: ${currentInfo[key]}`);
            //console.log(`New Meta: ${newInfo[key]}`);
            changed= true;
          }
        });
      }
      
    }
    return(changed);
  }

  return(<div className={classes.main}>
    <PageTitle
      title="Edit Payment Contract"
      icon={<NoteIcon/>}
      link={help_link_url}
    />
    <EditContractMetaSection
      errors = {errors}
      startingName={startingMeta.name}
      startingEmail={startingMeta.primaryEmail}
      startingManagerAddress = {startingMeta.management}
      startingDescription = {startingMeta.description}
      contracts = {contracts}
      wallets={wallets}
      metaMaskAddress={metaMaskAddress}
      updatePage={(newData)=>{
        setMeta(currentMeta=>{
          setIsChanged(checkIfChanged({...newData}, {...currentMeta}));
          return({...currentMeta, ...newData});
        });
      }}
      style={{width:"100%"}}
      className = {classes.editMetaSection}
    />
    {receivers.map((receiver, index)=>{
      const {
        cangeaID: startingCangeaID="",
        address: startingAddress="",
        email: startingEmail="",
        sendRequest: startingSendRequest = false,
        percent: startingPercent = 0,
        first: startingFirstName = "",
        last: startingLastName = "",
        company: startingCompany = "",
        phone: startingPhone = "",
        street: startingStreet = "",
        city: startingCity = "",
        state: startingState = "",
        country: startingCountry = "United States of America",
        zip: startingZip = "",
      } = receiver;
      return(<EditContractReceiverSection
        key={`${originalRender}+${index}`}
        startingCangeaID={startingCangeaID}
        startingAddress={startingAddress}
        startingEmail={startingEmail}
        startingSendRequest={startingSendRequest}
        startingPercent={startingPercent}
        startingFirstName={startingFirstName}
        startingLastName={startingLastName}
        startingCompany={startingCompany}
        startingPhone={startingPhone}
        startingStreet={startingStreet}
        startingCity={startingCity}
        startingState={startingState}
        startingCountry={startingCountry}
        startingZip={startingZip}
        contracts = {contracts}
        wallets={wallets}
        metaMaskAddress={metaMaskAddress}
        updatePage= {(updatedInfo=>{
          setReceivers(currentReceivers=>{
            setIsChanged(checkIfChanged({
              receivers: [currentReceivers[index]]
            },{
              receivers: [updatedInfo]
            }));
            currentReceivers[index] = updatedInfo;
            return([].concat(currentReceivers));
          });
          
        })}
        deleteReceiver={()=>{
          setOriginalRender(Date.now());
          setReceivers(currentReceivers=>{
            currentReceivers.splice(index,1);
            return([].concat(currentReceivers));
          });
          setIsChanged(true);
        }}
        lookupUser={lookupUser}
      ></EditContractReceiverSection>)
    })}
    <div style={{width:"100%", textAlign:"center", margin:"1rem", flexShrink:1}}>
      <Button
        variant="contained"
        color="primary"
        onClick={()=>{
          setOriginalRender(Date.now());
          setReceivers(currentReceivers=>{
            currentReceivers.push({});
            return([].concat(currentReceivers));
          })
        }}
      >Add Receiver</Button>
    </div>
    {isChanged?<div style={{width:"100%", textAlign:"center", margin:"1rem", flexShrink:1}}>
      <Button
        variant="contained"
        color="primary"
        onClick={()=>{
          saveChanges({
            ...meta,
            receivers
          })
        }}
      >Save Changes</Button>
    </div>:null}
    <div style={{width:"100%", textAlign:"center", margin:"1rem", flexShrink:1}}>
      <Button
        variant="contained"
        color="primary"
        onClick={()=>{
          copyContract({
            ...meta,
            receivers
          });
        }}
      >{`Copy${isChanged?" Edited":""} Contract`}</Button>
    </div>
    {isChanged?null:<div style={{width:"100%", textAlign:"center", margin:"1rem", flexShrink:1}}>
      <Button
        variant="contained"
        color="primary"
        onClick={()=>{
          compileContract();
        }}
      >Compile Contract</Button>
    </div>}
    <div style={{width:"100%", textAlign:"center", margin:"1rem", flexShrink:1}}>
      <Button
        variant="contained"
        color="secondary"
        onClick={()=>{
          deleteContract();
        }}
      >Delete Contract</Button>
    </div>

  </div>)
}

export default EditContractPage;