import React from 'react';
import StyledInput from '../styled_input/styled_input';

const WalletsInputDropdown = ({
  value="",
  wallets=[],
  contracts=[],
  metaMaskWallet=null,
  updateForm=()=>{},
  label="Wallet",
  style={},
  className="",
  disabled=false
})=>{
  
  let dropdowns = wallets.map(wallet=>{
    return({
      value: wallet.address,
      text: wallet.name
    });
  }).concat(contracts.filter(({deployed=false})=>{return(deployed)}).map(contract=>{
    return({
      text: `Contract: ${contract.name}`,
      value:contract.deployedAddress
    })
  }));
  if(typeof(metaMaskWallet)=='string')
  {
    dropdowns.push({
      text:"MetaMask Injected",
      value: metaMaskWallet
    });
  }
  return(<StyledInput
    style={style}
    className={className}
    value={value}
    label={label}
    type="text"
    updateForm={updateForm}
    variant='outlined'
    dropdowns={dropdowns}
    disabled={disabled}
  ></StyledInput>)
}

export default WalletsInputDropdown;