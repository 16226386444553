import React from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';

import CreditCardIcon from '@material-ui/icons/CreditCard';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const NavLinks = [{
  text:"Dashboard",
  icon:<DashboardIcon/>,
  page: "/dashboard",
},{
  text:"Create Contract",
  icon:<NoteAddIcon/>,
  page: "/contracts/create",
},{
  text:"My Contracts",
  icon:<LibraryBooksIcon/>,
  page: "/contracts/my-contracts",
},{
//   text:"Activity",
//   icon:<AttachMoneyIcon/>,
//   page: "/activity",
// },{
  text:"Transfer Activity",
  icon:<SwapHorizIcon/>,
  page: "/transfers",
},{
  text:"Wallets",
  icon:<AccountBalanceWalletIcon/>,
  page: "/wallets",
},{
  text:"Purchase Tokens",
  icon:<CreditCardIcon/>,
  page: "/purchase",
},{
  text:"Support",
  icon:<LiveHelpIcon/>,
  page: "/support",
},{
  text:"Log Out",
  icon:<ExitToAppIcon/>,
  page: "/logout",
  endOfList:true
}];

export default NavLinks;