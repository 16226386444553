import APIRoutes from './APIRoutes';
import XHR_POST from '../scripts/XHR_POST';

const Register = async (username, password, email)=>{
  if(typeof(username)!='string')
  {
    throw new Error("Invalid Username");
  }
  else if(typeof(password)!='string')
  {
    throw new Error("Invalid Password");
  }
  else if(typeof(email)!='string')
  {
    throw new Error("Invalid Email");
  }
  else
  {
    return(XHR_POST({
      route: APIRoutes.register,
      data:{
        username,password,email
      }
    }).catch(e=>{
      console.error(e);
      console.error(e.response);
      let error;
      if(e.status==401)
      {
        error = new Error("Invalid Inputs");
      }
      else if(e.status==409)
      {
        error = new Error("Username already reserved");
      }
      else
      {
        error = new Error("Unable to Register");
      }
      error.status = e.status;
      error.response = e.response;
      throw error;
    }).then(result=>{
      //Do post-processing here
      /*
      message,
      verifyEmail?,
      signup_link*
      */
      return(result);
    }));
  }
}

export default Register;