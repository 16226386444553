import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

import TransfersTable from '../../components/transfers_table/transfers_table';
import PageTitle from '../../components/page_title/page_title';
import Section from '../../components/section/section';

const usePageStyles = makeStyles((theme)=>{
  return({
    main:{
      display:"flex",
      flexDirection:"column",
      flexWrap:"wrap",
      justifyContent:"flex-start",
      alignContent:"flex-start",
      alignItems:"stretch",
      height: "100%",
      width: "100%",
      padding:"1rem"
    },
    table: {
      minWidth: 650,
    },
    tableSection:{
      padding:"0",
      width:"100%",
      '& > div':{
        padding:"0"
      }
    }
  });
});


const TransfersReceivedPage = (props)=>{
  const {help_link_url=null, transfers=[
    {
      transactionHash:"test_transaction_hash_which_is_really_long",
      blockHash: "test_block_hash_which_is_also_really_long",
      logIndex:1,
      sender:"0x13d3d44e768ee41b16d73c7db623fbc41b4de748",
      recipient:"0x13d3d44e768ee41b16d73c7db623fbc41b4de748",
      senderName:"dispense_mo_test",
      senderContract:false,
      senderOwned: false,
      recipientName:"logged_in_user",
      recipientContract: false,
      recipientOwned:true,
      date: Date.now(),
      timeStamp: new Date(Date.now()).toISOString(),
      amount:"50000000000000000000000",
      token: "Cangea",
      symbol: "CNG",
      tokenAddress:"0xe08e982F04c9919E7900DbA37Cc757F7751F7919",
      value: 50.00,
      price: "0.0000000000000000001"
    }
  ]} = props;
  const theme = useTheme();
  const classes = usePageStyles(theme);

  return(<div className={classes.main}>
    <PageTitle icon={<SwapHorizIcon/>} title={"Transfer Activity"} style={{flexBasis:"100%", flexShrink:"0"}}/>
    <Section
      help_link_url={help_link_url}
      className={classes.tableSection}
    >
      <TransfersTable
        transfers={transfers}
        title={<div style={{display:"flex",flexDirection:"row",alignItems:"center"}}><SwapHorizIcon style={{marginRight:"0.5rem"}}/> Recent Transfers</div>}
      />
    </Section>
  </div>);
}

export default TransfersReceivedPage;