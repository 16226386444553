import React from 'react';
import BigNumber from 'bignumber.js';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import CallMadeIcon from '@material-ui/icons/CallMade';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles';

import Section from '../section/section';

const useStyles = makeStyles((theme)=>{
  return({
    receiversContainer:{
      display:"flex",
      flexDirection:"row",
      flexWrap:"nowrap",
      justifyContent:"flex-start"
    },
    recipientInfoContainer:{
      display:"flex",
      flexDirection:"column",
      justifyContent:"flex-start",
      alignItems:"stretch",
      margin:"0.5rem",
      minWidth:"20rem"
    },
    recipientInfoTitle:{
      backgroundColor:"#021226",
      color:"white",
      width:"100%",
      flexGrow:"0",
      display:"inline-flex",
      flexDirection:"row",
      justifyContent:"flex-start",
      flexWrap:"nowrap",
      padding:"0.25rem",
      '& svg':{
        margin:"0.25rem"
      }
    },
    recipientInfoBody:{
      backgroundColor: "rgb(240, 240, 247)",
      flexGrow:"1",
      padding:"1rem",
      display:"inline-flex",
      flexDirection:"column",
      justifyContent:"flex-start",
      alignItems:"flex-start",
      '& span':{
        fontWeight:"600",
        marginTop:".25rem",
        marginBottom:".25rem",
      }
    },
    recipientInfoFooter:{
      backgroundColor:"#021226",
      color:"white",
      width:"100%",
      flexGrow:"0",
      display:"inline-flex",
      flexDirection:"row",
      justifyContent:"space-between",
      alignItems:"center",
      padding:"0.5rem"
    }
  });
});

const ContractReceiversSection = ({
  sectionTitle="Contract Receivers",
  receivers=[],
})=>{
  const theme = useTheme();
  const classes = useStyles(theme);
  

  const ReceiverCard = ({
    receiverTitle="",
    cangeaID="",
    address="",
    email="",
    percent=100,
    first="",
    last="",
    company="",
    phone="",
    street="",
    city="",
    state="",
    country="",
    zip="",
    totalReceived = 0,
    key=0, //used for mapping render
  }) =>{
    let totsReceived = new BigNumber(totalReceived).div(1e18).toFixed(4);
    return(<div className={classes.recipientInfoContainer} key={key}>
      <div className={classes.recipientInfoTitle}>
        <CallMadeIcon/><Typography variant="h6">{receiverTitle}</Typography>
      </div>
      <div className={classes.recipientInfoBody}>
        <Typography variant="body1" component="span" >{company}</Typography>
        <Typography variant="body2" >{first} {last}</Typography>
        <Typography variant="body2" >{street}</Typography>
        <Typography variant="body2" >{city} {state}, {zip}</Typography>
        <Typography variant="body2" >{country}</Typography>
        <Typography variant="body2" >{phone}</Typography>
        <Typography variant="body2" >{email}</Typography>
        <Typography variant="body1" component="span">Receives {percent}% of Payments</Typography>
      </div>
      <div className={classes.recipientInfoFooter}>
      <Typography variant="body1" >Total Received</Typography>
        <Typography variant="body1" >{totsReceived} CNG</Typography>
      </div>
    </div>);
  }

  return(<Section
    title={sectionTitle}
    icon ={<CallSplitIcon/>}
  ><div className={classes.receiversContainer}>
    {receivers.map((receiver,index)=>{
      return({...receiver, receiverTitle:`Receiver ${index+1}`, key:index});
    }).map(ReceiverCard)}
  </div></Section>)
}

export default ContractReceiversSection;