import React, {useState} from 'react';

import {makeStyles} from '@material-ui/core/styles';
import DashboardIcon from '@material-ui/icons/Dashboard';

import PageTitle from '../../components/page_title/page_title';
import NewsPostSection from '../../components/news_posts/news_posts_section';
import RequestFundsSection from '../../components/request_funds_section/request_funds_section';
import DashboardTips from '../../components/dashboard_tips/dashboard_tips';
import ReportsSection from '../../components/reports_section/reports_section';
import CreateContractSection from '../../components/create_contract_section/create_contract_section';

const useStyles = makeStyles((theme)=>{
  return({
    main:{
      display:"flex",
      flexDirection:"row",
      flexWrap:"wrap",
      justifyContent:"flex-start",
      alignContent:"flex-start",
      alignItems: "stretch",
      height: "100%",
      width: "100%",
      padding:"1rem"
    }
  })
});

const DashboardPage = ({
  requestFunds=()=>{
    console.log(`Request Funds Page Default`);
  },
  createContract = ()=>{
    console.log(`Create Contract Page Default`);
  },
  video_link="",
  newsPosts,
  wallets=[],
  contracts=[],
  metaMaskAddress=null
})=>{
  
  console.log(`Dashboard Video: ${video_link}`)
  const classes = useStyles();

  return(<div className={classes.main}>
    <PageTitle icon={<DashboardIcon/>} title={"Dashboard"} style={{flexBasis:"100%", flexShrink:"0"}}/>
    <NewsPostSection posts={newsPosts}/>
    <RequestFundsSection
      submit={requestFunds}
      wallets={wallets}
      contracts={contracts}
      metaMaskAddress={metaMaskAddress}
    />
    <ReportsSection/>
    <CreateContractSection
      createNewContract={createContract}
      wallets={wallets}
      metaMaskAddress={metaMaskAddress}
    />
    <DashboardTips
      video_link={video_link}
    />
  </div>)
}

export default DashboardPage;