import React, { useState, useEffect, useRef } from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {FormControl, InputLabel, OutlinedInput, Input} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      minWidth: "10rem",
      width:"auto"
    },
    dense: {
      marginTop: 19,
    },
    menu: {
      width: 200,
    },
}));

function StyledInput(props)
{
    const classes = useStyles();
    let {
        type="text",
        value="",
        password=false,
        updateForm=function(updated_value){
            console.log(`Updating Styled Input Value: ${updated_value}`)
        },
        onEnterPressed=()=>{
          console.log(`Pressed Enter in Styled Input`);
        },
        onDropdownSelect=(value)=>{
          console.log(`Selected from dropdown in Styled Input`);
        },
        variant='outlined',
        endAdornment=null,
        minimum=null,
        maximum=null,
        step=1,
        disabled=false,
        dropdowns=[/*{text,value}*/]
    } =props;
    let multiline=false;
    if(type=="textarea")
    {
        type="text";
        multiline=true;
    }
    if(type=="password")
    {
      type="text";
      password=true;
    }
    const [currentValue, setCurrentValueReal] = useState(value);
    const currentValRef = useRef(currentValue);
    const setCurrentValue = (newVal)=>{
      currentValRef.current = newVal;
      setCurrentValueReal(newVal);
    }
    const lastUpdateRef = useRef(Date.now());
    useEffect(()=>{
        if(lastUpdateRef.current < Date.now() - 10)
        {
          console.log(`Update Form`)
          updateForm(currentValue);
          lastUpdateRef.current = Date.now();
        }
    }, [currentValue])
    const [focus, setFocusedReal] = useState(false);
    const focused = useRef(focus);
    const setFocused =(newVal)=>{
      setFocusedReal(newVal);
      focused.current = newVal;
    }

    const [dropdownOpen, setOpen] = useState(false);
    
    function shouldBeActive(val){//val){
        // if(typeof(val)=='undefined')
        // {
            
        // }
        //let val = currentValRef.current;
        if(focused.current)
        {
          return(true);
        }
        else if(typeof(val)!='undefined' && val !==null && val !=='')
        {
          return(true);
        }
        else
        {
          return(false);
        }
    }
    const [isActive, setActive] = useState(shouldBeActive(value));
    const [labelWidth, setLabelWidth] = useState(0);

    function onFocus(){
        setCurrentValue(value);
        setOpen(true);
        setFocused(true);
        setActive(true);
    }
    function onBlur(){
        setCurrentValue(cv=>{
            //updateForm(cv);
            return(cv);
        });
        setFocused(false);
    }

    const handleKeyPress = (event) => {
      if(event.key === 'Enter'){
          setCurrentValue(cv=>{
            updateForm(cv);
            onEnterPressed();
            return(cv);
          })
      }if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      }
    }
    

    const handleChange = event => {
        let value = event.target.value;
        if(type==='number')
        {
            if(maximum!==null&&value>maximum)
            {
                value = maximum;
            }
            if(minimum!==null&&value<minimum)
            {
              value=minimum;
            }
        }
        setCurrentValue(value);
    };
    const labelRefCallback = element =>{
        if(element)
        {
            setLabelWidth(element.offsetWidth);
        }
    }
    const Label = <InputLabel style={{left:"0rem"}} ref={labelRefCallback}>{props.label}</InputLabel>;
    
    let outlinedProp = {
        labelWidth
    };
    if(variant!='outlined')
    {
        delete outlinedProp.labelWidth;
    }

    useEffect(()=>{
        setActive(shouldBeActive(currentValRef.current));
    }, [currentValue, value, focus]);

    const anchorRef = useRef(null);
    
    const handleClose = (dropdown_value)=> event => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
      if(dropdown_value)
      {
        onDropdownSelect(dropdown_value);
        setCurrentValue(dropdown_value);
        updateForm(dropdown_value);
        anchorRef.current.focus();
      }
      setOpen(false);
    };
    return(<FormControl 
        className = {[classes.formControl, props.className?props.className:""].join(' ')}
        variant="outlined"
        style = {{...props.style}}
        
    >
        {Label}
        <OutlinedInput
            ref={anchorRef}
            disabled={disabled}
            inputProps={{step:step, onKeyPress:handleKeyPress, autoComplete:`${Date.now()}-${Math.random()*1000}`}}
            notched = {shouldBeActive(focused.current?currentValue:value)}
            type = {password?"password":type}
            value = {focused.current?currentValue:value}
            onChange = {handleChange}
            onFocus = {onFocus}
            onBlur = {onBlur}
            multiline = {multiline}
            {...outlinedProp}
            endAdornment={endAdornment}
            style={{width:"auto"}}
        ></OutlinedInput>
        <Popper open={focused.current && dropdowns.length>0} anchorEl={anchorRef.current} role={undefined}  
          style={{width:anchorRef.current?anchorRef.current.offsetWidth:0, zIndex:2}} transition
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' , zIndex:2}}
            >
              <Paper style={{zIndex:2, maxHeight:200, overflowY:dropdowns.length>5?"scroll":"hidden"}}>
                <ClickAwayListener onClickAway={handleClose(null)}>
                  <MenuList autoFocusItem={false} id="menu-list-grow" onKeyDown={handleKeyPress} style={{zIndex:2,width:"100%"}}>
                    {dropdowns.map((dd,index)=>{
                        return(<MenuItem key={`${dd.value}/${index}`} onClick={handleClose(dd.value)}><Typography variant="body2">{dd.text}</Typography></MenuItem>)
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
    </FormControl>);
}

export default StyledInput;
