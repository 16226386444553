import XHR_POST from '../scripts/XHR_POST';
import APIRoutes from './APIRoutes';

const RemoveWallet = async ({
  address=null,
  name
},authToken=null) =>{
  const wantsToDelete = window.confirm(`Are you sure you want to delete the wallet "${name}"?(address ${address})`);
  if(wantsToDelete)
  {
    return(XHR_POST({
      route: APIRoutes.wallets.remove,
      data:{
        address
      },
      authToken,
    }).then(({message})=>{
      return(true);
    }));
  }
  else
  {
    return(false);
  }
}

export default RemoveWallet;