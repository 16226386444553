import web3 from 'web3';
import BigNumber from 'bignumber.js';

import ImportPrivateKey from './importPrivateKey';

const AddressRegex = /^0x[0-9a-fA-F]{40}$/;

/*
Progress function receives numeric status updates throughout
Positive statuses indicate happy path progression
Negative statuses indicate failures and end of execution
The absolute value of each status indicates the stage of execution
[
  0: Entry
  1: Input Validated
  2: Function Call Successful
  3: Call Return Value Handled
  4: TX Signed
  5: TX Sent
  6: TX Receipt Available (resolves)
]
*/

/*
  Handle return value must be an async function that returns a boolean value
  The returned value determines whether or not to sign and send the transaction
*/

/*
 Opts & default values:
 {
    from=privateKeyAccountAddress:"",
    value=0,
    gas=10e6,
    gasPriceCoef=1,
    gasPrice=10e6
  }
*/

const CreateContractMethod = (abi='[]', argTypes=[], method='') =>{
  // Parameter Validation

  // ABI validation
  if(typeof(abi)!='string' && !Array.isArray(abi))
  {
    throw new Error(`Create Contract Method ABI arg must be a string or Array`);
  }
  let useABI = abi;
  if(typeof(abi)=='string')
  {
    try{
      useABI = JSON.parse(abi);
    }
    catch(e)
    {
      console.error(e);
      throw new Error(`Create Contract Method ABI arg must be valid JSON if passed as a string`);
    }
  }
  if(!Array.isArray(useABI))
  {
    throw new Error(`Create Contract Method ABI arg must be a parsed to a valid Array object`);
  }

  // Arg Type validation
  const validTypes = ['string','number','address'];
  argTypes.forEach((type, index)=>{
    if(!validTypes.includes(type))
    {
      throw new Error(`Invalid argument type specified for method ${method} at argument ${index}: ${JSON.stringify(type)}`);
    }
  });

  // Method Name validation
  if(typeof(method)!='string' || method ==='')
  {
    throw new Error(`Create Contract Method method name must be a non-zero length string`);
  }


  // Return function
  return(async function DerivedContractMethod(Web3=null, contractAddress='0x0', callArgs=[], opts={}, privateKey=null, progressFunction=(stage)=>{
    console.log(`Contract Method Call has reached stage ${stage}`);
  },
  handleCallReturnValue= async (val)=>{
    //Returns whether to proceed with sending the actual transaction
    return(true);
  }){
    
    //Meta Validation
    if(typeof(progressFunction)!='function')
    {
      throw new Error(`Progress Function for Contract Method Call must be a function`);
    }
    progressFunction(0);
    // Validate Web3
    if(typeof(Web3)!='object' || ! Web3 instanceof web3)
    {
      throw new Error(`Invalid Web3 Object passed to method ${method}`);
    }
    // Validate Contract Address
    if(typeof(contractAddress)!='string' || ! AddressRegex.test(contractAddress))
    {
      throw new Error(`Invalid Contract Address passed to method ${method}`);
    }
    // Validate Options
    if(typeof(opts)!='object')
    {
      throw new Error(`Options for Contract Method Call must be an object`)
    }


    //Private Key Validation (optional)
    let account = null;
    if(privateKey)
    {
      account = await ImportPrivateKey(privateKey, Web3).catch(e=>{
        console.error(e);
        throw new Error(`Invalid Private Key passed to Contract Methood Call ${method}`);
      });
    }
    // Argument Validation
    if(!Array.isArray(callArgs))
    {
      throw new Error(`Call Arguments for Contract Method must be an Array`)
    }
    else if(callArgs.length != argTypes.length)
    {
      throw new Error(`Contract Method Call argument mismatch: expected ${argTypes.length}, got ${callArgs.length}`);
    }

    let errored = false;
    let errors = argTypes.map((argType, index)=>{
      if(callArgs.length)
      switch(argType)
      {
        case 'string':{
          if(typeof(callArgs[index]) !='string')
          {
            errored = true;
            return(`Method ${method} arg ${index} must be a string`);
          }
          break;
        }
        case 'address':{
          if(typeof(callArgs[index]) !='string' || ! AddressRegex.test(callArgs[index]))
          {
            errored = true;
            return(`Method ${method} arg ${index} must be a valid string address`);
          }
          break;
        }
        case 'number':{
          if(typeof(callArgs[index])=='string')
          {
            try
            {
              new BigNumber(callArgs[index]).toString();
            }
            catch(e)
            {
              console.error(e);
              errored = true;
              return(`Method ${method} arg ${index} must be a valid number`)
            }
          }
          else if(typeof(callArgs[index]) !='number' && ! callArgs[index] instanceof BigNumber)
          {
            errored = true;
            return(`Method ${method} arg ${index} must be a String number, Number number, or BigNumber`);
          }
          callArgs[index] = new BigNumber(callArgs[index]).toString();
          break;
        }
        default:{
          errored = true;
          return(`Method ${method} arg type at ${index} is invalid: ${JSON.stringify(argType)}`)
        }
      }
    });
    if(errored)
    {
      progressFunction(-1);
      throw new Error(`Invalid input arguments: ${JSON.stringify(errors)}`);
    }
    else
    {
      const contract = new Web3.eth.Contract(abi);
      contract.options.address = contractAddress;
      if(typeof(contract.methods[method])!='function')
      {
        progressFunction(-1);
        console.log(Object.keys(contract.methods));
        throw new Error(`Invalid method for contract call: ${method}`);
      }
      progressFunction(1);
      const functionCall = contract.methods[method](...callArgs);
      
      const {
        from=account?account.address:"",
        value=0,
        gas=7.99e6,
        gasPriceCoef=1,
        gasPrice=20e9
      } = opts;
      if(typeof(from)!='string' || ! AddressRegex.test(from))
      {
        progressFunction(-1);
        throw new Error(`Invalid Sender Address for contract method ${method}`);
      }
      console.log(`Sending From ${from}`);
      const txData = {
        from,
        to: contractAddress,
        value: value.toString(),
        gas: gas.toString(),
        gasPriceCoef,
        gasPrice
      };
      
      return(functionCall.call({
        ...txData
      }).catch(e=>{
        console.error(e);
        progressFunction(-2);
        throw new Error(`Unable to call contract method ${method} at address ${contractAddress}`);
      }).then(async (returnValue)=>{
        progressFunction(2);
        const shouldSendTransaction = await handleCallReturnValue(returnValue).catch(e=>{
          console.error(e);
          progressFunction(-3);
          throw new Error(`Unable to handle call result from contract method ${method} at address ${contractAddress}`);
        });
        if(!shouldSendTransaction)
        {
          return(null);
        }
        else
        {
          progressFunction(3);
          //Sign Transaction Data
          const methodData = functionCall.encodeABI();
          txData.data = methodData;

          const setupTxListeners = (txEmitter, resolve, reject)=>{
            txEmitter = txEmitter.once('transactionHash', function(txHash)
            {
              progressFunction(5);
            });
            txEmitter = txEmitter.once('error', function(error)
            {
              console.error(error);
              progressFunction(-5);
              reject(new Error('Unable to complete sending trnsaction'));
            });
            txEmitter = txEmitter.once('receipt', (receipt) =>{
              progressFunction(6);
              resolve(receipt);
            });
          }
          const handleSigningError =(e)=>{
            progressFunction(-4);
            console.error(e);
            throw new Error(`Could not sign transaction. Was it rejected?`);
          };

          let signedTx;
          if(account)
          {
            const nonce = await Web3.eth.getTransactionCount(txData.from, 'pending').catch(e=>{
              progressFunction(-2);
              console.error(e);
              throw new Error(`Unable to get sender nonce for ${txData.from}`);
            });
            txData.nonce = nonce.toString();
            signedTx = await account.signTransaction(txData).catch(handleSigningError);
            //Send Signed Transaction Data
            return(new Promise((resolve, reject)=>
            {
              progressFunction(4);
              let txEmitter = Web3.eth.sendSignedTransaction(signedTx.rawTransaction);
              setupTxListeners(txEmitter, resolve, reject);
            }));
          }
          else
          {
            return(new Promise((resolve, reject)=>
            {
              progressFunction(4);
              let txEmitter = Web3.eth.sendTransaction(txData);
              setupTxListeners(txEmitter, resolve, reject);
            }));
          }
        }
      }))
    }
  });

  
    
};

export default CreateContractMethod;