import React, {useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';

import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import PageTitle from '../../components/page_title/page_title';
import Section from '../../components/section/section';
import WalletInfo from '../../components/wallet_info/wallet_info';
import RecentContractsSlider from '../../components/recent_contracts_slider/recent_contracts_slider';
import ContractsOverviewTable from '../../components/contracts_overview/contracts_overview';

const useStyles = makeStyles((theme)=>{
  return({
    infoContainer:{
      display:"flex",
      flexDirection:"column",
      justifyContent:"center",
      alignContent:"center",
      alignItems:"center",
   },
   main:{
    display:"flex",
    flexDirection:"row",
    flexWrap:"wrap",
    justifyContent:"flex-start",
    alignContent:"flex-start",
    alignItems:"center",
    height: "100%",
    width: "100%",
    padding:"1rem",
    paddingBottom:"3rem"
  },
  infoSection:{width:"100%"},
  tableSection:{
    padding:"0",
    width:"100%",
    '& > div':{
      padding:"0"
    }
  }
  });
});


const MyContractsPage = ({
  contracts = [],
  openContractPage= (indexOrAddress)=>{
    console.log(`Default Open Contract ${indexOrAddress}`);
  }
})=>{
  const theme = useTheme();
  const classes = useStyles(theme);

  return(
  <div className={classes.main}>
    <PageTitle icon={<LibraryBooksIcon/>} title={"My Contracts"} style={{flexBasis:"100%", flexShrink:"0"}}/>
    <Section
      title="Recent Contracts"
      icon={<LibraryBooksIcon/>}
      className={classes.infoSection}
    >
      <div className = {classes.infoContainer}>
        <RecentContractsSlider contracts={contracts}
          onClick={(event, contractIndex)=>{
            openContractPage(event, contractIndex);
          }}
        />
      </div>
    </Section>
    <Section
      className={classes.tableSection}
    >
      <div className = {classes.infoContainer}>
        <ContractsOverviewTable
          handleClick={openContractPage}
          title={<div style={{display:"flex",flexDirection:"row",alignItems:"center"}}><LibraryBooksIcon style={{marginRight:"0.5rem"}}/> Contracts Overview</div>}
          contracts={contracts} getMore={null}
        />
      </div>
    </Section>
  </div>)
}

export default MyContractsPage;