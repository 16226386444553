import XHR_POST from '../scripts/XHR_POST';
import APIRoutes from './APIRoutes';

const UpdatePersonalProfile = async (profile={}, authToken=null) =>{
  if(typeof(profile)!='object')
  {
    throw {
      error: new Error(`Must supply a profile object to UpdatePersonalProfile`),
      response: "",
      status: 400
    }
  }
  if(!authToken)
  {
    throw {
      error: new Error(`Must supply an Auth Token to UpdatePersonalProfile`),
      response: "",
      status: 400,
    };
  }
  else
  {
    return(XHR_POST({
      route: APIRoutes.profile.personal,
      data:profile,
      authToken,
    }).catch(e=>{
      throw {
        error: new Error(`Unable to update profile`),
        response: e.response?e.response:"",
        status: e.status?e.status:500
      };
    }).then(({message, info})=>{
      return({message, info});
    }));
  }
}

export default UpdatePersonalProfile;