import XHR_POST from '../scripts/XHR_POST';
import APIRoutes from './APIRoutes';

const TwoFactorAuthSetupComplete = async (response, authToken=null) =>{
  if(typeof(response)!='string')
  {
    throw {
      error: new Error(`Must supply a Response Code to RemoveTwoFactorAuth`),
      response: "",
      status: 400,
    };
  }
  else if(!authToken)
  {
    throw {
      error: new Error(`Must supply an Auth Token to RemoveTwoFactorAuth`),
      response: "",
      status: 400,
    };
  }
  else
  {
    return(XHR_POST({
      route: APIRoutes.mfa.completeSetup,
      data:{twoFactorResponse: response},
      authToken,
    }).catch(e=>{
      throw {
        error: new Error(`Unable to complete two factor setup`),
        response: e.response?e.response:"",
        status: e.status?e.status:500
      };
    }).then(({message})=>{
      return({message});
    }));
  }
}

export default TwoFactorAuthSetupComplete;