import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import HelpLink from '../help_link/help_link';

const useStyles = makeStyles((theme)=>{
  return({
    PageTitleContainer:{
      display:"flex",
      flexDirection:"row",
      justifyContent: "flex-start",
      alignItems: "center",
      alignContent: "center",
      flexWrap:"nowrap",
      width: "100%",
      
    },
    PageTitleText:{
      display: "inline-block",
      fontSize: "2.5rem",
      width: "auto",
      textAlign:"left",
    },
    PageTitleIcon:{
      display:"inline-block",
      fontSize: "2rem",
      lineHeight: "2.5rem",
      marginLeft:"0.5rem",
      marginRight:"0.5rem"
    },
    PageTitleHelper:{
      marginLeft:"1rem",
      height: "2rem"
    }
  })
});

const PageTitle = (props)=>{
  const {title, icon=null, help_link=null, help_text="Watch Instructional Video"} = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  return(<div style={{width:"100%"}}><div className={classes.PageTitleContainer}>
      {icon?React.cloneElement(icon, {className:classes.PageTitleIcon}):null}
      <Typography variant="h3" className={classes.PageTitleText}>
          {title}
      </Typography>
    </div><div className={classes.PageTitleHelper}>
      {help_link?<HelpLink link={help_link} label={help_text}/>:null}
    </div>
  </div>);
} 

export default PageTitle;