import React, {useState, useEffect, useRef} from 'react';
import { createBrowserHistory } from 'history';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import {Router, Switch, Route, Link, useParams} from 'react-router-dom';
import ThemeProvider from '@material-ui/styles/ThemeProvider/ThemeProvider';
import CssBaseline from '@material-ui/core/CssBaseline';
import AutoSizer from 'react-virtualized-auto-sizer';
import clsx from 'clsx';

import BigNumber from 'bignumber.js';

import setLocalState from './scripts/SetLocalState';
import './App.css';
import useStyles from './app_style';
import Header from './components/header/page_header';
import NavBar from './components/navbar/navbar';
import NavLinks from './components/navbar/navLinks';
import PublicNavLinks from './components/navbar/publicNavLinks';
import TwoFactorChallengeModal from './components/two_factor_challenge_modal/two_factor_challenge_modal';
import ErrorModal from './components/error_modal/error_modal';


import LoginPage from './pages/onboarding/login_page';
import RegisterPage from './pages/onboarding/register_page';
import ForgotPasswordPage from './pages/onboarding/forgot_password_page';
import ProfilePage from './pages/profile/profile_page';

import DashboardPage from './pages/dashboard/dashboard';
import MyContractsPage from './pages/my_contracts/my_contracts_page';
import CreateContractPage from './pages/create_contract/create_contract_page';
import EditContractPage from './pages/edit_contract/edit_contract_page';
import ReviewContractPage from './pages/review_contract/review_contract_page';
import ContractDetailsPage from './pages/contract_details/contract_details';
import MyWalletsPage from './pages/my_wallets/my_wallets_page';
import TransfersReceivedPage from './pages/transfer_activity/transfer_activity';
import BuyTokensPage from './pages/purchase_tokens/purchase_tokens_page';
import InvoicePaymentPage from './pages/invoice_payment/invoice_payment_page';
import LogoutConfirmPage from './pages/logout_confirm/logout_confirm_page';
import FourOhFourPage from './pages/fourohfour/fourohfour_page';
import UpdateReceiverInfoPage from './pages/update_receiver_info/update_receiver_info_page';
import defaultContracts from './default_contracts';

import CangeaAPI from './api/API';
import XHR_GET from './scripts/XHR_GET'; 
import XHR_POST from './scripts/XHR_POST'; 
import ConnectWeb3 from './web3/connectWeb3';
import CangeaWeb3API from './web3/components/web3Container';

import WalletVerificationMethods from './web3/contractMethods/walletVerificationMethods';
import CrowdsaleContractMethods from './web3/contractMethods/crowdsaleContractMethods';
import TokenContractMethods from './web3/contractMethods/tokenContractMethods';
import SplitPaymentContractMethods from './web3/contractMethods/splitPaymentContractMethods';
import PurchaseContract from './web3/scripts/PurchaseContract';
import APIRoutes from './api/APIRoutes';

const BaseTheme = createMuiTheme({
   
},{
  backgroundLight: "#f0f0f7"
});

const BaseApp = (props)=>{
  const classes = useStyles(BaseTheme);
  const {
    loggedIn = true,
    history,
    changePage =(page)=>{
      console.log(`Switching to page ${page}`);
    },
    navOpen,
    setNavOpen,
    username=null,
  } = props;
  return (<Router history={history}><div style={{height:"100vh", display:"flex"}}>
  <CssBaseline />
    <AutoSizer>
      {
        ({height, width})=>{
          return(<div style={{height, width}} className={classes.BaseApp}>
            <ThemeProvider theme={BaseTheme}>
              <Header
                navOpen={navOpen}
                openNav={!loggedIn?null:()=>{setNavOpen(!navOpen);}}
                username={username}
                openProfile={()=>{
                  changePage('/profile');
                }}
              ></Header>
              <NavBar
                open={navOpen}
                setNavOpen = {setNavOpen}
                changePage = {changePage}
                links={loggedIn?NavLinks:PublicNavLinks}
              ></NavBar>
              
              <div className={clsx(classes.content, {[classes.contentShift]:navOpen})}>
                <div className={classes.toolbar}/>
                <div style={{flexGrow:"1", backgroundColor: "#f0f0f7"}}>
                  {props.children}
                </div>
                {/* <Switch>
                <AutoSizer>
                  {({height, width})=>{
                    return();
                  }}
                </AutoSizer></Switch> */}
              </div>
            </ThemeProvider>
          </div>)
        }
      }
    </AutoSizer>
</div></Router>);
}

const history = createBrowserHistory();
function App(props){
  const [Web3, setWeb3] = useState(null);
  const [MetamaskWallet, setMetamaskWallet] = useState(null);
  const [navOpen, setNavOpen] = setLocalState(useState,'navbarExpanded',true);

  const [authToken, setAuthToken] = setLocalState(useState, 'authToken', null);//useState(null);
  const [username, setUsername] = setLocalState(useState, 'username', null);// useState(null);
  const [authExpires, setAuthExpires] = setLocalState(useState, 'authExpires', 0);
  const authRef = useRef(authExpires);
  const loginTimeout = useRef(null);
  
  const [twoFactorOpen, setTwoFactorOpen] = useState(false);
  const [twoFactorEnabled, setTwoFactorEnabled] = setLocalState(useState,'twoFactorEnabled',false);
  const defaultTwoFactorResponseHandler = (response)=>{
    console.log(`Default App Two Factor Response Handler`);
  };
  const [twoFactorResponseHandler, setTwoFactorResponseHandler] = useState(()=>{return(defaultTwoFactorResponseHandler)});
  
  const [personalProfile, setPersonalProfile] = setLocalState(useState, 'personalProfile',{});
  const [businessProfile, setBusinessProfile] = setLocalState(useState, 'businessProfile',{});
  const [isAdmin, setIsAdmin] = setLocalState(useState, 'userIsAdmin', false);
  const [userContracts, setUserContracts] = setLocalState(useState, 'userContracts',[]);
  const [userTransactions, setUserTransactions] = setLocalState(useState, 'userTransactions',[]);
  const [loadedContracts, setLoadedContracts] = setLocalState(useState, 'loadedContracts',{});
  const [userWallets, setUserWallets] = useState([]);
  const [environmentAddresses, setEnvironmentAddresses] = setLocalState(useState, 'envAddresses', {});
  const [videoLinks, setVideoLinks] = setLocalState(useState, 'tutorialLinks',{});
  const [lastLinksUpdate, setLastLinksUpdate] = setLocalState(useState, 'lastTutorialsUpdate',0);
  const [newsPosts, setNewsPosts] = setLocalState(useState, 'newsPosts',[]);
  const [newsPostsLastUpdate, setNewsPostsLastUpdate] = setLocalState(useState, 'newsPostsUpdateTime',0);

  const logout = ()=>{
    console.log(`Logging Out`);
    setAuthToken(()=>{
      return(null);
    });
    setUsername(()=>{
      return(null);
    });
    setAuthExpires(0);
    authRef.current=0;
    setPersonalProfile({});
    setBusinessProfile({});
    setUserContracts([]);
    setUserTransactions([]);
    setLoadedContracts([]);
    setUserWallets([]);
    requireNoAuthPage();
  }

  const [latestErrorText, setLatestErrorText] = useState("");
  const [latestErrorTitle, setLatestErrorTitle] = useState("");
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const DisplayError = ({text="error text", title="Error"})=>{
    setLatestErrorText(text);
    setLatestErrorTitle(title);
    setErrorModalOpen(true);
  };

  const handleGeneralAPIError = async (error)=>{
    if(error && error.status)
    {
      switch(error.status)
      {
        case 401:{
          console.log(`"Unauthorized" error`);
          logout();
          break;
        }
      }
    }
    return;
  }

  function checkLogin(){
    let loggedIn = true;
    if(Date.now() - 120*1000 > authRef.current && authToken)
    {
      logout();
      loggedIn =false;
      alert('Login Expired');
    }
    return(loggedIn);
  }

  useEffect(()=>{
    loginTimeout.current = setTimeout(function loginTimeoutFunction(){
      if(checkLogin())
      {
        loginTimeout.current = setTimeout(loginTimeoutFunction, 30*1000)
      }
    },30*1000);
    return(()=>{
      clearTimeout(loginTimeout.current);
    });
  },[authToken])

  const setupWeb3 = async()=>{
    return(ConnectWeb3().catch(e=>{
      console.error(e);
      return(null);
    }).then(async (results)=>{
      if(results && results.Web3){
        const {Web3, metamaskWallet} = results;
        setWeb3(Web3);
        await Web3.eth.getBlock('latest').then(({number})=>{
          console.log(`Got BNUM ${number.toString()}`);
          return(null);
        });
        setMetamaskWallet(metamaskWallet);
        console.log(`Connected Web3!`);
      }
    }));
  }

  const getVideoLinks = ()=>{
    if(authToken && Date.now() - lastLinksUpdate > 1000*10)
    {
      const asyncGetLinks = async()=>{
        const links = await CangeaAPI.getTutorialLinks(authToken).catch(e=>{
          console.error(e);
          handleGeneralAPIError(e);
          return({});
        });
        setVideoLinks(cur=>{return(links)});
        setLastLinksUpdate(Date.now());
      };
      asyncGetLinks();
    }
    const thisTimeout = setTimeout(()=>{
      setLastLinksUpdate(0);
    }, 10*60*1000)
    return(()=>{
      clearTimeout(thisTimeout);
      setLastLinksUpdate(0);
    });
  }
  useEffect(getVideoLinks, [authToken]);

  const getNewsPosts = ()=>{
    if(Date.now() - newsPostsLastUpdate > 1000*60*60 || newsPosts.length === 0)
    {
      const asyncGetPosts = async()=>{
        const posts = await CangeaAPI.getNewsPosts().catch(e=>{
          console.error(e);
          handleGeneralAPIError(e);
          return([]);
        });
        setNewsPosts(cur=>{return(posts)});
        setNewsPostsLastUpdate(Date.now());
      };
      asyncGetPosts();
    }
    const thisTimeout = setTimeout(()=>{
      setNewsPostsLastUpdate(0);
    }, 10*60*1000)
    return(()=>{
      clearTimeout(thisTimeout);
    });
  }
  useEffect(getNewsPosts, []);

  const updatePageAfterLogin=()=>{
    if(lastLoggedOutMissingPath.current)
    {
      const lastLoggedOutUrl = lastLoggedOutMissingPath.current;
      lastLoggedOutMissingPath.current=null;
      const nextPath = `${lastLoggedOutUrl.pathname}${lastLoggedOutUrl.hash.length>1?lastLoggedOutUrl.hash:""}${lastLoggedOutUrl.search}`;
      console.log(nextPath);
      history.push(nextPath);
    }
    else
    {
      history.push('/dashboard');
    }
  }
  const submitLogin = async (username, password, rememberMe)=>{
    const apiResponse = await CangeaAPI.auth.login(username, password, rememberMe).catch(e=>{
      console.error(e);
      return({authToken:null})
    });
    if(apiResponse.authToken)
    {
      setUsername(()=>{
        return(username);
      });
      setAuthToken(apiResponse.authToken);
      //setAuthExpires(apiResponse.authExpires);
      setAuthExpires(apiResponse.authExpires);
      authRef.current=apiResponse.authExpires;
      updatePageAfterLogin();
    }
    else if(apiResponse.twoFactorRequired)
    {
      const twoFactorLoginResponseHandler = (response)=>{
        CangeaAPI.auth.twoFactorLogin(response, apiResponse.token).catch(e=>{
          console.error(e);
          alert("Two Factor Response Invalid");
          return({authToken:null});
        }).then((mfaResponse)=>{
          if(mfaResponse.authToken)
          {
            setUsername(()=>{
              return(mfaResponse.username);
            });
            setAuthToken(mfaResponse.authToken);
            //setAuthExpires(mfaResponse.authExpires);
            authRef.current = mfaResponse.authExpires;
            setAuthExpires(mfaResponse.authExpires);
            setTwoFactorOpen(false);
            setTwoFactorResponseHandler(()=>{return(defaultTwoFactorResponseHandler)});
            updatePageAfterLogin();
          }
        });
      }
      setTwoFactorOpen(true);
      setTwoFactorResponseHandler(()=>{return(twoFactorLoginResponseHandler)});
    }
    else
    {
      alert(`Login Failed`);
    }
  }

  const submitRegistration = async (username, password, email)=>{
    const apiResponse = await CangeaAPI.register(username, password, email).catch(e=>{
      console.error(e);
      alert(e.error?e.error.toString():e.toString());
      return(null)
    });
    if(apiResponse)
    {
      if(apiResponse.verifyEmail && apiResponse.signup_link)
      {
        let link= new URL(apiResponse.signup_link);
        let modLink = link.pathname + link.search;
        XHR_POST({
          data:{},
          route:modLink,
          setCSRF: false,
        }).catch(e=>{
          console.error(e);
          return(null);
        }).then(r=>{
          if(r)
          {
            alert(`Successfully created account! Please Login to access your account`);
            history.push('/login');
          }
        })
      }
      else alert(`Successfully created account! Complete Registration by confirming your email via the link sent to the email address you provided.`);
    }
  };

  const [twoFactorSetupCode, setTwoFactorSetupCode] = useState(null);
  const startTwoFactorSetup = ()=>{
    if(!authToken)
    {
      alert("Must be logged in");
    }
    else
    {
      CangeaAPI.mfa.setup(authToken).catch(e=>{
        handleGeneralAPIError(e);
        DisplayError({text:`${e.toString()}`, title: "MFA Setup Error"});
        console.error(e);
        return(null);
      }).then(response=>{
        if(response)
        {
          const {message, data, image} = response;
          setTwoFactorSetupCode(data);
        }
      });
    }
  };

  const completeTwoFactorSetup = (response)=>{
    if(!authToken)
    {
      alert("Must be logged in");
    }
    else
    {
      CangeaAPI.mfa.complete(response, authToken).catch(e=>{
        DisplayError({text:`${e.toString()}`, title: "MFA Setup Error"});
        handleGeneralAPIError(e);
        console.error(e);
        return(null);
      }).then(response=>{
        if(response)
        {
          setTwoFactorSetupCode(null);
        }
      });
    }
  };

  const removeTwoFactor = ()=>{
    if(!authToken)
    {
      alert("Must be logged in");
    }
    else
    {
      const twoFactorRemovalResponseHandler = (response)=>{
        CangeaAPI.mfa.remove(response, authToken).catch(e=>{
          DisplayError({text:`${e.toString()}`, title: "MFA Removal Error"});
          handleGeneralAPIError(e);
          console.error(e);
          return(null);
        }).then(response=>{
          if(response)
          {
            setTwoFactorEnabled(false);
            setTwoFactorOpen(false);
            setTwoFactorResponseHandler(()=>{return(defaultTwoFactorResponseHandler)});
          }
        });
      }
      setTwoFactorOpen(true);
      setTwoFactorResponseHandler(()=>{return(twoFactorRemovalResponseHandler)});
    }
  };

  const refreshProfile = async ()=>{
    if(!authToken)
    {
      console.error("Not Logged In");
    }
    else
    {
      const userInfo = await CangeaAPI.getUserInfo(authToken,{
        getBusiness:true,
        getPersonal:true,
        getRoles:true,
        getTwoFactorStatus: true,
      }).catch(e=>{
        handleGeneralAPIError(e);
        console.error(e);
        return({business:{},personal:{},roles:{}, twoFactor:{}});
      });
      setBusinessProfile(userInfo.business);
      setPersonalProfile(userInfo.personal);
      setIsAdmin(userInfo.roles.administrator?true:false);
      setTwoFactorEnabled(userInfo.twoFactor&&userInfo.twoFactor.status);
    }
  }

  const refreshContracts = async ()=>{
    if(!authToken)
    {
      console.error("Not Logged In");
    }
    else
    {
      const contracts = await CangeaAPI.getUserContracts(authToken)
      .catch(e=>{
        handleGeneralAPIError(e);
        console.error(e);
        return([]);
      });
      setLoadedContracts(currentContracts=>{
        contracts.forEach(contract=>{
          if(contract.deployed)
          {
            currentContracts[contract.deployedAddress] = contract;
          }
          currentContracts[contract.index] = contract;
        });
        return({...currentContracts});
      });
      setUserContracts(contracts);
    }
  }

  const refreshEnvAddresses = async()=>{
    if(!authToken)
    {
      console.error("Not Logged In");
    }
    else
    {
      const contracts = await CangeaAPI.getEnvironmentAddresses(authToken)
      .catch(e=>{
        handleGeneralAPIError(e);
        console.error(e);
        return({});
      });
      setEnvironmentAddresses(contracts);
    }
  }

  const refreshTransactions = async ()=>{
    if(!authToken)
    {
      console.error("Not Logged In");
    }
    else
    {
      const txs = await CangeaAPI.getUserTransactions(authToken)
      .catch(e=>{
        handleGeneralAPIError(e);
        console.error(e);
        return([]);
      });
      setUserTransactions(txs);
    }
  }
  const refreshWallets = async ()=>{
    if(!authToken)
    {
      console.error("Not Logged In");
    }
    else
    {
      const wallets = await CangeaAPI.getUserWallets(authToken)
      .catch(e=>{
        handleGeneralAPIError(e);
        console.error(e);
        return([]);
      });
      setUserWallets(wallets);
    }
  }

  const showLogin = ()=>{
    history.push(`/login`);
  }
  const showRegistration = ()=>{
    history.push(`/register`);
  }
  const showForgotPassword = ()=>{
    history.push(`/forgot-password`);
  }

  const changePage =(page)=>{
    if(page=='/logout')
    {
      logout();
    }
    history.push(page);
    //setNavOpen(false);
  }

  let loggedIn = (authToken&&username)?true:false;
  const lastLoggedOutMissingPath = useRef(null);
  const pageIsNoAuth = (href)=>{
    const currentUrl = new URL(href);
      switch(currentUrl.pathname.toLowerCase())
      {
        case '/login':
        case '/register':
        case '/invoices/view':
        case '/contracts/updatereceiver':
        {
          return(true);
        }
        default:
        {
          return(false);
        }
      }
  }

  const requireNoAuthPage = ()=>{
    const currentPage = window.location.href;
    if(!pageIsNoAuth(currentPage))
    {
      if(new URL(currentPage).pathname!='/logout')
      {
        lastLoggedOutMissingPath.current = new URL(currentPage);
      }
      history.push('/login');
    }
  }

  useEffect(()=>{
    if(authToken && username)
    {
      refreshProfile();
      refreshContracts();
      refreshTransactions();
      refreshWallets();
      refreshEnvAddresses();
      setupWeb3();
      
    }
    else
    {
      requireNoAuthPage();
    }
  }, [username,authToken]);

  const openContractPage = (event, index)=>{
    if(index){
      if(loadedContracts[index] && !loadedContracts[index].deployed)
      {
        if(loadedContracts[index].generated)
        {
          history.push(`/contracts/review?index=${index}`);
        }
        else
        {
          history.push(`/contracts/edit?index=${index}`);
        }
      }
      else
      {
        history.push(`/contracts/details?index=${index}`);
      }
      
    }
    else
    {
      history.push('/contracts/create');
    }
  }
  const handleContractsError=async (e)=>{
    const response = e.response?JSON.parse(e.response):{};
    const errorLocations = response.errors?Object.keys(response.errors):[];
    let errorString = `Errors: `;
    errorLocations.forEach(loc=>{
      if(loc!='receivers')
      {
        errorString += `In ${loc} field, ${response.errors[loc]}, `;
      }
    });

    DisplayError({text:`${errorString}`, title: "Contract Creation Error"});
    handleGeneralAPIError(e);
    return(null);
  }
  const createContract = (contractInfo)=>{
    let receivers = [];
    if(contractInfo.receivers)
    {
      receivers = contractInfo.receivers.map((r)=>{
        return({
          ...r,
          percent: parseFloat(r.percent)
        });
      });
    }
    CangeaAPI.contracts.create({...contractInfo, receivers}, authToken)
    .catch(handleContractsError).then(createdContract=>{
      if(createdContract)
      {
        console.log(createdContract);
        DisplayError({title:"Success!",text:`Created Contract ${createdContract.name}!`});
        refreshContracts();
        history.push(`/contracts/edit?index=${createdContract.index}`);
      }
    });
  };
  const editContract = (contractInfo)=>{
    const receivers = contractInfo.receivers.map((r)=>{
      return({
        ...r,
        percent: parseFloat(r.percent)
      });
    });
    CangeaAPI.contracts.edit({...contractInfo, receivers}, authToken)
    .catch(handleContractsError).then(editedContract=>{
      if(editedContract)
      {
        console.log(editedContract);
        refreshContracts();
      }
    });
  };

  const updateBusinessProfile = async (updatedProfile={}, progressFn=()=>{})=>{
    progressFn(0);
    return(CangeaAPI.profile.updateBusiness(updatedProfile, authToken).catch(e=>{
      console.error(e);
      DisplayError({text:`Could not update business profile: \n${JSON.stringify(e.response)}`, title: "Business Profile Update Error"});
      handleGeneralAPIError(e);
      progressFn(-1);
      return(null);
    }).then(result=>{
      {progressFn(1);
      refreshProfile();}
      return(result);
    }));
  }

  const updatePersonalProfile = async (updatedProfile={}, progressFn=()=>{})=>{
    progressFn(0);
    return(CangeaAPI.profile.updatePersonal(updatedProfile, authToken).catch(e=>{
      console.error(e);
      alert(`Could not update personal profile: \n${JSON.stringify(e.response)}`);
      handleGeneralAPIError(e);
      progressFn(-1);
      return(null);
    }).then(result=>{
      if(result)
      {progressFn(1);
      refreshProfile();}
      return(result);
    }));
  }
  
  const changePassword = async (passwordUpdateInfo={}, progressFn=()=>{})=>{
    progressFn(0);
    return(CangeaAPI.updatePassword(passwordUpdateInfo, authToken).catch(e=>{
      console.error(e);
      alert(`Could not update password: \n${JSON.stringify(e)}`);
      handleGeneralAPIError(e);
      progressFn(-1);
      return(null);
    }).then((result)=>{
      if(result)
      { 
        progressFn(1);
        const {authToken:newAuthToken, authExpires:newAuthExpires} = result;
        setAuthToken(newAuthToken);
        setAuthExpires(newAuthExpires);
        authRef.current = newAuthExpires;
        alert(`Succesfully Updated Password!`);
      }
      return(result);
    }));
  }

  const PurchaseTokensWithEth = async (numEth, progressFn)=>{
    if(!Web3)
    {
      alert(`Web3 not connected!\nTry the MetaMask extension to connect Web3 in the browser`);
    }
    else if(typeof(environmentAddresses.crowdsale)!='string')
    {
      alert('Crowdsale Address Unavailable')
    }
    else
    {
      CrowdsaleContractMethods.purchaseTokens(
        Web3,
        environmentAddresses.crowdsale,
        numEth,
        {
          from: MetamaskWallet
        },
        null,
        progressFn
      ).catch(e=>{
        console.error(e);
        alert(JSON.stringify(e));
        return(null);
      }).then(tx=>{
        if(tx)
        {
          alert(`Sent Token Purchase Tx. You will receive your tokens once the transaction is confirmed. Be sure to add ${environmentAddresses.cangea} to your wallet's tracked tokens`);
        }
      });
    }
  }

  const TransferCangeaTokens = async ({numTokens=0,to="",progressFn=()=>{}})=>{
    if(!Web3)
    {
      alert(`Web3 not connected!\nTry the MetaMask extension to connect Web3 in the browser`);
    }
    else if(typeof(environmentAddresses.cangea)!='string')
    {
      alert('Cangea Token Address Unavailable')
    }
    else
    {
      return(TokenContractMethods.transfer(
        Web3,
        environmentAddresses.cangea,
        to,
        numTokens,
        {
          from: MetamaskWallet
        },
        null,
        progressFn
      ).catch(e=>{
        console.error(e);
        alert(JSON.stringify(e));
        return(null);
      }).then(tx=>{
        // if(tx)
        // {
        //   alert(`Sent Token Transfer Tx!\nHash: ${tx.transactionHash}`);
        // }
        return(tx);
      }));
    }
  }

  const FreezeContract = async({
    address,
    progressFn=()=>{}
  })=>{
    if(!Web3)
    {
      alert(`Web3 not connected!\nTry the MetaMask extension to connect Web3 in the browser`);
    }
    else
    {
      return(SplitPaymentContractMethods.freeze(Web3, address, {
        from: MetamaskWallet
      }, null, progressFn).catch(e=>{
        console.error(e);
        alert(JSON.stringify(e));
        return(null);
      }).then(tx=>{
        return(tx);
      }));
    }
  }

  const ThawContract = async({
    address,
    progressFn=()=>{}
  })=>{
    if(!Web3)
    {
      alert(`Web3 not connected!\nTry the MetaMask extension to connect Web3 in the browser`);
    }
    else
    {
      return(SplitPaymentContractMethods.thaw(Web3, address, {
        from: MetamaskWallet
      }, null, progressFn).catch(e=>{
        console.error(e);
        alert(JSON.stringify(e));
        return(null);
      }).then(tx=>{
        return(tx);
      }));
    }
  }

  const ArchiveContract = async({
    address,
    progressFn=()=>{}
  })=>{
    if(!Web3)
    {
      alert(`Web3 not connected!\nTry the MetaMask extension to connect Web3 in the browser`);
    }
    else
    {
      return(SplitPaymentContractMethods.archive(Web3, address, {
        from: MetamaskWallet
      }, null, progressFn).catch(e=>{
        console.error(e);
        alert(JSON.stringify(e));
        return(null);
      }).then(tx=>{
        return(tx);
      }));
    }
  }

  const UpdateWallet = ({address, name=null, setDefault=false})=>{
    if(!authToken)
    {
      alert("Must be logged in");
    }
    else
    {
      if(!name)
      {
        name = `Unknown Wallet ${address}`
      }
      CangeaAPI.wallets.update({address,name, setDefault}, authToken).catch(e=>{
        handleGeneralAPIError(e);
        return(null)
      }).then((result)=>{
        if(result)
        {
          const {message,wallets, defaultWallet} = result;
          setUserWallets(wallets);
        }
      });
    }
  }

  const DeleteWallet = ({address, name=null})=>{
    if(!authToken)
    {
      alert("Must be logged in");
    }
    else
    {
      if(!name)
      {
        name = `Unknown Wallet ${address}`
      }
      CangeaAPI.wallets.remove({address,name}, authToken).catch(e=>{
        handleGeneralAPIError(e);
        return(null)
      }).then(removed=>{
        if(removed)
        {
          refreshWallets();
        }
      });
    }
  }

  const sendInvoiceRequest = async (requestFrom, amount, sendTo, note)=>{
    if(!authToken)
    {
      alert("Must be logged in");
      return(null);
    }
    else
    {
      return(CangeaAPI.sendInvoiceRequest({
        authToken,
        recipient:sendTo,
        email: requestFrom,
        amount,
        note
      }).catch(e=>{
        handleGeneralAPIError(e);
        return(null)
      }).then(response=>{
        if(response)
        {
          alert(`Sent Invoice Request`);
        }
        return(response);
      }));
    }
  }

  const [crowdsaleTransfers, setCrowdsaleTransfers] = setLocalState(useState, 'crowdsaleTransfers',[]);
  const [lastGotCrowdsaleTransfers, setLastGotCrowdsaleTransfers] = setLocalState(useState,'lastGotCrowdsaleTransfers',0);
  const lastCrowsaleTransferCallRef  = useRef(lastGotCrowdsaleTransfers);
  const performingCrowsaleTransfersCall = useRef(false);
  const rendered =  useRef(false);
  function RoutedBuyTokensPage(props)
  {
   
    useEffect(()=>{
      rendered.current= true;
      if(Date.now() - 12*1000 > lastCrowsaleTransferCallRef.current && !performingCrowsaleTransfersCall.current && environmentAddresses.crowdsale)
      {
        performingCrowsaleTransfersCall.current = true;
        console.log(lastCrowsaleTransferCallRef.current);
        console.log(performingCrowsaleTransfersCall.current.toString());
        CangeaAPI.getTransactionsByAddress(environmentAddresses.crowdsale, authToken, {getSent:false, getReceived:true}).catch(e=>{
          alert(e.toString());
          handleGeneralAPIError(e);
          console.error(e);
          return({sent:[], received:[]});
        }).then(({sent, received})=>{
          performingCrowsaleTransfersCall.current = false;
          lastCrowsaleTransferCallRef.current = Date.now();
          if(rendered.current)
          {
            setCrowdsaleTransfers(received);
            setLastGotCrowdsaleTransfers(Date.now());
          }
        });
      }
      return(()=>{
        rendered.current = false;
      });
    },[environmentAddresses]);
    return(<BuyTokensPage
      purchaseTokensFunction={Web3?PurchaseTokensWithEth:null}
      crowdsaleTransfers = {crowdsaleTransfers}
    />)
  }

  function RoutedContractDetailsPage(props)
  {
    const parms = new URLSearchParams(window.location.search);
    const index = parms.get('index');
    const address = parms.get('address');
    let displayContract = {};
    if(index && loadedContracts[index])
    {
      displayContract = loadedContracts[index];
    }
    else if(address && loadedContracts[address])
    {
      displayContract = loadedContracts[address];
    }
    displayContract.address = displayContract.deployedAddress;
    const [transfers, setTransfers] = useState([]);
    const lastGotTransfers = useRef(0);
    const gettingTransfers = useRef(false);
    useEffect(()=>{
      if(Date.now() - 12*1000 > lastGotTransfers.current && ! gettingTransfers.current && displayContract.deployedAddress!==undefined)
      {
        gettingTransfers.current = true;
        CangeaAPI.getTransactionsByAddress(displayContract.deployedAddress, authToken, {getSent:false, getReceived:true}).catch(e=>{
          alert(e.toString());
          handleGeneralAPIError(e);
          console.error(e);
          return({sent:[], received:[]});
        }).then(({sent, received})=>{
          gettingTransfers.current = false;
          setTransfers(received);
          lastGotTransfers.current=Date.now();
        });
      }
    },[]);
    return(<ContractDetailsPage
      {...displayContract} status={undefined}
      transfers={transfers}
      sendInvoiceRequest={sendInvoiceRequest}
      canManage={
        Web3
        &&typeof(MetamaskWallet)=='string'
        &&displayContract&&typeof(displayContract.management)=='string'
        &&MetamaskWallet.toLowerCase()==displayContract.management.toLowerCase()}
      archiveContract={async (opts)=>{
        const {progressFn=()=>{}} = opts;
        return(ArchiveContract({
          address: displayContract.deployedAddress,
          progressFn
        }));
      }}
      freezeContract={async (opts)=>{
        const {progressFn=()=>{}} = opts;
        return(FreezeContract({
          address: displayContract.deployedAddress,
          progressFn
        }));
      }}
      thawContract={async (opts)=>{
        const {progressFn=()=>{}} = opts;
        return(ThawContract({
          address: displayContract.deployedAddress,
          progressFn
        }));
      }}
    />)
  }

  function RoutedContractReviewPage({contracts, wallets})
  {
    const parms = new URLSearchParams(window.location.search);
    const index = parms.get('index');
    let displayContract = {};
    if(index && loadedContracts[index])
    {
      displayContract = loadedContracts[index];
    }
    let meta = {
      name: displayContract.name,
      index: displayContract.index,
      management: displayContract.management,
      primaryEmail: displayContract.primaryEmail,
      description:displayContract.description,
      activeOnly:displayContract.activeOnly,
      erc223:displayContract.erc223,
      receivable:displayContract.receivable
    };
    return(<ReviewContractPage
      meta = {meta}
      receivers={displayContract.receivers}
      contracts = {contracts}
      wallets = {wallets}
      metaMaskAddress = {MetamaskWallet}
      editContract = {()=>{
        history.push(`/contracts/edit?index=${index}`);
      }}
      purchaseContract = {()=>{
        if(!Web3)
        {
          alert(`This feature requires a MetaMask connection`);
        }
        else if(!MetamaskWallet)
        {
          alert(`This feature requires your MetaMask address be visible`);
        }
        else
        {
          const cost = new BigNumber(displayContract.estimatedCNG).times(1.05).toString();
          alert(`Purchasing Contract ${displayContract.name}.\nHash: ${displayContract.codeHash}\nCost: ${new BigNumber(cost).div(1e18)} CNG\nFrom: ${MetamaskWallet}.\n\nNote that transaction times vary and can take up to 30 seconds before confirmation.`);
          const increaseAllowance = window.confirm(`Increase Purchasing Contract Allowance by ${cost} tokens first? You will need to approve an extra transaction.`);
          PurchaseContract(
            Web3,
            environmentAddresses.cangea,
            environmentAddresses.purchases,
            displayContract.codeHash,
            cost,
            {
              from: MetamaskWallet,
              increaseAllowance
            },
            null
          ).catch(e=>{
            alert(e.toString());
            return(null);
          }).then(tx=>{
            if(tx)
            {
              alert(`Sent Purchase Tx. Please wait while the system prepares your new contract. You will receive an email notification when the contract is deployed.\nYou may now navigate away from this page.`);
            }
          })
        }
      }}
      deployContract={()=>{
        CangeaAPI.contracts.deploy({...displayContract}, authToken).catch(e=>{
          handleGeneralAPIError(e);
          console.error(e);
          alert(e.toString());
          return(null);
        }).then(deployed=>{
          if(deployed)
          {
            refreshContracts();
            history.push(`/contracts/details?index=${displayContract.index}`);
          }
        })
      }}
      isAdmin = {isAdmin}
    />)
  }

  function RoutedContractEditPage({contracts, wallets})
  {
    const parms = new URLSearchParams(window.location.search);
    const index = parms.get('index');
    let displayContract = {};
    if(index && loadedContracts[index])
    {
      displayContract = loadedContracts[index];
    }
    let startingMeta = {
      name: displayContract.name,
      index: displayContract.index,
      management: displayContract.management,
      primaryEmail: displayContract.primaryEmail,
      description:displayContract.description,
      activeOnly:displayContract.activeOnly,
      erc223:displayContract.erc223,
      receivable:displayContract.receivable
    };
    return(<EditContractPage
      startingMeta = {startingMeta}
      startingReceivers={displayContract.receivers}
      contracts = {userContracts}
      wallets = {userWallets}
      metaMaskAddress = {MetamaskWallet}
      saveChanges={editContract}
      deleteContract={()=>{
        CangeaAPI.contracts.delete({
          ...displayContract
        }, authToken).catch(e=>{
          handleGeneralAPIError(e);
          return(null)
        }).then(confirmedDelete=>{
          if(confirmedDelete)
          {
            refreshContracts();
            history.push('/contracts/my-contracts')
          }
        });
      }}
      compileContract = {()=>{
        CangeaAPI.contracts.generate({
          ...displayContract
        }, authToken).catch(e=>{
          handleGeneralAPIError(e);
          return(null)
        }).then(generatedContract=>{
          if(generatedContract)
          {
            refreshContracts();
            history.push(`/contracts/review?index=${generatedContract.index}`);
          }
        });
      }}
      lookupUser = {async (query)=>{
        return(CangeaAPI.lookupUser(query, authToken));
      }}
      copyContract={createContract}
    />)
  }

  function RoutedInvoiceDetailsPage({contracts, wallets, metamaskAddress})
  {
    const prms = new URLSearchParams(window.location.search);
    const amount = prms.get('amount');
    const address = prms.get('address');
    const acceptsEth = prms.get('receivable');
    const acceptedTokens = [
      environmentAddresses.cangea
    ];
    return(<InvoicePaymentPage
      Web3={Web3}
      address = {address}
      amount = {amount}
      acceptsEth = {acceptsEth}
      acceptedTokens = {acceptedTokens}
      contracts = {userContracts}
      wallets = {userWallets}
      metamaskAddress = {MetamaskWallet}
      transferCNG = {TransferCangeaTokens}
    />)
  }

  function RoutedUpdateRecipientDetailsPage(props)
  {
    const prms = new URLSearchParams(window.location.search);
    const token = prms.get('token');
    const updateRecipientInfo = async(info)=>{
      return(CangeaAPI.contracts.updateRecipientInfo(info, token).catch(e=>{
        console.error(e);
        alert(JSON.stringify(e));
      }));
    }

    return(<UpdateReceiverInfoPage
      contracts = {userContracts}
      wallets = {userWallets}
      metaMaskAddress = {MetamaskWallet}
      updateInfo={updateRecipientInfo}
    />)
  }

  function RegardlessOfLoginRoutes(){
    return(<React.Fragment>
      <Route path="/invoices/view">
        <RoutedInvoiceDetailsPage/>
      </Route>
      <Route path='/contracts/updateReceiver' sensitive={false}>
        <RoutedUpdateRecipientDetailsPage/>
      </Route>
    </React.Fragment>)
  }
  return(<BaseApp 
    loggedIn = {loggedIn}//
    history={history}
    changePage={changePage}
    navOpen = {navOpen && loggedIn}
    setNavOpen = {setNavOpen}
    username={username}
  >
    {!loggedIn?
    <Switch>
      <Route exact path="/">
        <LoginPage
          submitLogin = {submitLogin}
          showForgotPassword = {showForgotPassword}
          showRegistration = {showRegistration}
        />
      </Route>
      <Route exact path="/login">
        <LoginPage
          submitLogin = {submitLogin}
          showForgotPassword = {showForgotPassword}
          showRegistration = {showRegistration}
        />
      </Route>
      <Route exact path="/register">
        <RegisterPage
          showLogin = {showLogin}
          submitRegistration = {submitRegistration}
        />
      </Route>
      <Route exact path="/forgot-password">
        <ForgotPasswordPage
          showLogin = {showLogin}
        />
      </Route>
      {RegardlessOfLoginRoutes()}
    </Switch>
    :
    <Switch>
    <Route exact path="/">
      <DashboardPage
        video_link={videoLinks.dashboard}
        newsPosts = {newsPosts}
        wallets={userWallets}
        metaMaskAddress={MetamaskWallet}
        createContract={createContract}
        requestFunds={sendInvoiceRequest}
      />
    </Route>
    <Route exact path="/dashboard">
      <DashboardPage
        video_link={videoLinks.dashboard}
        newsPosts = {newsPosts}
        wallets={userWallets}
        contracts ={userContracts}
        metaMaskAddress={MetamaskWallet}
        createContract={createContract}
        requestFunds={sendInvoiceRequest}
      />
    </Route>
    <Route path="/contracts/my-contracts">
      <MyContractsPage
        openContractPage= {openContractPage}
        contracts = {userContracts}
      />
    </Route>
    <Route path="/contracts/details">
      <RoutedContractDetailsPage/>
    </Route>
    <Route exact path="/contracts/create">
      <CreateContractPage
        contracts={userContracts}
        wallets={userWallets}
        createContract = {createContract}
      />
    </Route>
    <Route path="/contracts/edit">
      <RoutedContractEditPage
        contracts={userContracts}
        wallets={userWallets}
      />
    </Route>
    <Route path="/contracts/review">
      <RoutedContractReviewPage
        contracts={userContracts}
        wallets={userWallets}
        />
    </Route>
    <Route path="/wallets">
      <MyWalletsPage
        wallets={userWallets}
        deleteWallet = {DeleteWallet}
        updateWallet = {UpdateWallet}
        verifyWallet = { Web3? async (address, code, progressFn)=>{
          if(!Web3)
          {
            alert(`Cannot Verify Wallet without Web3 Connection`);
          }
          else
          {
            address = Web3.utils.toChecksumAddress(address);
            WalletVerificationMethods.verifyWallet(Web3, environmentAddresses.verifications, code, {
              from: address.toLowerCase(),
            },null, progressFn, async (returnVal)=>{
              if(!returnVal)
              {
                alert(`Verification check failed for ${address} with code ${code}`);
                return(false);
              }
              else
              {
                alert(`Sending Verification Request TX`);
                return(true);
              }
            }).then(txReceipt=>{
              if(txReceipt){alert(`Sent Verification Request TX`);}
            }).catch(err=>{
              console.error(err);
            });
          }
          
        }:null}
      />
    </Route>
    <Route path="/transfers">
      <TransfersReceivedPage
        transfers={userTransactions}
      />
    </Route>
    <Route path="/purchase">
      <RoutedBuyTokensPage></RoutedBuyTokensPage>
    </Route>
    <Route path="/profile">
      <ProfilePage
        username={username}
        email={personalProfile.email}
        personal={personalProfile}
        business={businessProfile}
        twoFactorEnabled = {twoFactorEnabled}
        twoFactorSetupCode={twoFactorSetupCode}
        setupTwoFactor={startTwoFactorSetup}
        completeTwoFactorSetup={completeTwoFactorSetup}
        removeTwoFactorStart={removeTwoFactor}
        updateBusinessProfile={updateBusinessProfile}
        updatePersonalProfile={updatePersonalProfile}
        updatePassword= {changePassword}
      />
    </Route>
    {RegardlessOfLoginRoutes()}
    <Route path="/logout">
      <LogoutConfirmPage/>
    </Route>
    <Route>
      <FourOhFourPage
      />
    </Route></Switch>}
    <TwoFactorChallengeModal
      isOpen={twoFactorOpen}
      submitResponse={twoFactorResponseHandler}
      handleClose={()=>{setTwoFactorOpen(false);}}
    />
    <ErrorModal
      open={errorModalOpen}
      errorText={latestErrorText}
      errorTitle={latestErrorTitle}
      setOpen={setErrorModalOpen}
    />
  </BaseApp>);
}



export default App;