import React, {useState, useEffect} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import FaceIcon from '@material-ui/icons/Face';
import Section from '../section/section';
import StyledInput from '../styled_input/styled_input';
import StyledCheckbox from '../styled_checkbox/styled_checkbox';

const useStyles = makeStyles((theme)=>{
  return({
    formSubmitContainer:{
      flexBasis:"100%",
      flexShrink:"0",
      textAlign:"center",
      marginBottom:"1rem",
      '& > *':{
        margin:"1rem",
      }
    },
    formInput:{
      flexBasis:"48%",
      flexGrow:"0"
    }
  });
});

const ProfilePersonalSection = (props={})=>
{
  const theme = useTheme();
  const classes = useStyles(theme);
  const {
    className = "",
    personal = {},
    updatePersonalProfile = (updatedProfile, buttonUpdateFn)=>{console.log(`Default Section Update Personal Profile: ${JSON.stringify(updatedProfile)}`);}
  } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [first, setFirstName] = useState(personal.first||"");
  const [last, setLastName] = useState(personal.last||"");
  const [email, setEmail] = useState(personal.email||"");
  const [displayName, setDisplayName] = useState(personal.displayName||"");
  const [discoverable, setDiscoverable] = useState(personal.discoverable||false);

  const checkIfEdited = ()=>{
    let edited = false;
    if(first != (personal.first||"") ||
      last != (personal.last || "") ||
      email != (personal.email || "") ||
      displayName != (personal.displayName ||"") ||
      discoverable != (personal.discoverable || false)
    )
    {
      edited = true;
    }
    setIsEdited(edited);
  }
  useEffect(checkIfEdited,[first, last, email, displayName, discoverable]);

  const cancelChanges = ()=>{
    setFirstName(personal.first||"");
    setLastName(personal.last||"");
    setEmail(personal.email||"");
    setDisplayName(personal.displayName||"");
    setDiscoverable(personal.discoverable||false);
    setIsEdited(false);
  }

  const submitChanges=()=>{
    updatePersonalProfile({
      first: first||undefined,
      last: last || undefined,
      email: email || undefined,
      displayName: displayName || undefined,
      discoverable: discoverable
    },()=>{
      console.log(`Personal Profile Update Progression Default Notice`);
    }).then(result=>{
      if(result)
      {
        setIsEditing(false);
      }
    })
  }

  return(<Section
    title="Personal Information"
    icon={<FaceIcon/>}
  ><div className={className}>
    <StyledInput
      label="First Name"
      disabled={!isEditing}
      className={classes.formInput}
      value = {first}
      updateForm ={setFirstName}
    />
    <StyledInput
      label="Last Name"
      disabled={!isEditing}
      className={classes.formInput}
      value = {last}
      updateForm ={setLastName}
    />
    <StyledInput
      label="Public Contact Email"
      disabled={!isEditing}
      className={classes.formInput}
      value = {email}
      updateForm ={setEmail}
    />
    <StyledInput
      label="Display Name"
      disabled={!isEditing}
      className={classes.formInput}
      value = {displayName}
      updateForm ={setDisplayName}
    /><div className={classes.formSubmitContainer}>
    <StyledCheckbox
      checked={discoverable}
      disabled={!isEditing}
      className={classes.formInput}
      label = "Discoverable"
      style={{marginLeft:".5rem"}}
      updateForm ={setDiscoverable}
    /></div>
    <div className={classes.formSubmitContainer}>
    {isEditing?null:<Button 
        variant="outlined"
        color={isEditing?"secondary":"primary"}
        onClick={()=>{console.log(`Clicked Component Personal Profile Edit`)
          setIsEditing(true);
        }
      }
      >Edit Profile</Button>}
      {isEditing?<Button
        variant="outlined"
        color="secondary"
        onClick={()=>{console.log(`Clicked Component Personal Profile Cancel`)
          cancelChanges();
          setIsEditing(false);
        }}
      >Cancel Changes</Button>:null}
      {isEditing&&isEdited?<Button 
        variant="contained"
        color="primary"
        onClick={()=>{console.log("Clicked Component Personal Profile Submit"); submitChanges();}}
      >Submit Changes</Button>:null}
    </div>
  </div></Section>)
}

export default ProfilePersonalSection;