import React, {useEffect, useState, useRef} from 'react';

//Material UI
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import {makeStyles, useTheme} from '@material-ui/core/styles';

import StyledInput from '../styled_input/styled_input';
import WalletsDropdown from '../wallets_dropdown/wallets_dropdown';

const useStyles = makeStyles((theme)=>{
  return({
    inputsContainer:{
      display: "flex",
      flexDirection:"column",
      justifyContent:"flex-start",
      alignContent:"center",
      alignItems:"center"
    },
    formSubmitContainer:{
      flexShrink:"0",
      textAlign:"center",
      '& > *':{
        margin:"1rem",
      }
    },
    modal:{
      display: 'flex',
      flexDirection: "column",
      justifyContent:"stretch",
      height: "auto",
      width: "40rem",
      top:"calc(30%)",
      left:"calc(50% - 20rem)",
      padding:"1.5rem",
      '@media (max-width:700px)':{
        width: "calc(100% - 2rem)",
        left: "1rem"
      }
    },
    paper: {
      position: 'absolute',
      backgroundColor: "white",
      border: '2px solid #000',
      borderRadius:".5rem",
      outline:"none",
      boxShadow: "none",
      padding: "2rem, 4rem, 3rem",
    },
    RequestFundsContainer:{
      display:"flex",
      flexDirection:"column",
      alignContent:"center",
      alignItems:"center",
      justifyContent:"flex-start",
    },
    RequestFundsInput:{
      width: "calc(100% - 4rem)"
    }
  });
});

const RequestFundsModal = (props)=>{
  const {
    modalOpen=false,
    setModalOpen = (isOpen)=>{console.log(`Default Component Request Funds Modal Set ${isOpen?"Open":"Closed"}`);},
    submit=async (from, amount, to, note)=>{},
    wallets=[],
    contracts=[],
    metaMaskAddress=null,
    requestFrom=null,
    amount=null,
    sendTo=null,
    note=null
  } = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  const [requestFromState, setRequestFrom] = useState(requestFrom||"");
  const [amountState, setAmount] = useState(amount||"");
  const [sendToState, setSendTo] = useState(sendTo||"");
  const [noteState, setNote] = useState(note||"");
  const mounted = useRef(true);
  useEffect(()=>{
    return(()=>{
      mounted.current = false;
    })
  },[]);

  const closeModal=()=>{
    setRequestFrom(requestFrom||"");
    setAmount(amount||"");
    setSendTo(sendTo||"");
    setNote(note||"");
    setModalOpen(false);
  }

  const defaultButtonText ="Request Funds";
  const buttonTextTimeoutSeconds = 3;
  const [buttonText, setButtonText] = useState(defaultButtonText);
  const buttonTextTimeoutRef = useRef(null);
  useEffect(()=>{
    return(clearButtonTextTimeout);
  },[]);
  const clearButtonTextTimeout = ()=>{
    if(buttonTextTimeoutRef.current)
    {
      clearTimeout(buttonTextTimeoutRef.current);
    }
    return;
  }
  const setButtonTextTimeout =()=>{
    clearButtonTextTimeout();
    buttonTextTimeoutRef.current = setTimeout(()=>{
      if(mounted.current)
      {
        setButtonText(defaultButtonText);
      }
    }, buttonTextTimeoutSeconds*1000)
  }
  const setTempButtonText = (newText)=>{
    setButtonTextTimeout();
    if(mounted.current)
    {setButtonText(newText);}
  };
  

  const submitRequest = ()=>{
    setTempButtonText("Submitting...");
    submit(requestFromState, amountState, sendToState, noteState).catch(e=>{
      setTempButtonText("Error!");
      return(null);
    }).then(result=>{
      if(result)
      {
        setTempButtonText("Sent Request!");
      }
    });
  }

  return(<Modal
    open = {modalOpen}
    onClose={()=>{
      closeModal();
    }}
  ><div className={[classes.paper, classes.modal,classes.RequestFundsContainer].join(' ')}>
      <Typography variant="h6">Request Funds</Typography>
      <StyledInput
        value={requestFromState}
        disabled={requestFrom!==null?true:false}
        type="text"
        label="Request From Email"
        updateForm={setRequestFrom}
        className={classes.RequestFundsInput}
      />
      <StyledInput
        value={amountState}
        disabled={amount!==null?true:false}
        type="number"
        label="Amount to Request"
        minimum={0}
        updateForm={setAmount}
        className={classes.RequestFundsInput}
      />
      <WalletsDropdown
        value={sendToState}
        disabled={sendTo!==null?true:false}
        type="text"
        label="Send To Address"
        updateForm={setSendTo}
        className={classes.RequestFundsInput}
        wallets={wallets}
        contracts={contracts}
        metaMaskAddress={metaMaskAddress}
      />
      <StyledInput
        value={noteState}
        disabled={note!==null?true:false}
        type="textarea"
        label="Note"
        updateForm={setNote}
        className={classes.RequestFundsInput}
      />
      <Button
        variant="outlined"
        color="primary"
        onClick={submitRequest}
      >{buttonText}</Button>
    </div>
  </Modal>);
}

export default RequestFundsModal;