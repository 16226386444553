import React, {useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';

import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import InfoIcon from '@material-ui/icons/Info';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import PageTitle from '../../components/page_title/page_title';
import Section from '../../components/section/section';
import WalletInfo from '../../components/wallet_info/wallet_info';

const useStyles = makeStyles((theme)=>{
  return({
    walletInfoContainer:{
      display:"flex",
      flexDirection:"column",
      justifyContent:"center",
      alignContent:"center",
      alignItems:"center",
   },
   main:{
    display:"flex",
    flexDirection:"row",
    flexWrap:"wrap",
    justifyContent:"flex-start",
    alignContent:"flex-start",
    alignItems:"center",
    height: "100%",
    width: "100%",
    padding:"1rem"
  },
  infoSection:{width:"100%"}
  });
});


const MyWalletsPage = ({
  wallets=[{
    name:"test_default_0",
    address:"0x0000000000000000000000000000000000000001",
    is_default:false,
    verified:false
  },{
    name:"test_default_1",
    address:"0x0000000000000000000000000000000000000002",
    is_default:true,
    verified:false
  },{
    name:"test_default_2",
    address:"0x0000000000000000000000000000000000000003",
    is_default:false,
    verified:true
  }],
  help_link_url=null,
  deleteWallet=(address, name)=>{
    console.log(`Default Page Delete Wallet ${address} (${name})`);
  },
  updateWallet=(updatedInfo={})=>{
    const {address, name=null, setDefault=false} = updatedInfo;
    console.log(`Default Wallet Page Updating Wallet ${address}: ${JSON.stringify(updatedInfo)}`);
  },
  verifyWallet = (address, code, progressFn=(stage)=>{console.log(`Wallets Page Verification Stage ${stage}`)})=>{
    console.log(`Default Wallet Page Verifying Wallet ${address}`);
  }
})=>{
  const theme = useTheme();
  const classes = useStyles(theme);
  const [addingWallet, setAddingWallet] = useState(false);

  return(
  <div className={classes.main}>
    <PageTitle icon={<AccountBalanceWalletIcon/>} title={"My Wallets"} style={{flexBasis:"100%", flexShrink:"0"}}/>
    <Section
      title="Wallet Information"
      icon={<InfoIcon/>}
      className={classes.infoSection}
    >
      <div className = {classes.walletInfoContainer}>
      <Typography variant='caption' style={{display:"block"}} nowrap={"false"}>These wallets may be used to send and receive payments through the CanPass Platform. DO NOT USE EXCHANGE WALLETS SUCH AS POLONIEX OR COINBASE.</Typography>
      {wallets.map((wallet,index)=>{
        return(<WalletInfo
          starting_name={wallet.name}
          starting_address={wallet.address}
          verified={wallet.verified}
          is_default={wallet.default || wallet.defaultWallet || wallet.is_default}
          key={`${wallet.address}/${index}`}
          deleteWallet={()=>{
            deleteWallet({address:wallet.address, name: wallet.name});
          }}
          verifyWallet={(progressUpdateFn=(stage)=>{
            console.log(`Wallet Verification Page Update`)
          })=>{
            verifyWallet(wallet.address, wallet.verificationCode, progressUpdateFn);
          }}
          saveUpdates={(updatedInfo)=>{
            updateWallet(updatedInfo)
          }}
          updateEditing={null}
        ></WalletInfo>)
      })}{addingWallet?<WalletInfo
        startingName=""
        startingAddress=""
        verified={false}
        is_default={false}
        editing={true}
        saveUpdates={(updatedInfo)=>{
          updateWallet(updatedInfo);
          setAddingWallet(false);
        }}
        verifyWallet = {null}
        deleteWallet = {null}
        updateEditing={()=>{
          setAddingWallet(false);
        }}
      ></WalletInfo>:null}
      {!addingWallet?<Button
        variant='contained'
        color='primary'
        onClick={
          ()=>{
            setAddingWallet(true);
          }
        }
        style={{margin:"0.5rem"}}
      ><AddIcon/> Add Wallet</Button>:null}</div>
    </Section>
  </div>)
}

export default MyWalletsPage;