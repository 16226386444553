import React, { useEffect }from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {bool, func, string} from 'prop-types';

// import {
//   getLanguageCode,
//   getSupportedLanguages,
//   setLanguage
// } from 'web-translate';

const useStyles = makeStyles(theme=>{
  return({
    languageSelect: {
      margin: "1rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    languageSelectSelect: {
      backgroundColor: "white",
      // Chrome doesn't honor border-radius on select elements.
      borderRadius: ".25rem",
      fontSize: "14px",
      height: "24px"
    }
  });
})

const flagMap = {
  English: '&#x1F1FA;&#x1F1F8;',
  //French: '&#x1F1EB;&#x1F1F7;',
  //Portuguese: '&#x1F1F5;&#x1F1F9;'
};

function LanguageSelect({
  disabled=false,
  onChange=(newCode)=>{},
  language="English",
  languages={"English":1}
}) {
  const classes = useStyles();
  
  function handleChange(event) {
    let newValue = event.target.value;
    changeLanguage(newValue);
  }
  async function changeLanguage(newCode) {
    //await setLanguage(code);
    console.log(`Changing Language ${newCode}`)
    onChange(newCode)
  }

  useEffect(() => {
    // getSupportedLanguages().then(languages => {
    //   setAvailableLanguages(languages);
    //   const languageCode = languageCode || getLanguageCode();
    //   changeLanguage(languageCode);
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const languageNames = Object.keys(languages);
  if (languageNames.length === 0) return null;

  return (
    <div className={classes.languageSelect}>
      <select
        className={classes.languageSelectSelect}
        disabled={disabled}
        onChange={handleChange}
        value={language}
      >
        {languageNames.map(name => {
          const text = (flagMap[name] || '') + ' ' + name;
          return (
            <option
              dangerouslySetInnerHTML={{__html: text}}
              key={name}
              value={languages[name]}
            />
          );
        })}
      </select>
    </div>
  );
}

LanguageSelect.propTypes = {
  disabled: bool,
  onChange: func,
  language: string,
};
export default LanguageSelect;
