import APIRoutes from './APIRoutes';
import XHR_POST from '../scripts/XHR_POST';

const Login = async (username, password, rememberMe=true)=>{
  if(typeof(username)!='string')
  {
    throw new Error("Invalid Username");
  }
  else if(typeof(password)!='string')
  {
    throw new Error("Invalid Password");
  }
  else if(typeof(rememberMe)!='boolean')
  {
    throw new Error("Invalid RememberMe");
  }
  else
  {
    const loginResult = await XHR_POST({
      route: APIRoutes.login.password,
      data:{
        username,password, rememberMe
      }
    }).catch(e=>{
      console.error(e);
      console.error(e.response);
      let error;
      if(e.status==404)
      {
        error = new Error("Invalid Username/Password Combination");
      }
      else
      {
        error = new Error("Unable to Login");
      }
      error.status = e.status;
      error.response = e.response;
      throw error;
    }).then(result=>{
      //Do post-processing here
      return(result);
    });
    return(loginResult);
  }
}

export default Login;