import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AutoSizer from 'react-virtualized-auto-sizer';
import MoodIcon from '@material-ui/icons/Mood';
import Typography from '@material-ui/core/Typography';
import Section from '../section/section';
import Translator from '../../scripts/Translator';

const translate = Translator({});

const useStyles = makeStyles((theme)=>{
  return({
    videoIframe:{
      width:"100%",
      margin:0
    },
    videoTipsContainer:{
      minWidth:"20rem",
      minHeight:"20rem",
      display:"inline-block",
      width:"100%",
      textAlign:"center",
      flexGrow:1,
    }
  });
})

const DashboardTips = (props)=>{
  const {video_link=""}=props;
  const classes = useStyles();
  return(<Section title="Tips and Tutorials" icon={<MoodIcon/>}>
    <Typography>{translate("We have assembled some tips and how-to videos to help you get started")}</Typography>
    <div className={classes.videoTipsContainer}>
      <AutoSizer>
        {
          ({width, height})=>{
            const minDim = Math.min(width, height);
            return(<div style={{width:width, height:minDim, textAlign:"center"}}><iframe
              className = {classes.videoIframe}
              allow="autoplay; encrypted-media"
              allowFullScreen=""
              frameBorder="0"
              id="video-container"
              src={video_link}
              title="assembled tips"
              style={{width:"100%",height:"100%"}}
            /></div>)
          }
        }
      </AutoSizer>
      
    </div>
</Section>)
}

export default DashboardTips;