import React, {useState} from 'react';

import {makeStyles, useTheme} from '@material-ui/core/styles';

import NoteAddIcon from '@material-ui/icons/NoteAdd';
import Button from '@material-ui/core/Button';

import PageTitle from '../../components/page_title/page_title';
import EditContractMetaSection from '../../components/edit_contract_meta/edit_contract_meta';

const useStyles = makeStyles((theme)=>{
  return({
    main:{
      display:"flex",
      flexDirection:"row",
      flexWrap:"wrap",
      justifyContent:"flex-start",
      alignContent:"flex-start",
      alignItems:"center",
      height: "100%",
      width: "100%",
      padding:"1rem"
    },
    editMetaSection:{
      width: "100%"
    }
  })
});

const CreateContractPage = (props)=>{
  const {
    help_link_url=null,
    createContract = (contractData)=>{console.log(`Default Create Contract: ${JSON.stringify(contractData)}`)},
    errors = {},
    contracts=[],
    wallets=[],
    metaMaskAddress=null,
  } = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  const defaultMeta = {
    name:"",
    email:"",
    managementWallet: "",
    description:"",
    payable:false,
    erc223:false
  };
  const [meta, setMeta] = useState({...defaultMeta});
  return(<div className={classes.main}>
    <PageTitle
      title="Create New Payment Contract"
      icon={<NoteAddIcon/>}
      link={help_link_url}
    />
    <EditContractMetaSection
      errors = {errors}
      startingName={defaultMeta.name}
      startingEmail={defaultMeta.email}
      startingManagerAddress = {defaultMeta.managementWallet}
      startingDescription = {defaultMeta.description}
      updatePage={(newData)=>{
        setMeta(currentMeta=>{
          return({...currentMeta, ...newData});
        });
      }}
      style={{width:"100%"}}
      contracts = {contracts}
      wallets={wallets}
      metaMaskAddress={metaMaskAddress}
      className = {classes.editMetaSection}
    />
    <div style={{width:"100%", textAlign:"center", margin:"1rem", flexShrink:1}}>
      <Button
        variant="contained"
        color="primary"
        onClick={()=>{
          createContract({
            ...meta
          })
        }}
      >Save New Contract</Button>
    </div>
  </div>)
}

export default CreateContractPage;