import {func, node, string} from 'prop-types';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme)=>{
  return({
    Section:{
      backgroundColor: "white",
      display:"inline-flex",
      flexDirection:"column",
      textAlign:"left",
      width: "auto",
      margin: ".5rem",
      flexGrow:1
    },
    SectionTop:{
      [theme.breakpoints.down("md")]:{
        width:"100%"
      }
    },
    SectionHeader:{
      display:"flex",
      justifyContent:"space-between",
    },
    SectionTitle:{
      display:"inline-flex",
      alignItems: "center",
      backgroundColor: "#0D1326",
      color: "white",
      padding: "0.75rem 1.25rem",
      fontSize: "1rem",
      width:"100%",
      [theme.breakpoints.up("sm")]:{
        width: "auto"
      }
    },
    SectionTitleIcon:{
      marginRight: "0.5rem",
      fontSize: "1.75rem"
    },
    SectionTitleDelete:{

    },
    SectionBody:{
      backgroundColor: "white",
      padding: "0.5rem",
      flexGrow:1
    }
  });
});

function Section(props) {
  const {
    children=null, 
    icon=null,
    className="",
    onDelete=null,
    title=""
  } = props;
  const classes = useStyles();
  return (
    <section className={[classes.Section, className].join(' ')}>
      {(icon || title) ? (
        <div className={[classes.SectionTop].join(' ')}>
          <header className={[classes.SectionHeader].join(' ')}>
            <div className={[classes.SectionTitle].join(' ')}>
              {icon?React.cloneElement(icon, {className: classes.SectionTitleIcon}):null}
              {title}
            </div>
            {onDelete ? (
              <button 
                className={[classes.SectionTitleDelete].join(' ')}
                onClick={onDelete}>
                &#x2716;
              </button>
            ):null}
          </header>
        </div>
      ):null}
      <div className={[classes.SectionBody].join(' ')}>{children}</div>
    </section>
  );
}

Section.propTypes = {
  children: node,
  className: string,
  onDelete: func,
  title: string
};

export default Section;
