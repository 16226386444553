import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const StyledCheckbox=(props)=>{
  const {checked=false, updateForm=(newVal)=>{}, label=null, style={}} = props;

  const handleChange = (event)=>{
    updateForm(event.target.checked);
  }

  return(<FormGroup style={{display:"inline-flex", ...style}} className={props.className?props.className:""}>
      <FormControlLabel
        control={
          <Checkbox 
            checked={checked}
            onChange={handleChange}
            value={checked}
            color={props.color?props.color:'primary'}
          />
        }
        label={label}
      />
  </FormGroup>);
}

export default StyledCheckbox;