import XHR_GET from '../scripts/XHR_GET';
import APIRoutes from './APIRoutes';

// GetContractAddresses.js
async function GetContractAddresses(authToken, opts={}) {
  //console.log(path)
  return(XHR_GET({
    route: APIRoutes.contracts.addresses,
    authToken
  }).then(info=>{
    //Do post-processing here
    return(info.contractAddresses);
  }));
}

export default GetContractAddresses;
