import React from 'react';
import BigNumber from 'bignumber.js';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import DetailsIcon from '@material-ui/icons/Details';
import AdjustIcon from '@material-ui/icons/Adjust';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles';

import Section from '../section/section';

const useStyles = makeStyles((theme)=>{
  return({
    contractStatusContainer:{
      display:"flex",
      flexDirection:"row",
      alignItems:"center",
      justifyContent:"center",
      alignSelf:"center",
      border:"2px solid rgb(224, 224, 236)",
      borderRadius:".5rem",
      padding:"0.5rem"
    },
    activeIcon:{
      color:"#8CBF3F"
    },
    frozenIcon:{
      color:"#5D9B9E"
    },
    archivedIcon:{
      color:"#9E19BF"
    },
    metaInfoContainer:{
      display:"flex",
      flexDirection:"column",
      alignItems:"stretch",
      justifyContent:"flex-start",
      
    },
    metaInfoTextBlock:{
      display:"flex",
      flexDirection:"row",
      alignItems:"center",
      justifyContent:"space-between",
      
    }
  });
});

const ContractDetailsMeta = (props)=>{
  const {
    name="",
    description="",
    deployed=false,
    frozen=false,
    archived=false,
    activeOnly=false,
    erc223=false,
    receivable=false,
    totalReceived= "0",
    numTransactions= 0
  } = props;
  let {status} = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  console.log(JSON.stringify(status));
  if(typeof(status)!='string' || status==='')
  {
    if(deployed)
    {
      status = 'active';
    }
    if(frozen)
    {
      status='frozen'
    }
    if(archived)
    {
      status = 'archived';
    }
  }

  let paymentTokenRestrictionText = "CNG Only";
  if(receivable)
  {
    if(erc223)
    {
      paymentTokenRestrictionText= "Any Token or ETH"
    }
    else
    {
      paymentTokenRestrictionText= "CNG or ETH"
    }
  }
  else if(erc223)
  {
    paymentTokenRestrictionText= "Any Token"
  }

  const MetaInfoText = ({label, value})=>{
    return(<div className={classes.metaInfoTextBlock}>
      <Typography variant="body2">{label}</Typography>
      <Typography variant="body2" style={{fontWeight:"600"}}>{value}</Typography>
    </div>)
  }
  return(<Section
    title="Meta Info"
    icon={<DetailsIcon/>}
  >
    <div className={classes.metaInfoContainer}
    >
      <div className={[classes.contractStatusContainer, classes[`${status}Icon`]].join(' ')}>
        <AdjustIcon/><Typography variant="h6">{status}</Typography>
      </div>
      <MetaInfoText label="Description:" value={description}></MetaInfoText>
      <MetaInfoText label="Payment Sender Restricted To:" value={activeOnly?`Verified Addresses Only`:`Open to Anyone`}></MetaInfoText>
      <MetaInfoText label="Payment Token Restricted To:" value={paymentTokenRestrictionText}></MetaInfoText>
      <MetaInfoText label="Total Received:" value={`${new BigNumber(totalReceived).div(1e18).toFixed(4)} CNG`}></MetaInfoText>
      <MetaInfoText label="Num Transfers Received:" value={numTransactions}></MetaInfoText>
    </div>
  </Section>)

}

export default ContractDetailsMeta;