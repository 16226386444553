import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';

import Section from '../section/section';
import Typography from '@material-ui/core/Typography';
import Translator from '../../scripts/Translator';
const translate = Translator({});

const useStyles = makeStyles((theme)=>{
  return({
  })
});

const ReportsSection = (props)=>{
  const theme = useTheme();
  const classes = useStyles(theme);
  return(<Section title="Reports" icon={<AssignmentIcon/>}>
    <Typography variant="body2">
      {translate("Tax Documents and other reports will appear here")}
    </Typography>
    </Section>);
} 

export default ReportsSection;
