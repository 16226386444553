export default function CopyToClipboard(text) {
  // Create an element to hold text to be copied
  // and put the text there.
  const textarea = document.createElement('a');
  textarea.innerText = text;
  document.body.appendChild(textarea);

  // Copy the text in the element to the clipboard.
  const selection = document.getSelection();
  const range = document.createRange();
  range.selectNode(textarea);
  selection.removeAllRanges();
  selection.addRange(range);
  document.execCommand('copy');

  // Remove the element created to hold text to be copied.
  document.body.removeChild(textarea);
}