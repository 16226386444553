import HandleXHRResponse from './XHR_handleResponse';

//Sends XMLHTTP Request to route indicated. 
//Callback receives error object or response object
function GET_Request({
  route="/",
  headers={},
  authToken=null,
  queries=null,
  setCSRF=true
}, callback)
{
  console.log(`${route}, ${setCSRF}`);
  var queryString = '';
  if(typeof(queries)=='string')
  {
      queryString = queries;
  }
  else if(queries)
  {
      var queryKeys = Object.keys(queries);
      if(Array.isArray(queryKeys) && queryKeys.length>0)
      {
          queryString += '?';
          queryKeys.forEach((key, index)=>{
              queryString += key+"="+queries[key];
              if(index < queryKeys.length -1)
              {
                  queryString +="&";
              }
          });
      }
  }
  
  let modified_route = route+ queryString;
  var x=new XMLHttpRequest();
  x.onreadystatechange = HandleXHRResponse(x, callback);
  x.open('GET', modified_route, true);
  x.setRequestHeader('Content-Type', 'application/json');
  if(authToken)
  {
    x.setRequestHeader('X-Cangea-Authorization', authToken);
  }
  Object.keys(headers).forEach(h=>{
    x.setRequestHeader(h, headers[h]);
  });
  if(setCSRF)
  {
    x.setRequestHeader("x-csrf-Cangea","Anti_CSRF_Token");
  }
  x.send();
}

const XHR_GET = async ({
  route="/",
  headers={},
  authToken=null,
  queries=null,
  setCSRF=true
}) =>{
  return new Promise((resolve, reject)=>{
    GET_Request({
      route,
      headers,
      authToken,
      queries,
      setCSRF
    }, function(error, result)
    {
      if(error)
      {
        reject(error);
      }
      else
      {
        resolve(result);
      }
    })
  });
}

export default XHR_GET;