import BigNumber from 'bignumber.js';
import TokenContractMethods from '../contractMethods/tokenContractMethods';
import ContractPurchasingMethods from '../contractMethods/contractPurchasingMethods';

const PurchaseContract = async (
  Web3=null,
  tokenContractAddress='0x0',
  purchasesContractAddress='0x0',
  codeHash = null,
  numTokens = 0,
  opts={},
  privateKey=null,
)=>{
  const {
    increaseAllowance = true
  } = opts;
  const progressFunction=(step)=>(stage)=>{
    console.log(`PurchaseContract has reached stage ${stage} of step ${step}`);
  };
  const handleCallReturnValue= async (val)=>{
    //Returns whether to proceed with sending the actual transaction
    return(val);
  };

  const currentPurchaseInfo = await ContractPurchasingMethods.getPurchaseRecord(
    Web3,
    purchasesContractAddress,
    codeHash,
    {...opts},
    privateKey,
    progressFunction(0)
  ).catch(e=>{
    console.error(e);
    throw new Error(`Unable to check current purchase record info`);
  });
  console.log(currentPurchaseInfo);
  if(currentPurchaseInfo.deployedLocation != '0x0000000000000000000000000000000000000000')
  {
    throw new Error(`Contract has already been deployed`);
  }
  else if(new BigNumber(currentPurchaseInfo.value).gte(new BigNumber(numTokens)))
  {
    throw new Error(`Contract has already been purchased`);
  }
  else
  {
    if(increaseAllowance)
    {
      await TokenContractMethods.increaseSpenderAllowance(
        Web3,
        tokenContractAddress,
        purchasesContractAddress,
        new BigNumber(numTokens).toString(),
        {...opts},
        privateKey,
        progressFunction(1),
        handleCallReturnValue
      ).catch(e=>{
        console.error(e);
        throw new Error(`Unable to increase allowance for Purchases Contract`);
      });
    }
    return(ContractPurchasingMethods.purchaseContract(
      Web3,
      purchasesContractAddress,
      codeHash,
      new BigNumber(numTokens).toString(),
      {...opts},
      privateKey,
      progressFunction(2),
      handleCallReturnValue
    ).catch(e=>{
      console.error(e);
      throw new Error(`Unable to Purchase Contract: ${e.toString()}`);
    }));
  } 
};

export default PurchaseContract;