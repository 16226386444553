import XHR_POST from '../scripts/XHR_POST';
import APIRoutes from './APIRoutes';

const DeleteContract = async ({
  index=null,
  name=""
},authToken=null) =>{
  const wantsToDelete = window.confirm(`Are you sure you want to delete the contract "${name}"?(index ${index})`);
  if(wantsToDelete)
  {
    return(XHR_POST({
      route: APIRoutes.contracts.delete,
      data:{
        index
      },
      authToken,
    }).then((result)=>{
      if(result)
      {
        const {message} = result;
        alert(message);
        return(true);
      }
      else
      {
        return(false);
      }
    }));
  }
  else
  {
    return(false);
  }
}

export default DeleteContract;