import React, {useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import NoteAddIcon from '@material-ui/icons/NoteAdd';

import Section from '../section/section';
import WalletsInputDropdown from '../wallets_dropdown/wallets_dropdown';
import StyledInput from '../styled_input/styled_input';
import { Typography } from '@material-ui/core';
import Translator from '../../scripts/Translator';
const translate = Translator({});


const useStyles = makeStyles((theme)=>{
  return({
    FormContainer:{
      display:"flex",
      flexDirection:"column",
      alignContent:"center",
      alignItems:"center",
      justifyContent:"flex-start",
      minWidth: "25rem",
    },
    FormPrompt:{
      width: "calc(100% - 4rem)",
      marginTop:".25rem",
      marginBottom:".5rem"
    },
    FormInput:{
      width: "calc(100% - 4rem)"
    },
    FormButton:{
      margin:"1rem"
    }
  })
});

const CreateContractSection = (props)=>{
  const theme = useTheme();
  const classes = useStyles(theme);
  const {
    title = "Start Building A Contract",
    icon=<NoteAddIcon/>,
    createNewContract = (name, notification_email, description)=>{console.log("Create Contract Default")},
    wallets=[],
    metaMaskAddress=null
  } = props;
  const [name, setName] = useState("");
  const [managerAddress, setManagerAddress] = useState("");
  const [notificationEmail, setEmailNotification] = useState("");
  const [description, setDescription] = useState("");

  return(<Section
    title={title}
    icon={icon}
    
  >
    <div className={classes.FormContainer}>
    <Typography variant='body2' className={classes.FormPrompt}>
      {translate("Fill out this form to begin building a new Payment Routing smart contract:")}
    </Typography>
    <StyledInput
      type='text'
      label='Contract Name'
      className ={classes.FormInput}
      updateForm = {setName}
      value={name}
    ></StyledInput>
    <StyledInput
      type='text'
      label='Notification Email'
      className ={classes.FormInput}
      updateForm = {setEmailNotification}
      value={notificationEmail}
    ></StyledInput>
    <StyledInput
      type='textarea'
      label='Contract Description'
      className ={classes.FormInput}
      updateForm = {setDescription}
      value={description}
    ></StyledInput>
    <WalletsInputDropdown
        value={managerAddress}
        type="text"
        label="Manager Address"
        updateForm={(newVal)=>{
          setManagerAddress(newVal);
        }}
        className ={classes.FormInput}
        contracts={[]} //Cannot be a contract address
        wallets={wallets}
        metaMaskWallet={metaMaskAddress}
      />
    <Button
      variant="outlined"
      color="primary"
      onClick={()=>{
        createNewContract({
          name,
          primaryEmail: notificationEmail,
          description,
          management: managerAddress
        })
      }}
      className={classes.FormButton}
    >Create New Contract</Button>
    </div>
  </Section>)
}

export default CreateContractSection;