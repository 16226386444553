import BigNumber from 'bignumber.js';
import ABIs from '../abis';
import CreateContractMethod from '../createContractMethod';

const GetAccountBalance = async (
  Web3=null,
  contractAddress='0x0',
  account=null,
  opts={},
  privateKey=null,
  progressFunction=(stage)=>{
    console.log(`GetAccountBalance call has reached stage ${stage}`);
  },
 )=>{
  return(new Promise((resolve, reject)=>{
    const handleCallReturnValue= async (val)=>{
      //Returns whether to proceed with sending the actual transaction
      resolve(val);
      return(false);
    };
    CreateContractMethod(
      ABIs.Token,
      [
        'address',
      ], 
      'balanceOf',
    )(
      Web3,
      contractAddress,
      [
        account
      ],
      opts,
      privateKey,
      progressFunction,
      handleCallReturnValue
    ).catch(e=>{
      reject(e);
    })
  }));
}

const GetSpenderAllowance = async (
  Web3=null,
  contractAddress='0x0',
  owner=null,
  spender=null,
  opts={},
  privateKey=null,
  progressFunction=(stage)=>{
    console.log(`GetSpenderAllowance call has reached stage ${stage}`);
  },
 )=>{
  return(new Promise((resolve, reject)=>{
    const handleCallReturnValue= async (val)=>{
      //Returns whether to proceed with sending the actual transaction
      resolve(val);
      return(false);
    };
    CreateContractMethod(
      ABIs.Token,
      [
        'address',
        'address'
      ], 
      'allowance',
    )(
      Web3,
      contractAddress,
      [
        owner,
        spender
      ],
      opts,
      privateKey,
      progressFunction,
      handleCallReturnValue
    ).catch(e=>{
      reject(e);
    })
  }));
}

const SetSpenderAllowance = async (
  Web3=null,
  contractAddress='0x0',
  spender=null,
  allowance=0,
  opts={},
  privateKey=null,
  progressFunction=(stage)=>{
    console.log(`SetSpenderAllowance call has reached stage ${stage}`);
  },
  handleCallReturnValue= async (val)=>{
    //Returns whether to proceed with sending the actual transaction
    return(true);
  }) =>{
  return(
    CreateContractMethod(
      ABIs.Token,
      [
        'address',
        'number'
      ], 
      'approve',
    )(
      Web3,
      contractAddress,
      [
        spender,
        new BigNumber(allowance).toString()
      ],
      opts,
      privateKey,
      progressFunction,
      handleCallReturnValue
    )
  );
}

const IncreaseSpenderAllowance = async (
  Web3=null,
  contractAddress='0x0',
  spender=null,
  allowanceIncrease=0,
  opts={},
  privateKey=null,
  progressFunction=(stage)=>{
    console.log(`IncreaseSpenderAllowance call has reached stage ${stage}`);
  },
  handleCallReturnValue= async (val)=>{
    //Returns whether to proceed with sending the actual transaction
    return(true);
  }) =>{
  return(
    CreateContractMethod(
      ABIs.Token,
      [
        'address',
        'number'
      ], 
      'increaseAllowance',
    )(
      Web3,
      contractAddress,
      [
        spender,
        new BigNumber(allowanceIncrease).toString()
      ],
      opts,
      privateKey,
      progressFunction,
      handleCallReturnValue
    )
  );
}

const DecreaseSpenderAllowance = async (
  Web3=null,
  contractAddress='0x0',
  spender=null,
  allowanceDecrease=0,
  opts={},
  privateKey=null,
  progressFunction=(stage)=>{
    console.log(`DecreaseSpenderAllowance call has reached stage ${stage}`);
  },
  handleCallReturnValue= async (val)=>{
    //Returns whether to proceed with sending the actual transaction
    return(true);
  }) =>{
  return(
    CreateContractMethod(
      ABIs.Token,
      [
        'address',
        'number'
      ], 
      'decreaseAllowance',
    )(
      Web3,
      contractAddress,
      [
        spender,
        new BigNumber(allowanceDecrease).toString()
      ],
      opts,
      privateKey,
      progressFunction,
      handleCallReturnValue
    )
  );
}

const Transfer = async (
  Web3=null,
  contractAddress='0x0',
  to=null,
  value=0,
  opts={},
  privateKey=null,
  progressFunction=(stage)=>{
    console.log(`Transfer call has reached stage ${stage}`);
  },
  handleCallReturnValue= async (val)=>{
    //Returns whether to proceed with sending the actual transaction
    return(true);
  }) =>{
  return(
    CreateContractMethod(
      ABIs.Token,
      [
        'address',
        'number'
      ], 
      'transfer',
    )(
      Web3,
      contractAddress,
      [
        to,
        new BigNumber(value).toString()
      ],
      opts,
      privateKey,
      progressFunction,
      handleCallReturnValue
    )
  );
}

const TransferFrom = async (
  Web3=null,
  contractAddress='0x0',
  from=null,
  to=null,
  value=0,
  opts={},
  privateKey=null,
  progressFunction=(stage)=>{
    console.log(`Transfer call has reached stage ${stage}`);
  },
  handleCallReturnValue= async (val)=>{
    //Returns whether to proceed with sending the actual transaction
    return(true);
  }) =>{
  return(
    CreateContractMethod(
      ABIs.Token,
      [
        'address',
        'address',
        'number'
      ], 
      'transfer',
    )(
      Web3,
      contractAddress,
      [
        from,
        to,
        new BigNumber(value).toString()
      ],
      opts,
      privateKey,
      progressFunction,
      handleCallReturnValue
    )
  );
}

const TokenContractMethods = {
  getAccountBalance: GetAccountBalance,
  getSpenderAllownace: GetSpenderAllowance,
  setSpenderAllowance: SetSpenderAllowance,
  increaseSpenderAllowance: IncreaseSpenderAllowance,
  decreaseSpenderAllowance: DecreaseSpenderAllowance,
  transfer: Transfer,
  transferFrom: TransferFrom
};

export default TokenContractMethods;