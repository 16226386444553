import React, {useState} from 'react';

import TimeoutTextButton from '../timeout_text_button/timeout_text_button';
import CopyToClipboard from './copyToClipboard';

const CopyAddressButton = (props)=>{
  const {
    buttonText="Copy",
    copyContent="Hello Copier",
    buttonIcon=null,
    iconFirst=true,
    copyTextTimeoutSeconds=2,
    variant='contained',
    color='primary',
    className='',
  } = props;
  const [innerText, setInnerText] = useState(buttonText);
  
  const handleClick = ()=>{
    CopyToClipboard(copyContent);
    setInnerText("Copied!")
  };

  return(<TimeoutTextButton 
    buttonText = {innerText}
    buttonIcon = {buttonIcon}
    iconFirst={iconFirst}
    defaultText = {buttonText}
    timeoutSeconds={copyTextTimeoutSeconds}
    setButtonText = {setInnerText}
    onClick={handleClick}
    className={[className].join(' ')}
    variant={variant}
    color={color}
  />);
};

export default CopyAddressButton;