import React, {useState, useEffect} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';

import Section from '../section/section';
import StyledInput from '../styled_input/styled_input';
import StyledCheckbox from '../styled_checkbox/styled_checkbox';
import WalletsInputDropdown from '../wallets_dropdown/wallets_dropdown';
import Translator from '../../scripts/Translator';
const translate = Translator({});

const useStyles = makeStyles((theme)=>{
  return({
    EditMetaContainer:{
      display:"flex",
      flexDirection:"row",
      alignContent:"center",
      alignItems:"center",
      justifyContent:"space-between",
      width: "100%"
    },
    EditMetaInput:{
      flexGrow:"1"
    },
    EditMetaRestrictions:{
      display:"flex",
      flexDirection:"row",
      alignContent:"center",
      alignItems:"flex-start",
      justifyContent:"space-around",
      width: "100%",
      padding:".5rem",
      flexGrow:1,
      '& > div':{
        padding:".5rem",
        flexGrow:1,
        flexBasis:"50%",
        display:"inline-flex",
        flexDirection:"column",
        justifyContent:"flex-start",
        alignItems: "flex-start",
        '& button':{
          margin:".5rem"
        }
      }
    },
    PaymentTokenRestrictions:{

    }
  })
});

const EditContractMetaSection = (props)=>{
  
  const {
    startingName="", startingEmail="", startingManagerAddress="", startingDescription="",
    startingACLs=[], startingTokens = [], updatePage=(updateInfo)=>{console.log(`Updating Page`);console.log(updateInfo);},
    contracts=[],
    wallets=[],
    metaMaskAddress=null
  } = props;

  
  const theme = useTheme();
  const classes = useStyles(theme);
  const [name, setName] = useState(startingName);
  const [primaryEmail, setEmail] = useState(startingEmail);
  const [managerAddress, setManagerAddress] = useState(startingManagerAddress);
  const [description, setDescription] = useState(startingDescription);
  let startingACLArray = [{
    name: "Verified Wallets",
    address: "0xeaE61B845e2a08544F22AE65f94f52DAF7Ee9CDA",
    enforce: true
  }];
  if(Array.isArray(startingACLs))
  {
    startingACLArray = startingACLArray.concat(startingACLs);
  }
  const [accessControlLists, setAccessControlLists] = useState(startingACLArray);

  let startingTokensArray = [{
    name: "Ethereum",
    symbol: "ETH",
    address: "0x0000000000000000000000000000000000000000",
    accept: true
  },
  {
    name: "ERC223",
    symbol: "CNG, etc.",
    address: "0x0000000000000000000000000000000000000001",
    accept: true
  },
  // {
  //   name: "Cangea",
  //   symbol: "CNG",
  //   address: "0xe08e982F04c9919E7900DbA37Cc757F7751F7919",
  //   accept: true
  // }
];
  if(Array.isArray(startingTokens))
  {
    startingTokensArray = startingTokensArray.concat(startingTokens);
  }
  const [acceptedTokens, setAcceptedTokens] = useState(startingTokensArray);

  const updateTokenAcceptance = (token_address)=> (value)=>{
    //const value = event.target.checked;
    setAcceptedTokens(currentTokens=>{
      for(let i=0,limit=currentTokens.length; i<limit; i++)
      {
        if(token_address==currentTokens[i].address)
        {
          currentTokens[i].accept=value;
        }
      }
      return([].concat(currentTokens));
    });

  }

  const handlePaymentSenderRestrictionUpdate = (event)=>{
   
    let newVal = event.target.value;
    console.log(newVal)
    if(newVal=='anyone')
    {
      setAccessControlLists(currentLists=>{
        for(let i=0,limit=currentLists.length; i<limit; i++)
        {
          if(currentLists[i].name=="Verified Wallets")
          {
            currentLists[i].enforce=false;
          }
        }
        return([].concat(currentLists));
      });
    }
    else
    {
      setAccessControlLists(currentLists=>{
        for(let i=0,limit=currentLists.length; i<limit; i++)
        {
          if(currentLists[i].name=="Verified Wallets")
          {
            currentLists[i].enforce=true;
          }
        }
        return([].concat(currentLists));
      });
    }
  }

  const sendPageUpdate =()=>{
    let erc223 = false;
    let payable = false;
    let verified = false;
    for(let i=0,limit=acceptedTokens.length; i<limit; i++)
    {
      if(acceptedTokens[i].name=="Ethereum" && acceptedTokens[i].accept)
      {
        payable = true;
      }else if(acceptedTokens[i].name=="ERC223"  && acceptedTokens[i].accept)
      {
        erc223=true;
      }
    }
    for(let i=0,limit=accessControlLists.length; i<limit; i++)
    {
      if(accessControlLists[i].name== "Verified Wallets" && accessControlLists[i].enforce)
      {
        verified = true;
      }
    }
    updatePage({
      name,
      primaryEmail,
      description,
      management: managerAddress,
      erc223,
      payable,
      verified
    });
  }

  const PaymentSenderRestrictions = ()=>{
    let value = "anyone";
    for(let i=0,limit=accessControlLists.length; i<limit; i++)
    {
      if(accessControlLists[i].name=="Verified Wallets" && accessControlLists[i].enforce)
      {
        value = "verified"
      }
    }
    return(<div
      className={classes.PaymentSenderRestrictions}
    >
      <FormControl component="fieldset">
        <Typography variant="body1" >Payment Sender Restrictions</Typography>
        <RadioGroup defaultValue="female" aria-label="gender" name="sender-restrictions" value={value} onChange={handlePaymentSenderRestrictionUpdate}>
          <FormControlLabel value="anyone" control={<Radio color="primary"/>} label="Allow Payments from any Address" />
          <FormControlLabel value="verified" control={<Radio color="primary" />} label="Allow Payments only from Verified Addresses"/>
        </RadioGroup>
      </FormControl>
      <Button
        variant="outlined"
        color="primary"
        disabled
      >{translate("Custom Sender Restriction")}</Button>
    </div>);
  };

  const PaymentTokenRestrictions = ()=>{
    return(<div className={classes.PaymentTokenRestrictions}>
      <FormControl component="fieldset">
        <Typography variant="body1" >Accepted Tokens</Typography>
        {
          acceptedTokens.map((token,index)=>{
            return(<StyledCheckbox
              key ={index}
              label = {`${token.name} (${token.symbol})`}
              checked = {token.accept}
              updateForm = {updateTokenAcceptance(token.address)}
            ></StyledCheckbox>);
          })
        }
      </FormControl>
      <Button
        variant="outlined"
        color="primary"
        disabled
      >{translate("Accept New Token")}</Button>
    </div>)
  }

  let className = props.className?props.className:"";

  useEffect(sendPageUpdate, [
    name,
    primaryEmail,
    description,
    managerAddress,
    acceptedTokens,
    accessControlLists
  ]);
  return(<Section 
    title = "Contract Information"
    icon = {<LibraryBooksIcon/>}
    className = {className}
  >
    <div className={classes.EditMetaContainer}>
      <StyledInput
        value={name}
        type="text"
        label="Contract Name"
        updateForm={(newVal)=>{
          setName(newVal);
        }}
        className = {classes.EditMetaInput}
      />
      <StyledInput
        value={primaryEmail}
        type="text"
        label="Notifications Email"
        updateForm={(newVal)=>{
          setEmail(newVal);
        }}
        className = {classes.EditMetaInput}
      />
    </div>
    <div className={classes.EditMetaContainer}>
      <WalletsInputDropdown
        value={managerAddress}
        type="text"
        label="Manager Address"
        updateForm={(newVal)=>{
          setManagerAddress(newVal);
        }}
        className = {classes.EditMetaInput}
        contracts={[]} //Cannot be a contract address
        wallets={wallets}
        metaMaskWallet={metaMaskAddress}
      />
      <StyledInput
        value={description}
        type="textarea"
        label="Description"
        updateForm={(newVal)=>{
          setDescription(newVal);
        }}
        className = {classes.EditMetaInput}
      />
    </div>
    <div className={classes.EditMetaRestrictions}>
      {PaymentSenderRestrictions()}
      {PaymentTokenRestrictions()}
    </div>
  </Section>)
};

export default EditContractMetaSection;