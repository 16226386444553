import ABIs from '../abis';
import CreateContractMethod from '../createContractMethod';

const PurchaseContractDeployment = async (
  Web3=null,
  contractAddress='0x0',
  codeHash = null,
  numTokens = 0,
  opts={},
  privateKey=null,
  progressFunction=(stage)=>{
    console.log(`PurchaseContractDeployment call has reached stage ${stage}`);
  },
  handleCallReturnValue= async (val)=>{
    //Returns whether to proceed with sending the actual transaction
    return(true);
  }) =>{
  return(
    CreateContractMethod(
      ABIs.Purchases,
      [
        'string',
        'number'
      ], 
      'PurchaseContract',
    )(
      Web3,
      contractAddress,
      [
        codeHash,
        numTokens
      ],
      opts,
      privateKey,
      progressFunction,
      handleCallReturnValue
    )
  );
}

const GetPurchaseRecord = async (
  Web3=null,
  contractAddress='0x0',
  codeHash = null,
  opts={},
  privateKey=null,
  progressFunction=(stage)=>{
    console.log(`GetPurchaseRecord call has reached stage ${stage}`);
  }) =>{
  return(new Promise((resolve, reject)=>{
    const handleCallReturnValue= async (val)=>{
      //Returns whether to proceed with sending the actual transaction
      resolve(val);
      return(false);
    };
    CreateContractMethod(
      ABIs.Purchases,
      [
        'string',
      ], 
      'Purchases',
    )(
      Web3,
      contractAddress,
      [
        codeHash
      ],
      opts,
      privateKey,
      progressFunction,
      handleCallReturnValue
    ).catch(e=>{
      reject(e);
    })
  }));
}


const ContractPurchasingMethods = {
  getPurchaseRecord: GetPurchaseRecord,
  purchaseContract: PurchaseContractDeployment
};

export default ContractPurchasingMethods;
